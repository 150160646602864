import React from "react";

import './ScoreMetadata.css'

const renderMetadataItem = (key, value, index) => {
    return (
        <div key={index} className="modalItem">
            <span className="modalLabel">{key}:</span>
            <span className="metadataValue">{value.toString()}</span>
        </div>
    )
}

const renderMetadata = (metadata) => {
    console.log("metadata: ")
    console.log(metadata)
    return (
        metadata ?
        Object.keys(metadata).map((key, index) => {
            return renderMetadataItem(key, metadata[key], index);
        }) :
        <div>
            No metadata for this score
        </div>
    )
}

const ScoreMetadata = props => {
    if(!props.show){
        return null;
    }
    return (
        <div className="modal">
            <div className="modalContent">
                <p className="modalMessage">Metadata for this score</p>
                {renderMetadata(props.metadata)}
                <div className="modalButtons">
                    <button onClick={props.onClose}>
                    Close
                    </button>
                </div>
            </div>
        </div>
    );
} 

export default ScoreMetadata;