import React from 'react';
import "./Terms.css";

const Terms = () =>
  <div className="Terms">
    <div className="basicContainer termsContainer">
      <div className="terms">
        <h1>SilentWolf Terms of service</h1>
        <div className="lander termsMain">
          <h3>
            1. Your agreement with SilentWolf
          </h3>
          <div class="termsText">
            These Terms of Service ("Terms") govern your use of the silentwolf.com website, the SilentWolf plugin for Godot Engine and the SilentWolf APIs (that we will collectively refer to as "SilentWolf" below).
            We will refer to SilentWolf as "us" below.
          </div>
          <h3>
            2. Agreeing to these terms
          </h3>
          <div class="termsText">
            You must agree to these Terms in order to use SilentWolf. If you do not agree with any part of these Terms, then you should not use SilentWolf.
          </div>
          <h3>
            3. Current and future usage
          </h3>
          <div class="termsText">
            SilentWolf is our exclusive property and all intellectual property, including publically available code and marketing material is owned by us. You have the right to create a SilentWolf account and create an API key and a Game id with SilentWolf for free. We reserve the right to terminate any account, API key or game id without reason, and we are under no obligation to keep providing the SilentWolf service in the future or to provide or keep providing any particular feature.
            You are allowed to request your account, API key or Game id to be terminated at any time. You are also allowed to discontinue your use of SilentWolf at any time without terminating your account, your API key or game id.
            We reserve the right to change these Terms in the future. Your continued use of SilentWolf will be considered as acceptance of any new Terms.
          </div>
          <h3>
            4. Fair usage
          </h3>
          <div class="termsText">
            You SilentWolf account, API key and game id are destined for use by yourself or your company. You are not allowed to share your credentials with third parties.
            You are not allowed to resell the SilentWolf services or otherwise commercialise SilentWolf for your own or a third party's profit without our explicit written consent.
            You are responsible for all end user content stored or processed in SilentWolf via your projects ("Games") that use SilentWolf.
            If you work with contractors or other third parties, you are responsible for their acceptance and their abiding by these Terms.
          </div>
          <h3>5. Your obligations</h3>
          <div class="termsText">
            You may only use SilentWolf for lawful purposes and are responsible for complying with any laws applicable in the country from where you are using SilentWolf.
            You are not allowed to use SilentWolf in any way that breaches or attempts to breach SilentWolf's security or abuse it in a way to make it unusable by others.
          </div>
          <h3>
            6. Changes to our terms of service
          </h3>
          <div class="termsText">
            We will keep our terms of service under regular review. This version of our privacy policy was last updated on Monday, July 25th 2022.
          </div>
          <h3>
            8. How to contact us
          </h3>
          <div class="termsText">
            You can contact us about our terms of service via our <a href="/contact">public contact form</a> or on our <a href="mailto:support@silentwolf.com">support email address</a>.
          </div>
        </div>
      </div>
    </div>
  </div>

export default Terms
