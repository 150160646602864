import React, {useState} from "react";
import TextInputField from '../../../../Utils/TextInputField'
import ModalButton from '../../../../Utils/Controls/ModalButton'
import './PlayerModals.css'

const ChangePlayerPassword = props => {

    const [newPassword, setNewPassword] = useState(null)
    const [formError, setFormError] = useState(null)
    const [formSubmitting, setFormSubmitting] = useState(false)

    const handleNewPasswordChange = event => {
        setNewPassword(event.target.value)
        // is there's an error, setFormError
        // otherwise close modal (ideally with a checkmark replacing the button text before the popup closes)
    }

    const changePlayerPassword = async () => {
        setFormSubmitting(true)
        if(!newPassword) {
            setFormError('Please enter a new password for this player.')
        } else {
            const result = await props.onChangePassword(props.playerName, newPassword)
            console.log("changePlayerPassword, result:")
            console.log(result)
            if(result.success) {
                props.onClose()
            } else {
                setFormError(result.error)
            }
        }
        setFormSubmitting(false)
    }

    if(!props.show){
        return null;
    }
    return (
        <div className="modal changePasswordModal">
            <div className="modalContent">
                <p className="modalMessage">Change player password </p>
                <p className="modalItem">
                    Enter a new password for the player below.
                </p>
                <p className="modalItem">
                    <span className="modalLabel">Player name:</span> 
                    {props.playerName}
                </p>
                <div className="modalForm">
                    <TextInputField className="longTextInput" 
                                    name="newPassword" 
                                    label="New password" 
                                    onChange={handleNewPasswordChange} />
                </div>
                <div className="modalButtons changePasswordButtons">
                    <ModalButton buttonStyle="modalButton changePasswordButton" 
                            handleClick={() => changePlayerPassword()} 
                            text="Change Password" 
                            loading={formSubmitting} />
                    {/*<button className="changePasswordButton" onClick={() => changePlayerPassword()}>
                        Change Password
                    </button>*/}
                    <ModalButton buttonStyle="modalButton secondaryButton" 
                            handleClick={props.onClose} 
                            text="Cancel"  />
                     {/*<button className="secondaryButton" onClick={props.onClose}>
                        Cancel
                    </button>*/}
                </div>
                { formError ?
                    <div className="explainer exceptions modalExceptions">
                        {formError}
                    </div>
                : null }
            </div>
        </div>
    );
} 

export default ChangePlayerPassword;