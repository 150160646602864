import React, { useState } from 'react';
import "./CodeBlock.css";

const CodeBlock = props => {
  const [copyBtnText, setCopyBtnText] = useState('Copy');
  const [activeTab, setActiveTab] = useState(0);

  const tabLinks = [0, 1];
  const tabTitles = ['Godot 3.x', 'Godot 4'];

  const handleClick = tab => {
    setActiveTab(tab);
  };

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet)
    setCopyBtnText('Code Copied!');

    setTimeout(() => {
      setCopyBtnText('Copy');
    }, 1000);
  };

  return (
    <div className="tabbed-code-block">
        <div className="tabbed-header">
            <div className="tab-links">
                {tabLinks.map(link => (
                <div
                    key={link}
                    className={`tab-link ${activeTab === link ? 'active' : ''} ${link === 0 ? 'left-tab' : ''}`}
                    onClick={() => handleClick(link)}
                >
                    {tabTitles[link]}
                </div>
                ))}
            </div>
        </div>
        <div class="tabbed-content">
            {Object.keys(props.codeSnippets).map( (key, i) => (
            <div key={`tab-${i + 1}`}
                id={`tab-${i + 1}`}
                className={`code-snippet ${
                activeTab === i ? 'active' : ''
                }`}>
                <pre>
                    <code id={`code-tab-${i}`} class="code-content">
                        {props.codeSnippets[key]}
                    </code>
                </pre>
                <button
                    className="copy-btn"
                    onClick={() => handleCopy(props.codeSnippets[key])}
                    >
                <i className="fa fa-clone"></i> {copyBtnText}
                </button>
            </div>
        ))}
      </div>
    </div>
  );
}

export default CodeBlock;