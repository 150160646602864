import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useAppContext } from '../../libs/contextLib'
import { useFormFields } from '../../libs/hooksLib'
import Button from '../Utils/Controls/Button'
import "./CreateNewGame.css";

const CreateNewGame = props => {

  const { userSession, reloadGames } = useAppContext()

  const [newGameForm, setNewGameForm] = useState({
                                                  gameId: '',
                                                  gameName: '',
                                                  apiKeyCreated: null,
                                                  submitted: false,
                                                  submittedError: false,
                                                  errorMessage: '',
                                                  isLoading: false
                                                })

  const [fields, handleFieldChange] = useFormFields({
    gameName: ''
  });

  const createNewGame = async event => {
    event.preventDefault();

    setNewGameForm({
      ...newGameForm,
      isLoading: true
    })

    const createNewGameUrl = "https://api.silentwolf.com/create_new_game"
    const usernameGameName = userSession.username + ";" + fields.gameName
    //console.log("Creating API key for: " + usernameGameName)
    await fetch(createNewGameUrl + "/" + usernameGameName).then( results => {
        console.log("Created new game for: " + usernameGameName)
        return results.json()
      }).then( jsonResults => {
        if(jsonResults.hasOwnProperty('error')) {
          console.log("error in jsonResults: ")
          console.log(jsonResults.error)
          setNewGameForm({
            ...newGameForm,
            submittedError: true,
            errorMessage: jsonResults.error,
            isLoading: false
          })
        } else {
          console.log("api key: " + jsonResults["api_key"])
          console.log("game created: " + jsonResults["game_id"])
          const gameId = jsonResults['game_id']
          setNewGameForm({
            ...newGameForm,
            submitted: true,
            submittedError: false,
            apiKeyCreated: jsonResults['api_key'],
            gameId: gameId,
            isLoading: false
          })
          reloadGames(gameId)
        }
    })
  }

  const unauthRender = () => {
    return (
      <div className="basicContainer newGameContainer">
        <h1 className="titleShort">Sign in before you can create a new game</h1>
        <div className="explainer">
          You need to sign in before creating a game in SilentWolf.
        </div>
        <div className="explainer">
          If you have any issues during installation, don't hesitate to <Link to="/contact">contact us</Link>.
        </div>
        <div className="explainer">
          <Link to="/auth">
            <button>Login or create an account</button>
          </Link>
        </div>
      </div>
    )
  }

  const authRender = () => {
    return(
      <div className="basicContainer newGameContainer">
        <h1 className="titleShort">Create a new game</h1>
        <div className="explainer">Create a new game by just typing its name in the field below.</div>
        <div className="explainer marginned ">If you have multiple games, you can use the drop-down field at the top of the page to switch between them. </div>
        <div className="row">
          <label>Name of your game:</label>
          <input type='text'
                id='gameName'
                className='field'
                placeholder=''
                value={fields.gameName}
                onChange={handleFieldChange}
                required />
        </div>
        <div className="centered">
          <Button handleClick={createNewGame} text="Submit" loading={newGameForm.isLoading} />
        </div>
        { newGameForm.submittedError ?
          <div className="explainer exceptions">
            There was an error creating your new game - {newGameForm.errorMessage}
          </div>
        : null }
      </div>
    )
  }

  const formSubmitted = () => {
    return (
      <div className="basicContainer newGameContainer">
        <h1 className="titleShort">Congrats!</h1>
        <div className="explainer successful">
          Your new game was created successfully! Here is your API key:
        </div>
        <pre className="preContent">
            {newGameForm.apiKeyCreated}
        </pre>
        <div className="explainer">And here is the identifier (gameId) for your game {newGameForm.gameName}:</div>
        <pre className="preContent">
            {newGameForm.gameId}
        </pre>
        <div  className="explainer">Please take note of your API key and your new game id, you will need to use them to access SilentWolf backend services.</div>
        <div className="buttonContainer centered">
          <Link to="/features">
            <button className="homeButton">Home</button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="CreateNewGame">
      {!userSession.username
        ? unauthRender()
        : (newGameForm.submitted ?
          formSubmitted()
          : authRender()) }
    </div>
  )
}

export default CreateNewGame
