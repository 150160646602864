import React from 'react';

const PlayerDataIntroText = () =>
  <div className="introTextContainer">
    <h1 className="leaderboardFree">Add player data to your game for free</h1>
    <div className="explainer">
      SilentWolf enables you to save and reuse player and character data in your game for free. Persist arbitrary data about your players and characters such as experience, money, weapons and other items to the SilentWolf backend servers and retrieve them at any time. SilentWolf does the heavy lifting for you so you can focus on your game mechanics.
    </div>
    <div className="explainer">
      To add player data to your game, follow the instructions below.
    </div>
  </div>

export default PlayerDataIntroText
