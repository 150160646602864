import React, { Component }  from 'react';
import { Link } from 'react-router-dom'
import ShowApiKey from '../Utils/ShowApiKey'
import CodeBlock from '../Utils/Controls/CodeBlock'

// Snippets for the code blocks for Godot 3.x and Godot 4.x
const configSnippets = {
  g3: `
  SilentWolf.configure({
    "api_key": "YOUR_SILENTWOLF_API_KEY",
    "game_id": "YOUR_SILENTWOLF_GAME_ID",
    "game_version": "1.0.2",
    "log_level": 1
  })

  SilentWolf.configure_scores({
    "open_scene_on_close": "res://scenes/MainPage.tscn"
  })`, 
  g4: `
  SilentWolf.configure({
    "api_key": "YOUR_SILENTWOLF_API_KEY",
    "game_id": "YOUR_SILENTWOLF_GAME_ID",
    "log_level": 1
  })

  SilentWolf.configure_scores({
    "open_scene_on_close": "res://scenes/MainPage.tscn"
  })`
};

const savePlayerDataSnippets = {
  g3: `
  SilentWolf.Players.post_player_data(player_name, player_data)`,
  g4: `
  SilentWolf.Players.save_player_data(player_name, player_data)`
};

const playerDataExampleSnippets = {
  g3: `
  {
    "Weapons": ["Sword", "Shield"],
    "Money": 23
  }`,
  g4: `
  {
    "Weapons": ["Sword", "Shield"],
    "Money": 23
  }`
};

const savePlayerDataOverwriteFalseSnippets = {
  g3: `
  SilentWolf.Players.post_player_data(player_name, player_data, false)`,
  g4: `
  SilentWolf.Players.save_player_data(player_name, player_data, false)`
};
        
const getPlayerDataSnippets = {
  g3: `
  yield(SilentWolf.Players.get_player_data(player_name), "sw_player_data_received")
  print("Player data: " + str(SilentWolf.Players.player_data))`,
  g4: `
  var sw_result = await SilentWolf.Players.get_player_data(player_name).sw_get_player_data_complete
  print("Player data: " + str(sw_result.player_data))`
};

const deletePlayerDataSnippets = {
  g3: `
  SilentWolf.Players.delete_player_data(player_name, player_data)`,
  g4: `
  SilentWolf.Players.delete_player_data(player_name, player_data)`  
};

const deletePlayerDataItemSnippets = {
  g3: `
  var item_to_delete = { "Weapons": "", "Money":  "" }
  SilentWolf.Players.delete_player_data(player_name, item_to_delete)`,
  g4: `
  var item_to_delete = { "Weapons": "", "Money":  "" }
  SilentWolf.Players.delete_player_data(player_name, item_to_delete)`
};

const deleteAllPlayerDataSnippets = {
  g3: `
  SilentWolf.Players.delete_all_player_data(player_name)`,
  g4: `
  SilentWolf.Players.delete_all_player_data(player_name)`
};

const deletePlayerDataItemsSnippets = {
  g3: `
  var item_name = "Weapons"
  SilentWolf.Players.delete_player_items(player_name, item_name)`,
  g4: `
  var item_name = "Weapons"
  SilentWolf.Players.delete_player_items(player_name, item_name)`
};

const deletePlayerDataTopLevelSnippets = {
  g3: `
  SilentWolf.Players.delete_player_data(player_name, { item_name: "" }))`,
  g4: `
  SilentWolf.Players.delete_player_data(player_name, { item_name: "" }))`
};

const saveCharacterDataSnippets = {
  g3: `
  SilentWolf.Players.post_player_data(player_name, player_data, character_name)
  yield(SilentWolf.Players.get_player_data(player_name, character_name), "sw_player_data_received")`,
  g4: `
  SilentWolf.Players.save_player_data(player_name, player_data, character_name)
  await SilentWolf.Players.get_player_data(player_name, character_name).sw_save_player_data_complete`
};


class PlayerDataNextSteps extends Component {

  constructor(props) {
    console.log("constructing PlayerDataNextSteps, props: " + props)
    super(props);
  }

  async componentDidMount() {
  }

  render() {
    return (
      <div className="basicContainer nextStepsContainer">
        <h2 className="titleShort">Next steps</h2>
        <ol className="explainer">
          <li><a href="https://s3.amazonaws.com/silentwolf-downloads/silent_wolf.zip" download>Download the SilentWolf plugin for Godot Engine</a>. </li>
          <li>Decompress the zip file you just downloaded (using 7zip or your system's native archive utility) and copy its contents to the <i>addons</i> folder of your Godot project.</li>
          <li>Start your Godot editor</li>
          <li>Add the SilentWolf script to your Autoload settings in Godot Editor. To do this go to 'Project Settings' under the 'Project' menu and open the 'Autoload' tab. Then add a new autoloaded script by entering the following:
            <ul>
              <li><strong>Path:</strong> res://addons/silent_wolf/SilentWolf.gd</li>
              <li><strong>Node name:</strong> SilentWolf</li>
            </ul>
            <div className="onlyLargeScreen">
              Then press 'Add'. Make sure to call the exact spelling above.
            </div>
            <div className="screenshotMedium">
              <img src={process.env.PUBLIC_URL + "/SilentWolf_screenshot_Godot_1.png"} alt="Add SilentWolf plugin as an autoloaded singleton in Godot Editor" height="531" width="722" />
            </div>
            <div className="onlyLargeScreen">
              Once you are done your Project Settings tab should look like this:
            </div>
            <div className="screenshotMedium">
              <img src={process.env.PUBLIC_URL + "/SilentWolf_screenshot_Godot_2.png"} alt="Godot Project Settings - autoloaded scripts" height="179" width="721" />
            </div>
          </li>
          <li>Before you can use the SilentWolf APIs, you need to configure the plugin with your SilentWolf API key and Game Id. You can also specify the amount of logging you want to get in the console from SilentWolf (0 for errors only, 1 for info-level logging and 2 for debug logging). Finally, if you're using Godot 3.x you can also optionally add the version of the game that you are working on (using <a target="_blank" href="https://semver.org/">semantic versioning</a>, e.g. 1.0.1).
            <CodeBlock codeSnippets={configSnippets} />
            <ShowApiKey {...this.props} />
            <div className="explainer">
               You can do so by calling the SilentWolf.configure function. Make sure that this code is executed at the very beginning of the game. A good place would be the _ready() function of an autoloaded singleton (like your global script) or the script associated with your splash page. Also, make sure to specify your game_id in your configuration (as generated by SilentWolf), not your game name.
            </div>
          </li>
        </ol>
        <h2 className="titleShort">Quick note on mobile exports</h2>
        <div className="explainer">
          Before going into the nitty gritty of saving your game's player data, please note that SilentWolf will need internet access in your target platform. This is by default the case when exporting to desktop and HTML5, but if you're planning on exporting to mobile <a href="/mobileExport">you'll need to follow these instructions</a>.
        </div>
        <h2 className="titleShort">Saving player data</h2>
        <div className="explainer">
          You can persist player data in the SilentWolf backend by calling the following function in the SilentWolf plugin:
        </div>
        <CodeBlock codeSnippets={savePlayerDataSnippets} />
        <div className="explainer">
          In the above function call, player_data is a <a hreef="https://docs.godotengine.org/en/stable/classes/class_dictionary.html">Dictionary</a> containing arbitrary values, for example:
        </div>
        <CodeBlock codeSnippets={playerDataExampleSnippets} />
        <div className="explainer">
          The dictionary can be as complex as you want and include strings, numeric values, booleans or arrays of any of these types. Do not try to store GDScript objects (like Vectors) in the player_data dictionary as this will result in an error. Instead, oif you want to store coordinates, store them as arrays of numbers instead and reconstitute them as Vectors when you retrieve them.  
        </div>
        <div className="explainer">
          You can have quite a large dictionary, but it should not exceed 40KB in size.
        </div>
        <div className="explainer">
          When you post player data for a given player_name, and that player doesn't exist yet in the SilentWolf backend, a player is created and is associated with that player data. If the player already existed (maybe they had a username/password account, or already some player data), the new data becomes associated with that player account.
        </div>
        <div className="explainer">
          By default the new player data will overwrite the old player data, but there is an optional third attribute ('overwrite') to the function that can be set to false. In that case the old player data and the new player data will be merged and the merged version is saved.
        </div>
        <div className="explainer">
          The merge works in this way: top-level items in the dictionary will be overrided by the new values, unless the existing value is an array, in which case the new array objects will be added to the existing ones.
        </div>
        <CodeBlock codeSnippets={savePlayerDataOverwriteFalseSnippets} />
        <h2 className="titleShort">Retrieving player data</h2>
        <div className="explainer">
          To retrieve player data previously stored for a given player, simply call the get_player_data function:
        </div>
        <CodeBlock codeSnippets={getPlayerDataSnippets} />
        <div className="explainer">
          This function will call the SilentWolf backend to retrieve a dictionary containing the latest persisted value of the player's data. <a href="/callingSilentWolf">The SilentWolf.Players.player_data associated variable will be populated once the "sw_player_data_received" signal ("sw_get_player_data_complete" in Godot 4) is emitted</a>.
        </div>
        <h2 className="titleShort">Delete player data</h2>
        <div className="explainer">
          You can just as easily delete previously persisted player data. To do so simply call:
        </div>
        <CodeBlock codeSnippets={deletePlayerDataSnippets} />
        <div className="explainer">
          The above function call deletes only the parts of the top-level elements of the player data that has been previously persisted for a given player. You can selectively delete specific such items using the player_data dictionary, for example:
        </div>
        <CodeBlock codeSnippets={deletePlayerDataItemSnippets} />
        <div className="explainer">
          The above code will delete the "Weapons" and "Money" top-level items from the player's data. If you want to delete all of a player's data in one go you can just use:
        </div>
        <CodeBlock codeSnippets={deleteAllPlayerDataSnippets} />
        <div className="explainer">
          This is a utility function that just calls SilentWolf.Players.delete_player_data(player_name, "") under the hood, deleting all the player data for a given player. Or if you want to delete just one specific top-level item, just call:
        </div>
        <CodeBlock codeSnippets={deletePlayerDataItemsSnippets} />
        <div className="explainer">
          This is another handy utility function; it lets you call the main delete_player_data function passing just the player's name and the name of the top-level item you want to remove:
        </div>
        <CodeBlock codeSnippets={deletePlayerDataTopLevelSnippets} />
        <h2 className="titleShort">Player data and character data</h2>
        <div className="explainer">
          Some games only let a player control a single in-game character. But other games let players control multiple characters at the same time.
        </div>
        <div className="explainer">
          If you're in the second case, it's easy to use SilentWolf's player data for individual characters rather than for the player. Otherwise you can safely use the above player data functions as a proxy for the player's sole character.
        </div>
        <div className="explainer">
          To save and retrieve data related to one of the player's potentially multiple characters, simply add an optional character_name attribute to the function calls:
        </div>
        <div className="explainer">
          If you delete all of a player's player data, and the player didn't have any other information within the SilentWolf backend, that player will be entirely deleted.
        </div>
        <h2 className="titleShort">Game state and other uses of player data</h2>
        <div className="explainer">
          SilentWolf's player data features can be leveraged for other uses than data strictly related to players, like for example game state. You can specify a dummy player_name (such as 'game_state#12345') and save data related to a particular session or level that the players are involved in.
        </div>
        <div className="explainer">
          You can also use player data as a basis for multiplayer match making and turn-based multiplayer games if latency is not a problem for you. SilentWolf will soon offer proper turn-based and real-time multiplayer features.
        </div>
        <CodeBlock codeSnippets={saveCharacterDataSnippets} />
        <h2 className="titleShort">Troubleshooting</h2>
        <div className="explainer">
          Check out our <a href="/troubleshooting">troubleshooting guide</a> to help you fix typical problems you might be facing.
        </div>
        <h2 className="titleShort">Get in touch</h2>
        <div className="explainer">
          <Link to="/contact">Contact us</Link> and we can help you integrate player data (or any other feature requiring backend servers) into your game.
        </div>
      </div>
    )
  }
}

export default PlayerDataNextSteps

/**
  Godot Asset Library: <a href="https://godotengine.org/asset-library/asset">https://godotengine.org/asset-library/asset</a>
*/
