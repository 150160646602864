import React from 'react';
import CodeBlock from '../Utils/Controls/CodeBlock'

// Snippets for the code blocks for Godot 3.x and Godot 4.x
const connectingSnippets = {
  g3: `
  func _ready():
    SilentWolf.Auth.connect("sw_registration_succeeded", self, "_on_registration_succeeded")
    SilentWolf.Auth.connect("sw_registration_failed", self, "_on_registration_failed")

  func _on_registration_succeeded():
    print("Registration succeeded!")
    
  func _on_registration_failed(error):
    print("Error: " + str(error))`, 
  g4: `
  func _ready():
    SilentWolf.Auth.sw_registration_complete.connect(_on_registration_complete)

  func _on_registration_complete(sw_result: Dictionary) -> void:
  if sw_result.success:
    print("Registration succeeded!")
  else:
    print("Error: " + str(sw_result.error))`
};

const coroutineSnippets = {
  g3: `
  add_loading_scores_message()
  yield(SilentWolf.Scores.get_high_scores(), "sw_scores_received")
  hide_message()
  render_board(SilentWolf.Scores.scores, local_scores)`,
  g4: `
  add_loading_scores_message()
  async SilentWolf.Scores.get_high_scores().sw_get_scores_complete
  hide_message()
  render_board(SilentWolf.Scores.scores, local_scores)`
};

const CallingSW = props => {

  return (
    <div className="CallingSW">
      <div className="basicContainer">
        <h1>Calling SilentWolf</h1>
        <div className="explainer basicExplainer">
          SilentWolf consists of a set of backend services and a plugin for Godot engine. To do anything useful, the plugin will need to communicate with the backend over the Internet. We're doing everything we can to make the backend performant and to reduce latency as much as possible, but you any time you have a round trip to the backend, there will be some latency that you will have to manage within your game. Keep reading to understand how to deal with this.
        </div>
        <h2 className="titleShort">SilentWolf functions and associated variables</h2>
        <div className="explainer basicExplainer">
          Each SilentWolf function (such as SilentWolf.Scores.get_scores()) that retrieves data from the backend is associated with a variable in the same script (such as SilentWolf.Scores.scores). If the function completes successfully, it will populate the associated variable with the result. In our example, when SilentWolf.Scores.get_high_scores() completes, it populates the SilentWolf.Scores.scores variable with an array of dictionaries containing the scores retrieved from the backend.
        </div>
        <div className="explainer basicExplainer">
          So once you have called the SilentWolf.Scores.get_scores() (SilentWolf.Scores.get_high_scores() in Godot 3.x) function and unless you want to refresh the scores, you only need to look at the contents of the SilentWolf.Scores.scores variable to get the list of scores. This will save you a round trip to the backend. The first way to manage latency in your code is to only call the backend when you really need to.
        </div>
        <h2 className="titleShort">Godot signals</h2>
        <div className="explainer basicExplainer">
          The problem with asynchronous code is that you don't know when it will complete. In the example above, if you call SilentWolf.Scores.get_scores() and immediately look up the value of the SilentWolf.Scores.scores variable, it will probably be empty because the round trip to the backend is still in progress.
        </div>
        <div className="explainer basicExplainer">
          How do you know when the function has completed? After each SilentWolf async function is called, <a href="https://docs.godotengine.org/en/stable/tutorials/scripting/gdscript/gdscript_basics.html#signals" target="_blank">a signal is emitted</a> when it completes (and sometimes even when it fails, as is the case for the SilentWolf.Auth.login_player and SilentWolf.Auth.register_player). In our example, after the SilentWolf.Scores.get_scores() function completes, the "sw_get_scores_complete" ("sw_scores_received" in Godot 3.x) signal is emitted. If you have a function in your game that waits for this signal to be emitted, you can be confident that the SilentWolf.Scores.scores variable will also contain the latest scores from the backend.
        </div>
        <div className="explainer basicExplainer">
          Unless otherwise specified, this is how all SilentWolf functions work.
        </div>
        <h2 className="titleShort">Connecting a signal</h2>
        <div className="explainer basicExplainer">
          You can easily trigger code whenever a signal is emitted. To do so, simply connect a GDScript function to the signal like this:
        </div>
        <CodeBlock codeSnippets={connectingSnippets} />
        <div className="explainer basicExplainer">
          This is useful when a single function can lead to more than one signal being emitted. If you're only waiting for one signal, using coroutines with the async (yield in Godot 3.x) keyword is the easiest approach.
        </div>
        <h2 className="titleShort">Coroutines with async</h2>
        <div className="explainer basicExplainer">
          Godot provides a very handy way to interrupt a function to wait for a signal, and to start from where you stopped once it is emitted, all in one line of code. This construct is called <a href="https://docs.godotengine.org/en/stable/tutorials/scripting/gdscript/gdscript_basics.html#coroutines-with-yield" target="_blank">a coroutine and makes use of the "async" keyword</a> that is built into Godot.
        </div>
        <div className="explainer basicExplainer">
          This is the approach that is used in the built-in scenes that come with the SilentWolf addon. In our example, when we want to retrieve the latest scores from the backend to display them in a leaderboard, the script attached to the built-in Leaderboard scene first displays a message to the player that the scores are being loaded, then calls the SilentWolf.Scores.get_scores() function and waits for the "sw_get_scores_complete" signal using the async keyword:
        </div>
        <div className="explainer basicExplainer">
          If you're using Godot 4, calling a function using async will return a Dictionary called sw_result that will contain all the information about the result of your backend function call, including the data if you you were feetching something. For example if you called SilentWolf.Scores.get_scores() using async you can find the returned scores in sw_result.scores.
        </div>
        <CodeBlock codeSnippets={coroutineSnippets} />
        <div className="explainer basicExplainer">
          Once the scores are retrieved from the backend, the signal is emitted and the rest of the function is executed. The processing message is hidden and the leaderboard is rendered using the contents of the SilentWolf.Scores.scores variable.
        </div>
        <div className="explainer basicExplainer">
          If you don't want to make the player wait for the scores to load, you might want to consider pre-loading the scores from the backend at another moment, before the leaderboard is displayed to the player.
        </div>
        <h2 className="titleShort">Fire and forget</h2>
        <div className="explainer basicExplainer">
          Of course if you don't need to wait for the backend call to complete, you don't need to wait for a signal to be emitted. For example, if you want to publish a new high score to the backend, you can simply call SilentWolf.Scores.save_score(score) (SilentWolf.Scores.post_new_score(score) oin Godot 3.x) and then immediately move on. This is called fire-and-forget.
        </div>
      </div>
    </div>
  )
}

export default CallingSW
