import React, {useState} from "react";
import ModalButton from '../../../../Utils/Controls/ModalButton';

import './ConfirmDeleteScore.css'

const ConfirmDeleteScore = props => {

    const [formSubmitting, setFormSubmitting] = useState(false)
    const [formError, setFormError] = useState(null)

    const deleteScore = async () => {
        setFormSubmitting(true)
        console.log("deleteScore, scoreId: ", props.scoreId)
        console.log("deleteScore, props.onDelete: ", props.onDelete)
        const result = await props.onDelete(props.scoreId)
        console.log("deleteScore, result:")
        console.log(result)
        if(result === 'success') {
            setFormSubmitting(false)
            props.onClose()
        } else {
            setFormError(result.error)
            setFormSubmitting(false)
        }
    }

    console.log("In ConfirmDeleteScore modal")
    if(!props.show){
        return null;
    }
    return (
        <div className="modal">
            <div className="modalContent">
                {props.children}
                <div className="modalButtons">
                    <ModalButton buttonStyle="modalButton secondaryButton deleteButton"
                                handleClick={() => deleteScore()} 
                                text="Delete" 
                                loading={formSubmitting} />
                    {/*<button className="secondaryButton" onClick={() => props.onDelete(props.scoreId)}>
                    Delete
    </button>*/}
                    <button className="btn" onClick={props.onClose}>
                    Cancel
                    </button>
                </div>
            </div>
        </div>
    );
} 

export default ConfirmDeleteScore;


/**
 * if(!this.props.show){
          return null;
      }
    return <div>Hello Modal</div>;
 */