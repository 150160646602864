import React from 'react';
import "./Leaderboard.css";

const IntroText = () =>
  <div className="introTextContainer">
    <h1 className="leaderboardFree">Add a leaderboard to your game for free</h1>
    <div className="explainer">
      SilentWolf enables you to include a leaderboard in your game for free. Collect scores in our secure backend servers, fetch historical scores and display top scores in your game easily.
    </div>
    <div className="explainer">
      To include a leaderboard in your game, follow the instructions below.
    </div>
  </div>

export default IntroText
