import React from 'react';
import "./Button.css";

const ModalButton = (props) => {

    const buttonStyle = () => {
        return `submitButton ${props.buttonStyle}`
    }

    return (
        <button className={buttonStyle()} onClick={props.handleClick} type="submit" disabled={props.loading}>
            <span className={props.textStyle}>
                {props.loading && (
                    <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                    />
                )}
                {props.text}
            </span>
        </button>
    )
}

export default ModalButton;
