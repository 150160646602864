import React, { Component }  from 'react';
import { Link } from 'react-router-dom'
import AuthSettings from './AuthSettings'
import ShowApiKey from '../Utils/ShowApiKey'
import CodeBlock from '../Utils/Controls/CodeBlock'
import './PlayerAuth.css';

// Snippets for the code blocks for Godot 3.x and Godot 4.x
const configSnippets = {
  g3: `
  SilentWolf.configure({
    "api_key": "YOUR_SILENTWOLF_API_KEY",
    "game_id": "YOUR_SILENTWOLF_GAME_ID",
    "game_version": "1.0.2",
    "log_level": 1
  })

  SilentWolf.configure_scores({
    "open_scene_on_close": "res://scenes/MainPage.tscn"
  })`, 
  g4: `
  SilentWolf.configure({
    "api_key": "YOUR_SILENTWOLF_API_KEY",
    "game_id": "YOUR_SILENTWOLF_GAME_ID",
    "log_level": 1
  })

  SilentWolf.configure_scores({
    "open_scene_on_close": "res://scenes/MainPage.tscn"
  })`
};

const registerPlayerSnippets = {
  g3: `
  SilentWolf.Auth.register_player(player_name, email, password, confirm_password)`,
  g4: `
  SilentWolf.Auth.register_player(player_name, email, password, confirm_password)`
};

const registrationResultSnippets = {
  g3: `
  func _ready():
    SilentWolf.Auth.connect("sw_registration_succeeded", self, "_on_registration_succeeded")
    SilentWolf.Auth.connect("sw_registration_failed", self, "_on_registration_failed")
    

  func _on_registration_succeeded():
    print("Registration succeeded!")
    

  func _on_registration_failed(error):
    print("Error: " + str(error))`,
  g4: `
  func _ready():
    SilentWolf.Auth.sw_registration_complete.connect(_on_registration_complete)
  

  func _on_registration_complete(sw_result: Dictionary) -> void:
    if sw_result.success:
      print("Registration succeeded!")
    else:
      print("Error: " + str(sw_result.error))`
};

const verifyEmailSnippets = {
  g3: `
  var player_name = SilentWolf.Auth.tmp_username
  SilentWolf.Auth.verify_email(player_name, code)`,
  g4: `
  var player_name = SilentWolf.Auth.tmp_username
  SilentWolf.Auth.verify_email(player_name, code)`
};

const resendConfCodeSnippets = {
  g3: `
  var player_name = SilentWolf.Auth.tmp_username
  SilentWolf.Auth.resend_conf_code(player_name)`,
  g4: `
  var player_name = SilentWolf.Auth.tmp_username
  SilentWolf.Auth.resend_conf_code(player_name)`
};

const accountConfirmationSnippets = {
  g3: `
  func _ready():
    SilentWolf.Auth.connect("sw_email_verif_succeeded", self, "_on_confirmation_succeeded")
    SilentWolf.Auth.connect("sw_email_verif_failed", self, "_on_confirmation_failed")
    SilentWolf.Auth.connect("sw_resend_conf_code_succeeded", self, "_on_resend_code_succeeded")
    SilentWolf.Auth.connect("sw_resend_conf_code_failed", self, "_on_resend_code_failed")


  func _on_confirmation_succeeded():
    print("email verification succeeded!")
    print("player successfully logged in: " + str(SilentWolf.Auth.logged_in_player))
    get_tree().change_scene("<your-next-scene")


  func _on_confirmation_failed(error):
    print("email verification failed: " + str(error))


  func _on_resend_code_succeeded():
    print("Code resend successful for player: " + str(SilentWolf.Auth.tmp_username))


  func _on_resend_code_failed():
    print("Code resend failed for player: " + str(SilentWolf.Auth.tmp_username))`,
  g4: `
  func _ready():
    SilentWolf.Auth.sw_email_verif_complete.connect(_on_confirmation_complete)
	  SilentWolf.Auth.sw_resend_conf_code_complete.connect(_on_resend_code_complete)


  func _on_confirmation_complete(sw_result: Dictionary) -> void:
    if sw_result.success:
      print("email verification succeeded!")
      print("player successfully logged in: " + str(SilentWolf.Auth.logged_in_player))
      get_tree().change_scene("<your-next-scene")
    else:
      print("email verification failed: " + str(sw_result.error))


  func _on_resend_code_complete(sw_result: Dictionary) -> void:
    if sw_result.success:
      print("Code resend successful for player: " + str(SilentWolf.Auth.tmp_username))
    else:
      print("Code resend failed for player: " + str(SilentWolf.Auth.tmp_username))`
};


const loginPlayerSnippets = {
  g3: `
  SilentWolf.Auth.login_player(username, password)`,
  g4: `
  SilentWolf.Auth.login_player(username, password)`
};

const loginResultSnippets = {
  g3: `
  func _ready():
    SilentWolf.Auth.connect("sw_login_succeeded", self, "_on_login_succeeded")
    SilentWolf.Auth.connect("sw_login_failed", self, "_on_login_failed")


  func _on_login_succeeded():
    print("Login succeeded!")
    print("logged in as: " + str(SilentWolf.Auth.logged_in_player))


  func _on_login_failed(error):
    print("Error: " + str(error))`,
  g4: `
  func _ready():
    SilentWolf.Auth.sw_login_complete.connect(_on_login_complete)

  func _on_login_complete(sw_result: Dictionary) -> void:
    if sw_result.success:
      print("Login succeeded!")
      print("logged in as: " + str(SilentWolf.Auth.logged_in_player))
    else:
      print("Error: " + str(sw_result.error))`
};

const requestPlayerPasswordResetSnippets = {
  g3: `
  SilentWolf.Auth.request_player_password_reset(player_name)`,
  g4: `
  SilentWolf.Auth.request_player_password_reset(player_name)`
};

const resetPlayerPasswordSnippets = {
  g3: `
  SilentWolf.Auth.reset_player_password(player_name, code, password, confirm_password)`,
  g4: `
  SilentWolf.Auth.reset_player_password(player_name, code, password, confirm_password)`
};

const savedSessionSnippets = {
  g3: `
  SilentWolf.Auth.login_player(username, password, true)`,
  g4: `
  SilentWolf.Auth.login_player(username, password, true)`
};

const autoLoginSnippets = {
  g3: `
  SilentWolf.Auth.auto_login_player()`,
  g4: `
  SilentWolf.Auth.auto_login_player()`
};

const autoLoginPlayerSnippets = {
  g3: `
  yield(SilentWolf.Auth.auto_login_player(), "sw_session_check_complete")
  get_tree().change_scene("res://scenes/MainScene.tscn")`,
  g4: `
  async SilentWolf.Auth.auto_login_player().sw_session_check_complete
  get_tree().change_scene("res://scenes/MainScene.tscn")`
};

const logoutSnippets  = {
  g3: `
  SilentWolf.Auth.logout_player()`,
  g4: `
  SilentWolf.Auth.logout_player()`
};


class PlayerAuthNextSteps extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div className="basicContainer nextStepsContainer">
        <h2 className="titleShort">Next steps</h2>
        <ol className="explainer">
          <li><a href="https://s3.amazonaws.com/silentwolf-downloads/silent_wolf.zip" download>Download the SilentWolf plugin for Godot Engine</a>. </li>
          <li>Decompress the zip file you just downloaded (using 7zip or your system's native archive utility) and copy its contents to the <i>addons</i> folder of your Godot project.</li>
          <li>Start your Godot editor</li>
          <li>Add the SilentWolf script to your Autoload settings in Godot Editor. To do this go to 'Project Settings' under the 'Project' menu and open the 'Autoload' tab. Then add a new autoloaded script by entering the following:
            <ul>
              <li><strong>Path:</strong> res://addons/silent_wolf/SilentWolf.gd</li>
              <li><strong>Node name:</strong> SilentWolf</li>
            </ul>
            <div className="onlyLargeScreen">
              Then press 'Add'. Make sure to call the exact spelling above.
            </div>
            <div className="screenshotMedium">
              <img  src={process.env.PUBLIC_URL + "/SilentWolf_screenshot_Godot_1.png"} alt="Add SilentWolf plugin as an autoloaded singleton in Godot Editor" height="531" width="722" />
            </div>
            <div className="onlyLargeScreen">
              Once you are done your Project Settings tab should look like this:
            </div>
            <div className="screenshotMedium">
              <img src={process.env.PUBLIC_URL + "/SilentWolf_screenshot_Godot_2.png"} alt="Godot Project Settings - autoloaded scripts" height="179" width="721" />
            </div>
          </li>
          <li id="pluginConfig">Before you can use the SilentWolf APIs, you need to configure the plugin with your SilentWolf API key and Game Id. You can also specify the amount of logging you want to get in the console from SilentWolf (0 for errors only, 1 for info-level logging and 2 for debug logging). Finally, if you're using Godot 3.x you can also optionally add the version of the game that you are working on (using <a target="_blank" href="https://semver.org/">semantic versioning</a>, e.g. 1.0.1).
            <CodeBlock codeSnippets={configSnippets} />
            <ShowApiKey {...this.props} />
            <div className="explainer">
               You can do so by calling the SilentWolf.configure function. Make sure that this code is executed at the very beginning of the game. A good place would be the _ready() function of an autoloaded singleton (like your global script) or the script associated with your splash page. Also, make sure to specify your game_id in your configuration (as generated by SilentWolf), not your game name.
            </div>
            <div className="explainer">
               In the example code above, we've included a call to the SilentWolf.configure_auth function. The redirect_to_scene value is useful if you use the <a href="/playerauth#builtinScenes">built-in Register, RegisterUsernamePassword, ConfirmEmail and/or Login scene</a> and want to let SilentWolf know where to send the player after they log in successfully.
            </div>
            <div className="explainer">
              Also, if you're using the built-in Register scene and you <a href="/playerauth#configurePlayerAuth">configure your game so that players need to verify their email address</a> to complete their registration, you will need to specify which scene to use to request the player's confirmation code. By default this is set to the built-in ConfirmEmail scene.
            </div>
            <div className="explainer">
              Similarly, the "login_scene" and "reset_password_scene" indicate which scenes to switch to when the player wants to log in or reset their password. If you're not using the built-in scenes, youcan ignore this configuration.
            </div>
            <div className="explainer">
               The session_duration_seconds value (in seconds) lets you override the default the time a player remains signed into your game after log in. By default the session doesn't expire, so the player will stay logged in until they close the game. If that's what you want you can set the "session_duration_seconds" value to 0. If on the contrary you want the user to be automatically logged out after an hour, set this value to 3600.
            </div>
            <div className="explainer">
               You may also want to remember a user's signed in session even after they close the game, so that they don't have to log in every time they want to play on the same pc, mobile device or browser. This feature is called <a href="/playerauth#savedSessions">Saved Sessions</a>. You can configure how long you want SilentWolf to remember the player's signed in status using the "saved_session_expiration_days" value. The default value is 30 days, but you can set it to 365 if you want saved sessions to persist for a year. In any case the saved session will be deleted if the user logs out.
            </div>
            <div className="explainer">
               If you're using saved sessions, it probably doesn't make sense to set the value of "session_duration_seconds" to anything but 0.
            </div>
          </li>
        </ol>
        <h2 className="titleShort">Quick note on mobile exports</h2>
        <div className="explainer">
          Before we delve into the nitty gritty of enabling player registration and authentication in your game, please note that SilentWolf will need internet access in your target platform. This is by default the case when exporting to desktop and HTML5, but if you're planning on exporting to mobile <a href="/mobileExport">you'll need to follow these instructions</a>.
        </div>
        <h2 className="titleShort" id="configurePlayerAuth">Configure player authentication</h2>
        <div className="explainer">
          In order to let players register and log in to your game via the SilentWolf backend, you first need to enable player authentication for your game. This is as easy as checking the box below:
        </div>
        <AuthSettings name="authSettings" gameId={this.props.gameId} username={this.props.username} gameName={this.props.gameName} games={this.props.games} />
        {/*<div className="explainer">
          <Checkbox name="authEnabled" label={this.state.checkboxLabel} isSelected={this.state.authEnabled} onChange={this.handleCheckboxChange} />
        </div>
        <div className="explainer">
          If you don't enable player authentication, or if you disable it at any point, you'll get an error when you try to call the SilentWolf Auth APIs.
        </div>
        { this.state.authEnabled ? <AuthSettings name="authSettings" authSettings={authSettings} /> : null }
        */}
        <h2 className="titleShort">Player registration with player name, email and password</h2>
        <div className="explainer">
          Before the player can log into your game you want them to register their account. To do so you typically request them to enter a player name, an email address, and a password (usually twice to make sure they entered it correctly). The following API call will let you register a given player within your game:
        </div>
        <CodeBlock codeSnippets={registerPlayerSnippets} />
        <div className="explainer">
          Based on the above function call, two things can happen. Either registration succeeds or it fails. In either case the SilentWolf plugin will emit a signal that your code can connect to:
        </div>
        <CodeBlock codeSnippets={registrationResultSnippets} />
        <div className="explainer">
          Registration can fail for any number of reasons, e.g. the player entered a password that doesn't conform to the password policy (by default, passwords must contain at least 8 characters with a mix of uppercase, lowercase, numeric and (optionally) special characters, but you can request a change your password policy by <a href="https://silentwolf.com/contact">contacting us</a>, or they entered two passwords that didn't match.
        </div>
        <div className="explainer">
          Behind the scenes this function will securely send the player registration details to the SilentWolf backend and create the user. The response includes specific error messages if the player has submitted a player name that already exists, an invalid email address or passwords that don't comply with SilentWolf's reasonable password policy or if the passwords don't match.
        </div>
        <div className="explainer">
          After successful registration the player will be logged in (and you can access their username: SilentWolf.Auth.logged_in_player) unless you enabled email confirmation in your game.
        </div>
        <h2 className="titleShort">Email confirmation</h2>
        <div className="explainer">
          If you've <a href="playerauth#configurePlayerAuth">enabled email confirmation</a> in your game, after successful username/password registration the player will receive a one-time code at the email address that they specified during sign up. Until they do so their registration will not be complete and they will not be logged in, but their username will already be availabel in the variable SilentWolf.Auth.tmp_username.
        </div>
        <div className="explainer">
          If you're using the built-in Register scene, the player will automatically be redirected to <a href="/playerauth/pluginConfig">the scene that you configured using SilentWolf.configure_auth</a>. This should be a scene that lets the player enter this code. If you're not using the built-in scenes, you need to switch to your email confirmation scene after the player passes the registration step. In the associated script of this new scene, you can call the following function:
        </div>
        <CodeBlock codeSnippets={verifyEmailSnippets} />
        <div className="explainer">
          In Godot 3, if the code is valid, the "sw_email_verif_succeeded" signal will be fired and the player will be logged in. If the code is invalid, the "sw_email_verif_failed" signal will be fired. 
        </div>
        <div className='explainer'>
          In Godot 4, the "sw_email_verif_complete" signal will be fired in either case, and the "success" parameter will be true if the code is valid, false otherwise (see sample code above).
        </div>
        <div className="explainer">
          In both Godot 3 and 4, the SilentWolf.Auth.logged_in_player variable will contain the player's username. 
        </div>
        <div className="explainer">
          In case for whatever reason the player did not receive the email with the one-time confirmation code, you have the option to resend a new code to the same player by calling:
        </div>
        <CodeBlock codeSnippets={resendConfCodeSnippets} />
        <div className="explainer">
          In Godot 3, if the resend is successful the "sw_resend_conf_code_succeeded" signal will be fired and the player can submit the new code. If not, the "sw_resend_conf_code_failed" is fired.
        </div>
        <div className="explainer">
          In Godot 4, the "sw_resend_conf_code_complete" signal will be fired in eeither case, and the "success" parameter will be true if the resend was successful, false otherwise.
        </div>
        <div className="explainer">
          Here is some sample code that you could use in your account confirmation scene:
        </div>
        <CodeBlock codeSnippets={accountConfirmationSnippets} />
        <div className="explainer">
          As usual, you can also just use (or draw inspiration from) the ConfirmEmail.tscn scene that is built into the plugin (along withs attached ConfirmEmail.gd script).
        </div>
        <h2 className="titleShort">Player login</h2>
        <div className="explainer">
          After registration the player will be able to log into his account in your game. The below function call will let you log the player in:
        </div>
        <CodeBlock codeSnippets={loginPlayerSnippets} />
        <div className="explainer">
          This function will log the player into your game. When the player is logged in the variable SilentWolf.Auth.logged_in_player will hold their username. You can check against this value to see whether or not a player is logged in or to use the username within the game.
        </div>
        <div>
          If the player has entered an invalid username/password combination the response includes an error message. If log in is successful the response will include a token that will be sent along with future backend requests to identify the player making the request for the duration of the player's session.
        </div>
        <CodeBlock codeSnippets={loginResultSnippets} />
        <div>
          If authentication was successful, <a href="/callingSilentWolf">the SilentWolf.Auth.logged_in_player associated variable will contain the logged in player's name.</a>
        </div>
        <div className="explainer">
          By default the session duration is one hour, but you can modify this value in the SilentWolf <a href="/playerauth#pluginConfig">addon configuration</a>.
        </div>
        <div className="explainer">
          After logging in the player will be redirected to the configured scene (as specified in SilentWolf.auth_config)
        </div>
        <h2 className="titleShort" id="resetPassword">Reset password</h2>
        <div className="explainer">
          If you allow your players to log in using a username/email address and a password, sooner or later your players will want to reset their password, either because they don't remember their current password, or because they think their current password might be compromised.
        </div>
        <div className="explainer">
          SilentWolf makes this easy using the Reset Password feature. There are two steps to resetting a player password:
        </div>
        <ol>
          <li>Requesting a password reset</li>
          <li>Actually resetting the password</li>
        </ol>
        <div className="explainer">
          In the first step, the player requests to have their password reset. To do this call the following function:
        </div>
        <CodeBlock codeSnippets={requestPlayerPasswordResetSnippets} />
        <div className="explainer">
          Passing the player's player_name as an argument. Most of the time the player will want to reset their password while not currently logged in, so you will need to prompt the player for their player_name.
        </div>
        <div className="explainer">
          In Godot 3, you will know that the request has succeeded as soon as the "sw_request_password_reset_succeeded" is emitted (if the request fails the "sw_request_password_reset_failed" signal is emitted). As soon as the request goes through the player's current password will be invalidated so the player will need to finish the process and select a new password before they can log in again.
        </div>
        <div className="explainer">
          In Godot 4, the "sw_request_password_reset_complete" signal will be emitted in either case, and the "success" parameter will be true if the request was successful, false otherwise.
        </div>
        <div className="explainer">
          SilentWolf will then automatically send an email with a one-time code to the player's email address. We're ready for the next step.
        </div>
        <div className="explainer">
          In the second step you will prompt the player for the code that was sent to them by email and for their new password (that they should enter twice). You can then call the function:
        </div>
        <CodeBlock codeSnippets={resetPlayerPasswordSnippets} />
        <div className="explainer">
          In Godot 3, you will know that the password reset has succeeded when you the "sw_reset_password_succeeded" signal is emitted ("sw_reset_password_failed" otherwise). 
        </div>
        <div className="explainer">
          In Godot 4, the "sw_reset_password_complete" signal will be emitted in either case, and the "success" parameter will be true if the reset was successful, false otherwise.
        </div>
        <div className="explainer">
          Usually you will then want to switch to the login scene so that they can test their brand new password.
        </div>
        <div className="explainer">
          The built-in scene ResetPassword.tscn (and its associated script ResetPassword.gd) performs all of the above steps. You can use it in your game or draw inspiration from it to build your own reset password flow.
        </div>
        <div className="explainer">
          If you do end up using the built-in scene, you will need to configure your "login_scene" when calling SilentWolf.configure_auth() (see <a href="/playerauth#pluginConfig">section on plugin configuration</a>). This is the scene that the built-in ResetPassword scene will switch to after a successful password reset.
        </div>
        <h2 className="titleShort" id="savedSessions">Saved sessions</h2>
        <div className="explainer">
          When a player logs in to your game, you have the option to have SilentWolf remember their signed in status for a certain amount of time (30 days by default). That means that they don't need to enter their credentials every time they open your game on the same device or the same browser.
        </div>
        <div className="explainer">
          To request the user's sign in to be remembered, just pass an extra optional parameter to the login_player function described above:
        </div>
        <CodeBlock codeSnippets={savedSessionSnippets} />
        <div className="explainer">
          Typically you would ask the player's permission before saving their session for extended periods of time, so the boolean value of this extra parameter would be set based on whether or not they checked a box in the login form. This is implemented in the built-in Login scene.
        </div>
        <div className="explainer">
          You can configure how long you want these saved sessions to persist. By default the player stays signed in to the game for 30 days (as long as they use the same device or browser and don't log out), but you can configure a different length (in days) <a href="/playerauth#pluginConfig">using "session_duration_seconds" when calling SilentWolf.configure_auth()</a>.
        </div>
        <div className="explainer">
          The next time the player loads the game, whether the game is exported on Windows, Mac or Linux or even run in the browser, you can check whether a valid saved session exists by calling:
        </div>
        <CodeBlock codeSnippets={autoLoginSnippets} />
        <div className="explainer">
          If there is a saved session this function will load it and validate it against the backend. If the saved session checks out the SilentWolf.Auth.logged_in_player variable will take the value of the signed in player. Whether the auto login works or not, the "sw_session_check_complete" signal will be emitted, so you will know that you can load the game.
        </div>
        <div className="explainer">
          Since the auto_login_player() function needs to call the backend to validate the session, it's best to call it using a coroutine and the yield keyword:
        </div>
        <CodeBlock codeSnippets={autoLoginPlayerSnippets} />
        <h2 className="titleShort">Logout</h2>
        <div className="explainer">
          If a player is already authenticated, you can let them do a clean log out by calling:
        </div>
        <CodeBlock codeSnippets={logoutSnippets} />
        <div className="explainer">
          This will reset the SilentWolf.Auth.logged_in_player variable to null and remove any saved sessions and associated player data. The "sw_logout_succeeded" signal ("sw_logout_complete" in Godot 4) will be emitted once logout is complete.
        </div>
        <h2 className="titleShort">SilentWolf dashboard (new!)</h2>
        <div className="explainer">
          You can view all of your players, change their password, disable or even delete any of them via <a href="https://silentwolf.com/dashboard/players">your SilentWolf dashboard</a>.
        </div>
        <div className="explainer">
          NB: if your players don't appear on the dashboard you may need to log out and log in again.
        </div>
        <div className="explainer">
          We'll be adding many new features to the dashboard in the near future. Stay tuned!
        </div>
        <h2 className="titleShort" id="builtinScenes">Built-in scenes</h2>
        <div className="explainer">
          You can create your own scenes to capture the player's input for registration, login and reset password. The SilentWolf plugin for Godot includes sample Register, ConfirmEmail, Login  and ResetPassword scenes in the Auth folder.
        </div>
        <div className="screenshotMedium twoScreenshots">
          <div className="screenshot">
            <img src={process.env.PUBLIC_URL + "/Register_scene.png"} alt="Built-in SilentWolf register scene" height="240" width="374" />
          </div>
          <div className="screenshot">
            <img src={process.env.PUBLIC_URL + "/ConfirmEmail_scene.png"} alt="Built-in SilentWolf confirm email scene" height="240" width="374" />
          </div>
        </div>
        <div className="screenshotMedium twoScreenshots">
          <div className="screenshot">
            <img src={process.env.PUBLIC_URL + "/RegisterUserPassword_scene.png"} alt="Built-in SilentWolf login scene" height="200" width="356" />
          </div>
          <div className="screenshot">
            <img src={process.env.PUBLIC_URL + "/Login_scene.png"} alt="Built-in SilentWolf login scene" height="200" width="356" />
          </div>
        </div>
        <div className="screenshotMedium twoScreenshots">
          <div className="screenshot">
            <img src={process.env.PUBLIC_URL + "/ResetPassword_scene_1.png"} alt="Built-in SilentWolf reset password scene - step 1" height="237" width="370" />
          </div>
          <div className="screenshot">
            <img src={process.env.PUBLIC_URL + "/ResetPassword_scene_2.png"} alt="Built-in SilentWolf reset password scene - step 2" height="237" width="370" />
          </div>
        </div>
        <div className="explainer">
          Each of these scenes has an attached script that you can use: Auth/Register.gd, Auth/ConfirmEmail.gd, Auth/RegisterUsernamePassword.gd, Auth/Login.gd and/or Auth/ResetPassword.gd.
        </div>
        <div className="explainer">
          You can also customize the built-in SilentWolf scenes to match your game's visual style. It's best to create new scenes whose scripts extend from Auth/Register.tscn, Auth/ConfirmEmail.tscn, Auth/RegisterUsernamePassword.gd and/or Auth/Login.tscn and modify them so that plugin upgrades don't override your customizations.
        </div>
        <h2 className="titleShort">Troubleshooting</h2>
        <div className="explainer">
          Check out our <a href="/troubleshooting">troubleshooting guide</a> to help you fix typical problems you might be facing.
        </div>
        <h2 className="titleShort">Get in touch</h2>
        <div className="explainer">
          <Link to="/contact">Contact us</Link> and we can help you integrate these features (or any other feature requiring backend servers) into your game.
        </div>
      </div>
    )
  }
}

export default PlayerAuthNextSteps

/**
  Godot Asset Library: <a href="https://godotengine.org/asset-library/asset">https://godotengine.org/asset-library/asset</a>
*/
