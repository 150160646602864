import React, {useState} from "react";
import ModalButton from '../../../../Utils/Controls/ModalButton'

const ConfirmEnablePlayer = props => {

    const [formSubmitting, setFormSubmitting] = useState(false)
    const [formError, setFormError] = useState(null)

    const enablePlayer = async () => {
        setFormSubmitting(true)
        const result = await props.onEnable(props.playerName)
        console.log("enablePlayer, result:")
        console.log(result)
        if(result.success) {
            props.onClose()
        } else {
            setFormError(result.error)
        }
        setFormSubmitting(false)
    }

    if(!props.show){
        return null;
    }
    return (
        <div className="modal">
            <div className="modalContent">
                {props.children}
                <div className="modalButtons">
                    <ModalButton buttonStyle="modalButton secondaryButton enableButton"
                            handleClick={() => enablePlayer()} 
                            text="Enable" 
                            loading={formSubmitting} />
                    <button className="btn" onClick={props.onClose}>
                        Cancel
                    </button>
                </div>
                { formError ?
                    <div className="explainer exceptions modalExceptions">
                        {formError}
                    </div>
                : null }
            </div>
        </div>
    );
} 

export default ConfirmEnablePlayer;