import React from 'react';

const ReadOnlyTextField = ({ label, name, id, defaultValue }) => (
  <div className="textInputField">
    <label className="inputFieldLabel" htmlFor={id}>
      {label}
    </label>
    <div name={name} id={id}  className="inputFieldReadOnlyText">
      {defaultValue}
    </div>
  </div>
);

export default ReadOnlyTextField;
