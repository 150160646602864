import React from "react";
import "./Footer.css";

const Footer = () => 
<div className="footer">
  <div className="footerContent">
      <div className="footerTextContentItem">
          <a href="/terms" className="footerLink">Terms of service</a>
      </div>
      <div className="footerTextContentItem">
          <a href="/privacy" className="footerLink">Privacy policy</a>
      </div>
      <div className="footerTextContentItem">
          <a href="/blog/godot4.html" className="footerLink">Blog</a>
      </div>
      <div className="footerTextContentItem">
          <a href="/contact" className="footerLink">Contact</a>
      </div>
      <div className="footerTextContentItem">
          <a target="_twitter" href="https://twitter.com/SilentWolfCloud">
              <img src="./img/social/twitter_logo.png" alt="SilentWolf Twitter account" className="twitterLogo" />
          </a>
      </div>
  </div>
  <div className="footerCopyright">
      © Copyright 2019-2023 SilentWolf
  </div>
  <div className="footerLogoDiv">
      <img src="./logo/logo_with_name.png" alt="SilentWolf footer logo" className="footerLogo" />
  </div>
</div>

export default Footer
