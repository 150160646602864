import React from 'react'
import './PlayerElement.css'

const PlayerElement = props => {

  //console.log("playerElement props: ", props)

  const statusRowClass = props.emailConfEnabled ? "dashboardListRowElem longElem" : "dashboardListRowElem shortElem"

  const openPlayerDataModal = () => {
    console.log("Opening player data modal for player: ", props.player.pn)
    props.setShowPlayerDataModal({show: true, playerWithData: props.player })
  }

  // translate milliseconds to UTC date string
  const millisToDateString = (millis) => {
    if(!millis || millis < 1) {
      return ""
    } else {
      const date = new Date(millis)
      const dateString = date.toUTCString()
      // get substring of dateString starting from 4th position
      const dateStringSubstring = dateString.substring(4)
      return dateStringSubstring
    }
  }

  const getSubstringWithEllipsis = (string, maxLength) => {
    let substring = string
    if(string && string.length > maxLength) {
      substring = string.substring(0, maxLength) + '...'
    }
    return substring
  }

  // get string representation of object
  const objToString = (obj) => {
    let str = ''
    for(let key in obj) {
        if(typeof obj[key] === 'string' || typeof obj[key] === 'number' || typeof obj[key] === 'boolean') {
            str += `${key}: ${obj[key]}, `
        } else {
            str += `${key}: ${objToString(obj[key])}, `
        }
    }
    return str.substring()
  }

  const getEnabledClass = (enabled) => {
    if(enabled) {
      return 'enabled'
    } else {
      return 'disabled'
    }
  }

  const getStatusClass = (status) => {
    if(status === 'CONFIRMED') {
      return 'confirmed'
    } else if(status === 'UNCONFIRMED') {
      return 'unconfirmed'
    } else if(status === 'RESET_REQUIRED') {
      return 'resetRequired'
    } else if(status === 'FORCE_CHANGE_PASSWORD') {
      return 'forceChangePassword'
    } else {
      return 'unknown'
    }
  }

  const getConfirmedStatus = (status) => {
    const separator = ` - `
    const confirmedStatus = props.emailConfEnabled ?
      <>
        {separator}<span className={getStatusClass(status)}>
          {status}
        </span>
      </> :
      null
    return confirmedStatus
  }

  const getStatusValue = (active, status) => {
    return <>
      <span className={getEnabledClass(active)}>
        { active ? 'Enabled' : 'Disabled' }
      </span>
      { getConfirmedStatus(status) }
    </>
  }

  const renderActionButtons = () => {
    return <>
      <span className="actionItem" onClick={() => props.setShowChangePlayerPasswordModal({ show: true, playerName: props.player.pn})}>
        <img src="/img/dashboard/changePassword.png" alt="Change player password" title="Change player password" className="dashboardMainIcon" />
      </span>
      { props.player.en ?
        <span className="actionItem" onClick={() => props.setShowDisablePlayerModal({ show: true, playerName: props.player.pn})}>
          <img src="/img/dashboard/disable.png" alt="Disable player" title="Disable player" className="dashboardMainIcon" />
        </span> :
          props.player.st !== undefined && props.player.st !== '' ?
          <span className="actionItem" onClick={() => props.setShowEnablePlayerModal({ show: true, playerName: props.player.pn})}>
            <img src="/img/dashboard/enable.png" alt="(Re-)enable player" title="(Re-)enable player" className="dashboardMainIcon" />
          </span>
          : null
      }
      <span className="actionItem" onClick={() => props.setShowDeletePlayerModal({ show: true, playerName: props.player.pn})}>
        <img src="/img/dashboard/delete.png" alt="Delete player" title="Delete player" className="dashboardMainIcon" />
      </span>
    </>
  }

  return (
    <div className="dashboardListRow" key={props.player.pn}>
        <div className="dashboardListRowElem mediumElem">
            <span className="subtitleLabel" title={props.player.pn}>
              {getSubstringWithEllipsis(props.player.pn, 22)}
            </span>
        </div>
        <div className="dashboardListRowElem mediumElem">
            <span className="subtitleLabel" title={props.player.em}>
            {props.player.em ? getSubstringWithEllipsis(props.player.em, 26) : null}
            </span>
        </div>
        <div className={statusRowClass}>
            { 'en' in props.player ? getStatusValue(props.player.en, props.player.st) : <span className="subtitleLabel"></span>}
        </div>
        <div className="dashboardListRowElem mediumElem">
            <span className="subtitleLabel">
              {props.player.co ? millisToDateString(props.player.co * 1000) : null}
            </span>
        </div>
        <div className="dashboardListRowElem shortElem">
            <a href="#" onClick={() => openPlayerDataModal()}>
            <span className="subtitleLabel" title={props.player.pd}>
                {props.player.pd ? getSubstringWithEllipsis(objToString(props.player.pd), 14) : ''}
            </span>
            </a>
        </div>
        <div className="dashboardListRowElem veryShortElem">
          {/* Check that player has created_on date before rendering */}
          { props.player.co ?
            renderActionButtons() :
            <span className="subtitleLabel"></span>
        }
        </div>
    </div>
  )
}

export default PlayerElement

{/* }
<i class="fa-duotone fa-user-gear"></i>*/}
