import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import LeaderboardElement from './LeaderboardElement'
import { useAppContext } from '../../../../libs/contextLib'
import './../../Dashboard.css'
import './../Main.css'

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = '2WQMRe392f2T9bOxVogxh8z9ROJ0qWmt2O1aKiNF' //'FmKF4gtm0Z2RbUAEU62kZ2OZoYLj4PNXa5YRIBb0'

const sendGetRequest = async (urlPath) => {
  const apiUrl = `${baseUrl}${urlPath}`
  const results = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey
    }
  })
  const jsonResponse = results.json()
  return jsonResponse
}

const sendPostRequest = async (urlPath, json, legacy) => {
  const apiUrl = `${baseUrl}${urlPath}`
  let pluginVersion = '0.9.0'
  if(legacy) {
    pluginVersion = '0.6.8'
  }
  const headers = {
    'x-api-key': apiKey,
    'x-sw-plugin-version': pluginVersion,
    'Content-Type': 'application/json'
  }
  const results = await fetch(apiUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(json)
  })
  return results.json()
}

const Scores = props => {

  const [scoresLoading, setScoresLoading] = useState(true)
  const { isAuthenticated, userSession } = useAppContext()
  const [leaderboards, setLeaderboards] = useState([])
  // scores is a dictionary where each key is a leaderboard name and each value
  // is a list of scores
  const [scores, setScores] = useState({})
  // we shouldn't need this, just load a first set of max 50 scores, and then
  // keep requesting more if the user scrolls down
  const maxScores = 50

  useEffect(() => {
    const getLeaderboards = async (gameId) => {
      const username = userSession.username
      if(username) {
        const urlPath = `get_leaderboard_config/${gameId}`
        const jsonResponse = await sendGetRequest(urlPath)
        setLeaderboards(jsonResponse.leaderboards)
      }
      setScoresLoading(false)
    }
    getLeaderboards(userSession.gameId);
    //loadScores();
    // in this component's code, we assume that we will have a latest_version
  }, [userSession.gameId]);

  /**async function loadScores(ldName = 'Monthly') {
    const gameId = userSession.gameId // get this from context 
    const maxScores = 50 // Manage this with state 

    const newScores = getScores(maxScores, ldName)
    if(newScores && newScores.length > 0) {
      let updatedScores = scores 
      updatedScores[ldName] = newScores
      setScores(updatedScores)
    }
  }*/

  const renderLoaderSpinner = () => {
    return (<div className="loaderSpinner">
        <i
        className="fa fa-refresh fa-spin fa-3x"
        style={{ marginRight: "0px" }}
        />
    </div>)
  }

  // this function can act for both addding new leadeboards and for updating
  // an existing one. However bnefore updating a leaderboard config, make sure that
  // the user understands the implication (esp for changes to insertOpt, timeBased
  // and timezone) and propose to wipe the leaderboard to avoid any inconsistencies
  const saveLeaderboard = async (name, displayName, insertOpt, timeBased, timezone) => {
    if(userSession.username) {
      const path = "set_leaderboard_config"
      const jsonLdboard = {
        'game_id': userSession.gameId,
        'ldboard_name': name,
        'ldboard_displayname': displayName,
        'insert_option': insertOpt,
        'time_based': timeBased,
        'timezone': timezone,
        'sharded': false
      }
      const jsonResults = await sendPostRequest(path, jsonLdboard)
      if(jsonResults.success) {
        setLeaderboards({
          ...leaderboards,
          jsonLdboard
        })
      }
    }
  }

  const wipeLeaderboard = async (gameVersion, ldName) => {
    let returnValue = false
    if(userSession.username) {
      const path = "wipe_leaderboard"
      const jsonWipe = {
        "game_id": userSession.gameId,
        "game_version": userSession.games[0].latest_version,
        "ldboard_name": ldName // NB: we could default ldName to main
      }
      const jsonResults = await sendPostRequest(path, jsonWipe)
      returnValue = jsonResults.success
    }
    return returnValue
  }

  // const deleteLeaderboard (create backend function!)

  /**
   * For simplicity's sake, we're leaving out the x-sw-plugin-version header,
   * and also the x-sw-act-tmst and x-sw-act-dig headers that guarantee integrity
   * So the console acts as a pre-0.6.9 game.
   * TODO: change this in the future!
   * TODO: also enable the user to submit metadata along with the score
  **/
  const addScore = async (playerName, ldName, scoreValue) => {
    let returnValue = false
    if(userSession.username) {
      const path = "post_new_score"
      const uuid = uuidv4();
      const now = Date.now()
      const jsonScore = {
      	  "score_id": uuid,
          "player_name": playerName,
          "ldboard_name": ldName,
          "game_id": userSession.gameId,
          "game_version": userSession.games[0].latest_version,
          "score": scoreValue
      }
      const jsonResults = await sendPostRequest(path, jsonScore)
      returnValue = jsonResults.success
    }
    return returnValue
  }

  const saveScore = async (playerName, ldName, scoreValue) => {
    let returnValue = false
    if(userSession.username) {
      const path = "save_score"
      const uuid = uuidv4();
      const now = Date.now()
      const jsonScore = {
      	  "score_id": uuid,
          "player_name": playerName,
          "ldboard_name": ldName,
          "game_id": userSession.gameId,
          "score": scoreValue
      }
      const jsonResults = await sendPostRequest(path, jsonScore)
      returnValue = jsonResults.success
    }
    return returnValue
  }

  // const modifyScore (TODO: implement backend function)

  // Delete one score
  const deleteScore = async (scoreId, ldName, legacy) => {
    let response = null
    const username = userSession.username
    let version = ""
    if(legacy) {
      version = userSession.games[0].latest_version
    }
    const ldBoardName = ldName ? ldName : "main"
    if(username) {
      const urlPath = `delete_scores`
      const scoresForDeletion = {
        'scores': [{
          'game_id': userSession.gameId,
          'game_version': version,
          'score_id': scoreId,
          'ldboard_name': ldBoardName
        }]
      }
      response = await sendPostRequest(urlPath, scoresForDeletion, legacy)
    }
    return response
  }

  // search scores by player name or score value (even timestamp?)
  // const findScore

  const renderLeaderboard = (ldboard) => {
    // check if scores object contains the leaderboard name as key
    // if it does get the scores from the scores object for that leaderboard
    //const ldScores = scores[ldboard.ld_name] ? scores[ldboard.ld_name] : []
    const latestVersion = userSession.games[0] ? userSession.games[0].latest_version : "0.1.0"
    return  <LeaderboardElement key={ldboard.ld_name}
                          ldName={ldboard.ld_name}
                          insertOpt={ldboard.insert_opt}
                          timeBased={ldboard.time_based}
                          timeZone={ldboard.timezone}
                          //scores={ldScores}
                          username={userSession.username}
                          gameId={userSession.gameId}
                          latestVersion={latestVersion}
                          deleteScore={deleteScore}/>
  }

  return (
    <div className="main">
      <div className="scores">
        <h2>Leaderboards:</h2>
        {
          scoresLoading ?
          renderLoaderSpinner() :
          leaderboards !== undefined ?
          leaderboards.map(renderLeaderboard) :
          <div className="comingSoon">
            <button>Create your first leaderboard</button>
          </div>
        }
      </div>
    </div>
  )
}

export default Scores