import React from 'react';

const PlayerAuthIntroText = () =>
  <div className="introTextContainer">
    <h1 className="leaderboardFree">Add player login to your game for free</h1>
    <div className="explainer">
      SilentWolf enables you to include player authentication to your game for free. Let your players register for an account with your game and then login. You can then attach their principal to <a href="https://silentwolf.com/leaderboard">scores they achieve in your game</a>, let them access private features of your game, etc. SilentWolf takes care of everything for you.
    </div>
    <div className="explainer">
      To add player authentication to your game, follow the instructions below.
    </div>
  </div>

export default PlayerAuthIntroText
