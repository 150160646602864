import React  from "react";
import { Link } from 'react-router-dom'
import { useAppContext } from '../libs/contextLib'
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import "./Home.css";

const Home = ({isAuthenticated, handleLogout, username, games, onSwitchGame}) => {

  const { userSession } = useAppContext()

  return (
  <div className="Home swpage">
    <div className="mainSection">
      <div className="features">
        <div className="feature">
          <img src={process.env.PUBLIC_URL + "/img/leaderboard_illustration.svg"} className="featureImage" />
          <h2>Set up your leaderboard</h2>
          <Link to="/leaderboard">
            <button className="linkButton centered">Get started</button>
          </Link>
        </div>
        <div className="feature">
          <img src={process.env.PUBLIC_URL + "/img/playeraccounts_illustration.svg"} className="featureImage" />
          <h2>Set up player accounts</h2>
          <Link to="/playerauth">
            <button className="linkButton centered">Get started</button>
          </Link>
        </div>
        <div className="feature">
          <img src={process.env.PUBLIC_URL + "/img/playerdata_illustration.svg"} className="featureImage" />
          <h2>Set up player data</h2>
          <Link to="/playerdata">
            <button className="linkButton centered">Get started</button>
          </Link>
        </div>
      </div>
      { userSession.gameId ?
        <>
          <div className="dashboardLink">
            <div className="explainer">
              ...or view and manage your game's data via <Link to="/dashboard/game">your SilentWolf dashboard</Link>.
            </div>
          </div>
          <div className="studioWork">
            <h2 className="studioWorkTitle">
              Studio work
            </h2>
            <div className="studioWorkContents">
              We also do custom development work for game studios on a contract basis. <Link to="/contact">Contact us</Link> to discuss your needs.
            </div>
            <div className="studioWorkContents">
              Here are some of the things we can do for you:
            </div>
            <div className="studioWorkContents">
              <ul>
                <li>Custom Godot game development (GDScript, C#) and prototyping</li>
                <li>Game backend development: networking, multiplayer, cloud infra setup on AWS, GCP or Azure</li>
                <li>Ad hoc native scripting (GDNative, GDExtension)</li>
                <li>Console ports of Godot games</li>
                <li>Training on game development, architecture</li>
              </ul>
            </div>
          </div>
        </> :
        null }
    </div>
  </div>
  )
}

export default Home
