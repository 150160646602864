import React from 'react';
import "./Terms.css";

const Privacy = () =>
  <div className="Privacy">
    <div className="basicContainer privacyContainer">
      <div className="privacy">
        <h1>SilentWolf Privacy Policy</h1>
        <div className="lander privacyMain">
          <h3>
            1. Who we are
          </h3>
          <div class="termsText">
            We are a team of developers working on SilentWolf, a suite of backend services for video games.
          </div>
          <div class="termsText">
            Our approach to personal data is to collect as little as possible, and to be good stewards of this data. This means that we go to great lengths to secure your data (and your players' data) and we do not share any of this data with third parties.
          </div>
          <h3>
            2. What data we collect
          </h3>
          <div class="termsText">
            Through our website (https://silentwolf.com) and our backend functions available via the SilentWolf plugin to Godot engine, we collect information related to your game so that we can help you operate and enhance it.
          </div>
          <div class="termsText">
            To use SilentWolf you need to sign up on our website using an email address. We need this email address as a way to identify you and to contact you to provide support and to help you operate your game.
          </div>
          <div class="termsText">
            When you use the SilentWolf system, the games you develop communicate with the SilentWolf backend via the SilentWolf plugin. This sends data into our servers, that we use to help you add features and manage your game. Most of this data relates to the game itself, or to its players. In most cases this is not personally identifiable data, but if you choose to use the SilentWolf player accounts features, you may choose to collect email addresses from your players, and you accept that this personal data will be stored on our servers.
          </div>
          <h3>
            3. What we do with personal data
          </h3>
          <div class="termsText">
            We mostly use your personal data, and the personal data collected from your players, on the SilentWolf website or in games using the SilentWolf plugin, as detailed in the documentation the SilentWolf website. We do not and will never sell or divulge your personal data, and your players' personal data to third parties. We may use some of your data in aggregate and in an anonymised manner to improve the SilentWolf services or produce statistics.
          </div>
          <div class="termsText">
            Concerning email addresses specifically, we use the email address that you submit when creating your account on the SilentWolf's website to contact you directly if we need to on an exceptional basis. Unless you sign up to our newsletter, we will not contact you on a regular basis to receive news or updates about the service. We will never send you spam of any kind.
          </div>
          <div class="termsText">
            As for your players' email addresses, we only contact them through SilentWolf's functions as called by the games you develop (for example to confirm their registration to your game). We will never use email addresses collected through SilentWolf to contact your players directly without your consent.
          </div>
          <h3>
            4. How we protect personal data
          </h3>
          <div class="termsText">
            When data is sent to our servers it is always through an encrypted channel (mostly https) where transport-layer encryption ensures that this data will not be intercepted and obtained by middlemen before it reachess our servers (or on the way back if they are requested). All of your data, including your email address and those of your players (if you chose to use specific SilentWolf features), are stored securely and encrypted at rest. We follow best practices and go responsible reasonable lengths to keep this data safe.
          </div>
          <div class="termsText">
            No method of transmission over the Internet, and no method of data storage is completely secure however, which is why we will strive to make ever reasonable attempt to protect your data from using the means at our disposal.
          </div>
          <h3>
            5. Your rights related to your data
          </h3>
          <div class="termsText">
            You have a right to access, change or delete all of the data that you have submitted yourself or that has been collected through your games into our servers. We strive to make all of this data accessible to you directly, either via the SilentWolf website or the SilentWolf plugin for Godot engine. If it is unclear to you how to access a certain kind of data, please <a href="/contact">Contact us</a> so we can provide assistance.
          </div>
          <h3>
            6. Cookies
          </h3>
          <div class="termsText">
            Cookies are small placed of your computer, smartphone or other device that are regularly usedd acorss the internet. We use cookies and similar technologies to:
          </div>
          <ul>
            <li>Collect information that will help us understand visitors' browsing behaviour.</li>
            <li>Compile statistical reports on website activity.</li>
            <li>Temporarily store data used by the SilentWolf website to improve the site's usability.</li>
            <li>In some cases, remember information between visits to our website.</li>
          </ul>
          <div class="termsText">
            The above mechanisms are used to continuously improve our website and our services.
          </div>
          <div class="termsText">
            Similarly, depending on which features of SilentWolf you wish to use, the SilentWolf plugin may store data on your player's devices for similar purposes.
          </div>
          <h3>
            7. Changes to our privacy policy
          </h3>
          <div class="termsText">
            We will keep our privacy policy under regular review. This version of our privacy policy was last updated on Monday, July 25th 2022.
          </div>
          <h3>
            8. How to contact us
          </h3>
          <div class="termsText">
            You can contact us about our privacy policy via our <a href="/contact">public contact form</a> or on our <a href="mailto:support@silentwolf.com">support email address</a>.
          </div>
        </div>
      </div>
    </div>
  </div>

export default Privacy
