import React, { useState, useEffect } from 'react'
import SidebarElement from './SidebarElement'
import { useLocation } from 'react-router-dom'
import './Sidebar.css'

const menuItems = [
    { text: 'Game', iconSrc: '/img/dashboard/game_white.png', iconSelectedSrc: '/img/dashboard/game.png', alt: 'Game', key: 0, status: '', url: '/dashboard/game' },
    { text: 'Players', iconSrc: '/img/dashboard/player_white.png', iconSelectedSrc: '/img/dashboard/player.png', alt: 'Players', key: 1, status: 'active', url: '/dashboard/players' },
    { text: 'Scores', iconSrc: '/img/dashboard/scores_white.png', iconSelectedSrc: '/img/dashboard/scores.png', alt: 'Scores', key: 2, status: '', url: '/dashboard/scores' } //,
    //{ text: 'Multiplayer', iconSrc: '/img/dashboard/scores_white.png', iconSelectedSrc: '/img/dashboard/scores.png', alt: 'Multiplayer', key: 3, status: '', url: '/dashboard/multiplayer' }
]

const sidebarElements = (activeUrl) => menuItems.map( (item, index) => {
  return (
    <SidebarElement text={item.text} iconSrc={item.iconSrc}
                    iconSelectedSrc={item.iconSelectedSrc}
                    alt={item.alt} key={item.key}
                    status={item.status} url={item.url} active={item.url === activeUrl} />
  )
})

const Sidebar = (props,  {defaultActive}) => {

  const location = useLocation();
  const [ activeUrl, setActiveUrl ] = useState(defaultActive)

  useEffect(() => {
    setActiveUrl(location.pathname)
  }, [location.pathname]);

  return (
    <div className="sidebar">
      {sidebarElements(activeUrl)}
    </div>
  )
}

export default Sidebar
