import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useAppContext } from '../../libs/contextLib'
import { useFormFields } from '../../libs/hooksLib'
import Button from '../Utils/Controls/Button'
import TextArea from '../Utils/TextArea'
import "./Contact.css";

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = 'FmKF4gtm0Z2RbUAEU62kZ2OZoYLj4PNXa5YRIBb0'

const sendPublicPostRequest = async (urlPath, json) => {
  const apiUrl = `${baseUrl}${urlPath}`
  const results = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      //'x-api-key': apiKey,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(json)
  })
  return results.json()
}

const Contact = props => {

  const { userSession } = useAppContext()

  const [contactForm, setContactForm] = useState({
                                                  message: undefined,
                                                  isLoading: false,
                                                  submitted: false,
                                                  error: undefined
                                                })

  const [fields, handleFieldChange] = useFormFields({
    email: undefined
  });

  const setMessage = (event) => {
    setContactForm({
      ...contactForm,
      message: event.target.value
    })
  }

  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validateMessage = (message) => {
    return message && message.length > 0;
  }

  const submitContactMessage = async event => {
    event.preventDefault();

    setContactForm({
      ...contactForm,
      isLoading: true
    })

    const email = fields.email ? fields.email : userSession.username

    console.log("contactForm.message: " + contactForm.message)

    if(!validateEmail(email)) {
      setContactForm({
        ...contactForm,
        isLoading: false,
        error: 'Please enter a valid email address'
      })
    } else if(!validateMessage(contactForm.message)) {
      setContactForm({
        ...contactForm,
        isLoading: false,
        error: 'Please enter a message'
      })
    } else {
      const json = {
        'email': email,
        'message': contactForm.message
      }
      const submitContactMessageUrl = "contact/submit_contact_message"
      const jsonResults = await sendPublicPostRequest(submitContactMessageUrl, json)

      if(jsonResults.hasOwnProperty('error')) {
          console.log("error in jsonResults: ")
          console.log(jsonResults.error)
          setContactForm({
            ...contactForm,
            error: jsonResults.error,
            isLoading: false
          })
      } else {
        setContactForm({
          ...contactForm,
          error: undefined,
          submitted: true,
          isLoading: false
        })
      }
    }
  }

  const unauthRender = () => {
    return (
      <div className="basicContainer">
        <h1 className="titleShort">Contact the SilentWolf team</h1>
        <div className="row formFirstRow contactFromTextInputField">
          <div className="inputFieldLabel">
            Your email address:
          </div>
          <input type="text" name="email" id="email"
                    value={fields.email}
                    onChange={handleFieldChange}
                    className="inputFieldTextInput" />
        </div>
        <div className="row contactFromTextInputField">
          <div className="inputFieldLabel">
            Your message:
          </div>
          <textarea className="contactMessage"
                    name="contactMessage" id="contactMessage"
                    defaultValue={contactForm.message}
                    onChange={setMessage}
                    cols="42"
                    rows="12" />
        </div>
        <Button handleClick={submitContactMessage} text="Submit" loading={contactForm.isLoading} />
        { contactForm.error ?
          <div className="explainer exceptions">
            {contactForm.error}
          </div>
        : null }
      </div>
    )
  }

  const authRender = () => {
    return(
      <div className="basicContainer">
        <h1 className="titleShort">Contact the SilentWolf team</h1>
        <div className="row formFirstRow">
          <TextArea className="contactMessage"
                    label="Your message:"
                    name="contactMessage" id="contactMessage"
                    defaultValue={contactForm.message}
                    onChange={setMessage} 
                    cols="42"
                    rows="12" />
        </div>
        <Button handleClick={submitContactMessage} text="Submit" loading={contactForm.isLoading} />
        { contactForm.error ?
          <div className="explainer exceptions">
            {contactForm.error}
          </div>
        : null }
      </div>
    )
  }

  const formSubmitted = () => {
    return (
      <div className="basicContainer confirmationContainer">
        <h1 className="titleShort">Thanks for contacting us</h1>
        <div className="explainer successful">
          Thank you for contacting the SilentWolf team. We will get back to you as soon as possible.
        </div>
        <div className="explainer">
          <Link to="/features">
            <button className="homeButton">Home</button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="ContactForm">
      { contactForm.submitted
        ? formSubmitted()
        : userSession.username ?
          authRender()
          : unauthRender() }
    </div>
  )
}

export default Contact
