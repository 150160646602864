import React,  { useState, useEffect } from 'react'
import Amplify, { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import ls from 'local-storage'
import { AppContext } from './libs/contextLib';
import { Routes, Route, Outlet } from 'react-router-dom';

/* Pages */
//import NotFound from './NotFound';
import Home from './components/Home';
import LoginSignup from './components/Auth/LoginSignup';
import ForgotPassword from './components/Auth/ForgotPassword';
import EmailSent from './components/Auth/EmailSent';
import Terms from './components/Admin/Terms';
import Privacy from './components/Admin/Privacy'
import Contact from './components/Contact/Contact'
import ChangePassword from './components/Auth/ChangePassword';
import Download from './components/Download/Download';
import Troubleshooting from './components/Troubleshooting/Troubleshooting';
import CallingSW from './components/Docs/CallingSW';
import CSharp from './components/Docs/CSharp';
import AndroidExport from './components/Docs/AndroidExport';
import Leaderboard from './components/Leaderboard/Leaderboard';
import PlayerAuth from './components/PlayerAuth/PlayerAuth';
import PlayerData from './components/PlayerData/PlayerData';
import CreateNewGame from './components/CreateNewGame/CreateNewGame';
import Header from './components/Header/Header.js';
import Footer from './components/Footer/Footer.js';
import NewsletterSubConfirmed from './components/Newsletter/NewsletterSubConfirmed'
//import Dashboard from './containers/Dashboard';
import Sidebar from './components/Dashboard/Sidebar/Sidebar';
import Game from './components/Dashboard/Main/Game/Game';
import Players from './components/Dashboard/Main/Players/Players';
import Scores from './components/Dashboard/Main/Scores/Scores';
import Multiplayer from './components/Dashboard/Main/Multiplayer/Multiplayer';
import DashboardFooter from './components/Footer/DashboardFooter';
import './Main.css'

function BasicLayout() {
  return (
    <div className="page">
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

function DashboardLayout() {
  return (
    <div className="page">
      <div className="dashboard">
        <div className="fixedHeader">
          <Header />
        </div>
        <div className="dashboardMain dashboardMainDiv">
          <Sidebar />
          <Outlet />
        </div>
        <DashboardFooter />
      </div>
    </div>
  )
}

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = 'FmKF4gtm0Z2RbUAEU62kZ2OZoYLj4PNXa5YRIBb0'

const sendGetRequest = async (urlPath) => {
  const apiUrl = `${baseUrl}${urlPath}`
  const results = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey
    }
  })
  return results.json()
}

const App = () => {

  Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:c745618a-cfd2-451f-8f98-07515311c37c',

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_7tmPu3c08',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '36o816576dl847f0lvq262cgsi',

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
  });

  // You can get the current config object
  //const currentConfig = Auth.configure();

  const navigate = useNavigate()
  //const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userSession, setUserSession] =  useState({
                                                    isAuthenticating: true,
                                                    gameId: undefined,
                                                    games: [],
                                                    username: undefined
                                                  })

  useEffect(() => {
    onLoad();
  }, [isAuthenticated]);

  const onLoad = async () => {

    if(isAuthenticated) {

      try {
        // see contents of user session: https://medium.com/@dantasfiles/three-methods-to-get-user-information-in-aws-amplify-authentication-e4e39e658c33
        const currentSession = await Auth.currentSession();

        const username = currentSession.idToken.payload.email
        const games = await getGames(username)
        let gameId = undefined
        if(games.length > 0) {
          gameId = games[0].id
        }
        console.log("User is authenticated, username: " + username + ", gameId: " + gameId)
        const newUserSession = {
            gameId: gameId,
            games: games,
            username: username
        }
        ls.set('userSession', newUserSession)
        setUserSession(newUserSession)
      }
      catch(e) {
        if (e !== 'No current user') {
          console.log("No user signed in:")
        }
      }
    } else {
      const sessionFromLocalStorage = ls.get('userSession')
      if(sessionFromLocalStorage) {
        setUserSession(sessionFromLocalStorage)
        userHasAuthenticated(true)
      }
    }

    //setIsAuthenticating(false);
  }

  async function getGames(username) {
    //console.log("getting games for user: " + username)
    const path = "get_games_for_user/" + username
    const jsonResults = await sendGetRequest(path)
    //console.log("getGamesForUser response:")
    //console.log(jsonResults)
    return jsonResults.games
  }

  async function setGameAsLatest(gameId, username) {
    //console.log("setting game " + gameId + " as latest for user: " + username)
    const path = "set_game_as_latest/" + username + ";" + gameId
    const jsonResults = await sendGetRequest(path)
    //console.log("getGamesForUser response:")
    //console.log(jsonResults)
    return jsonResults.games
  }

  async function reloadGames(gameId) {
    const username = userSession.username
    const games = await getGames(username)
    if(gameId) {
      const latestGameSet = await setGameAsLatest(gameId, username)
      // Get latest version of each game (check the version on the last score submitted)
      setUserSession({
        ...userSession,
        gameId: gameId,
        games: games,
      })
    } else {
      setUserSession({
        ...userSession,
        games: games,
      })
    }
  }

  async function handleLogout(event) {
    //console.log("handleLogout function")

    await Auth.signOut({ global: true })
      .catch(e => {
        console.log("Problem logging user out, attempting local logout")
        if (e.name === 'NotAuthorizedException') {
          Auth.signOut()
        }
      });

    ls.remove('userSession')

    // should the user session reset instead be in the onLoad function?
    setUserSession({
      gameId: undefined,
      games: [],
      username: undefined
    })
    userHasAuthenticated(false)
    navigate("/auth");
  }

  async function switchGame(gameId) { //async event => {
    //console.log("Switching game to gameId: " + gameId + ", username:  "  + userSession.username)
    const games = await setGameAsLatest(gameId, userSession.username)
    setUserSession({
      ...userSession,
      gameId: gameId,
      games: games
    })
  }

  return (
    <AppContext.Provider value={{isAuthenticated, userHasAuthenticated, userSession, handleLogout, switchGame, reloadGames}}>
      <Routes>
        <Route path="/" element={<BasicLayout />}>
          <Route index element={<Home />}/>
          <Route path="auth" element={<LoginSignup />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="emailSent" element={<EmailSent />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="contact" element={<Contact />} />
          <Route path="emailConfirmed" element={<NewsletterSubConfirmed />} />
          <Route path="features" element={<Home />} />
          <Route path="changepwd" element={<ChangePassword />} />
          <Route path="download" element={<Download />} />
          <Route path="troubleshooting" element={<Troubleshooting />} />
          <Route path="callingSilentWolf" element={<CallingSW />} />
          <Route path="cSharp" element={<CSharp />} />
          <Route path="mobileExport" element={<AndroidExport />} />
          <Route path="leaderboard" element={<Leaderboard />} />
          <Route path="playerauth" element={<PlayerAuth />} />
          <Route path="playerdata" element={<PlayerData />} />
          {/*<Route path="dashboard" element={<Dashboard />} />*/}
          <Route path="createNewGame" element={<CreateNewGame />}></Route>
          <Route path="*" element={<Home />}></Route>
        </Route>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Game />} />
          <Route path="/dashboard/game" element={<Game />} />
          <Route path="/dashboard/players" element={<Players />} />
          <Route path="/dashboard/scores" element={<Scores />} />
          <Route path="/dashboard/multiplayer" element={<Multiplayer />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );

}

export default App