import { useContext, createContext } from "react";

export const AppContext = createContext(null);
//export const DashboardContext = createContext(null);
//export const DashboardActionsContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}



/**
export function useDashboardContext() {
  return useContext(DashboardContext);
}

export function useDashboardActionsContext() {
  return useContext(DashboardActionsContext);
}
*/
