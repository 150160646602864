import React from 'react';

const EmailSent = () =>
  <div className="basicContainer confimrationContainer">
    <div className="terms ">
      <div className="centered">An email was sent to your inbox</div>
      <div className="lander">
        <p>If there is a SilentWolf account linked to the email address that you just entered, an email has been sent to it with instructions on how to reset your password.</p>
      </div>
    </div>
  </div>

export default EmailSent
