import React, {useState} from "react";
import { Link } from 'react-router-dom'
import GameSwitcher from "../Utils/GameSwitcher";
import { useAppContext } from "../../libs/contextLib";
import "./Header.css";

const cssSelector = (mobile, secondSelector) => {
    return mobile ? "mobileContentNavItem " + secondSelector : "navItem " + secondSelector;
} 

// Via state management, open Features menu showing Leaderboard, Player accounts, etc
const openFeaturesMenu = () => {
    return true
}

const HeaderSignedIn = props => {

    const { handleLogout } = useAppContext()

    const [showFeatureOverlay, setShowFeatureOverlay] = useState(false)

    const showFeaturesOverlay = () => {
        setShowFeatureOverlay(true)
    }

    const hideFeaturesOverlay = () => {
        setShowFeatureOverlay(false)
    }

    return (<>
    <li className={cssSelector(props.mobile, "createNewGameItem")}>
        <Link to="/createNewGame">
            + New Game
        </Link>
    </li>
    <li className="navItem featureItem">
        <Link to="/features">
            Features
        </Link>
    </li>
    {/*<li className="navItem featureItem">
        <Link to="/leaderboard">
            Leaderboards
        </Link>
    </li>
    <li className="navItem featureItem">
        <Link to="/playerauth">
            Player accounts
        </Link>
    </li>
    <li className="navItem featureItem">
        <Link to="/playerdata">
            Player Data
        </Link>
</li>*/}
    <li className="navItem contactItem">
        <Link to="/contact">
            Contact
        </Link>
    </li>
    <li className={cssSelector(props.mobile, "downloadItem")}>
        <Link to="/download">
            Download
        </Link>
    </li>
    <li className={cssSelector(props.mobile, "changePwdItem")}>
        <Link to="/changepwd">
            Change password
        </Link>
    </li>
    <li className={cssSelector(props.mobile, "gameSwitcher")}>
        <GameSwitcher {...props} />
    </li>
    <li className={cssSelector(props.mobile, "signInItem")}>
        <button className="btnPrimary" id="navGetStartedMobile" onClick={handleLogout}>
            Sign out
        </button>
    </li> 
  </>
    )
}

export default HeaderSignedIn