import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../libs/contextLib';
import { useFormFields } from '../../libs/hooksLib';
import { useNavigate } from 'react-router-dom';
import Button from '../Utils/Controls/Button'

const Signup = props => {

  const { userHasAuthenticated } = useAppContext()

  const [fields, handleFieldChange] = useFormFields({
    firstname: '',
    lastname: '',
    company: '',
    username: '',
    password: '',
    confirmPassword: '',
    acceptTOS: '',
    confirmationCode: ''
  });
  const navigate = useNavigate();
  const [formState, setFormState] = useState({ isLoading: false, error: undefined, newUser: sessionStorage.getItem('newUser') })
  const [confirmationFormState, setConfirmationFormState] = useState({ isConfirmationCodeLoading: false, error: undefined, signupComplete: false })
  const [acceptTOS, setAcceptTOS] = useState(false);

  const handleSignupSubmit = async event => {
    event.preventDefault();

    console.log('NewUser:')
    console.log(formState.newUser)

    setFormState({
      ...formState,
      isLoading: true
    })

    // TODO: check email format for username (using regex)
    // check that the user checked the TOS checkbox
    console.log("acceptTOS: " + acceptTOS)
    console.log("username: " + fields.username)
    if(fields.firstname.length < 1 || fields.lastname.length < 1 ) {
      setFormState({
        ...formState,
        error: "Please enter your first name and last name.",
        isLoading: false
      })
    } else if(fields.username.length < 5) {
      setFormState({
        ...formState,
        error: "Please enter a valid email address.",
        isLoading: false
      })
    } else if(fields.password.length < 6) {
      setFormState({
        ...formState,
        error: "Please enter a valid password.",
        isLoading: false
      })
    } else if(fields.password !== fields.confirmPassword) {
      setFormState({
        ...formState,
        error: "Passwords don't match.",
        isLoading: false
      })
    } else if(!acceptTOS) {
      console.log('acceptTOS:')
      console.log(acceptTOS)
      setFormState({
        ...formState,
        error: 'Please read and accept our Terms of service.',
        isLoading: false
      })
    } else {
      setFormState({
        ...formState,
        isLoading: true
      })
      const lowerCaseUsername = fields.username.toLowerCase()
      Auth.signUp({
          username: lowerCaseUsername,
          password: fields.password,
          attributes: {
            "custom:firstname": fields.firstname,
            "custom:lastname": fields.lastname,
            "custom:company": fields.company
          },
          validationData: []  //optional
        })
        .then(data => {
          console.log('data:')
          console.log(data)
          sessionStorage.setItem('newUser', lowerCaseUsername)
          setFormState({
            error: undefined,
            isLoading: false,
            newUser: lowerCaseUsername
          })
        })
        .catch(err => {
          console.log(err)
          console.log("Error: " + (err.name === "UsernameExistsException"))
          if(err.name === "UsernameExistsException") {
            // the use case we are dealiong with here is if the user signed up but then refreshed the confirmation page
            // if they sign up again we just want to send the confirmation code again to the same
            // email address
            setFormState({
              ...formState,
              error: 'There is already an account for this email address. Please log in or select Forgot password.',
              isLoading: false
            })
            Auth.resendSignUp(lowerCaseUsername)
          } else if(err.name === "InvalidPasswordException") {
            setFormState({
              ...formState,
              error: 'Invalid password. Please select a password containing a combination of at least 8 lowercase and uppercase letters, numbers and symbols.',
              isLoading: false
            })
          } else if(err.name === "InvalidParameterException") {
            //this.setState({error: err.message, isLoading: false})
            setFormState({
              ...formState,
              error: err.message,
              isLoading: false
            })
          } else if(err.message === "Network error") {
            setFormState({
              ...formState,
              error: "Couldn't reach the SilentWolf backend. Check your internet connection.",
              isLoading: false
            })
          } else {
            setFormState({
              ...formState,
              error: err.message,
              isLoading: false
            })
          }
        });
    }
  };

  const handleConfirmationSubmit = async event => {
    event.preventDefault();

    setConfirmationFormState({
      ...confirmationFormState,
      isConfirmationCodeLoading: true
    })

    const createUserUrl = 'https://api.silentwolf.com/create_new_user'

    try {
      const lowerCaseUsername = fields.username.toLowerCase()
      await Auth.confirmSignUp(lowerCaseUsername, fields.confirmationCode);
      await Auth.signIn(lowerCaseUsername, fields.password)
                .then(user => {
                  sessionStorage.setItem('newUser', null);
                  sessionStorage.setItem('username', '');
                  sessionStorage.setItem('password', '');
                  setConfirmationFormState({
                    ...confirmationFormState,
                    signupComplete: true,
                    isConfirmationCodeLoading: false
                  })
                })
      // we also want to add the user to the SW db
      fetch(createUserUrl, {
        method: 'post',
        body: JSON.stringify({ "username": lowerCaseUsername, "firstName": fields.firstname, "lastName": fields.lastname, "company": fields.company })
      }).then( results => {
        console.log(results.json());
      })

      userHasAuthenticated(true);
      // in final version, use should be redirected to dashboard after login
      navigate('/features')
    } catch (err) {
        //this.setState({ error: "Invalid confirmation code", isConfirmationCodeLoading: false })
        console.log("error during confirmation: " + err)
        setConfirmationFormState({
          ...confirmationFormState,
          error: 'Invalid confirmation code',
          isConfirmationCodeLoading: false
        })
    }
  }

  const handleAcceptTOSChange = event => {
    setAcceptTOS(!acceptTOS)
  }

  const renderForm = () => {

    return (
      <div className="signup">
        <div className="signupForm">
          <div>
            <h3 className="miniTitle centered">
              New user? Create your SilentWolf account.
            </h3>
            <div className="row">
              <label htmlFor="firstname" className="label">
                First name:
              </label>
             <input type="text"
                     id="firstname"
                     className="field"
                     value={fields.firstname}
                     onChange={handleFieldChange}
                     required />
            </div>
            <div className="row">
              <label htmlFor="lastname" className="label">
                Last name:
              </label>
              <input type="text"
                     id="lastname"
                     className="field"
                     value={fields.lastname}
                     onChange={handleFieldChange}
                     required />
            </div>
            <div className="row">
              <label htmlFor="company" className="label">
                Company (optional):
              </label>
             <input type="text"
                    id="company"
                    className="field"
                    value={fields.company}
                    onChange={handleFieldChange} />
            </div>
            <div className="row">
              <label htmlFor="username" className="label">
                Email address:
              </label>
             <input type="text"
                    id="username"
                    className="field"
                    placeholder="email@example.com"
                    value={fields.username}
                    onChange={handleFieldChange}
                    required />
            </div>
            <div className="row">
              <label htmlFor="password" className="label">
                Password:
              </label>
               <input type="password"
                      id="password"
                      className="field"
                      value={fields.password}
                      onChange={handleFieldChange}
                      required />
            </div>
            <div className="row">
              <label htmlFor="confirmPassword" className="label">
                Confirm password:
              </label>
               <input type="password"
                      id="confirmPassword"
                      className="field"
                      value={fields.confirmPassword}
                      onChange={handleFieldChange} />
            </div>
            <div className="checkboxRow">
              <input type="checkbox" className="checkboxField" name="acceptTOS" id="acceptTOS" onChange={handleAcceptTOSChange} />
              <label htmlFor="acceptTOS" className="checkboxLabel">
                I have read and accept the <a href="/terms" target="_blank">Terms of service</a>.
              </label>
            </div>
            <div className="exceptions">
              {formState.error}
            </div>
            <div className="centered">
              <Button handleClick={handleSignupSubmit} text="Submit" loading={formState.isLoading} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderConfirmationForm = () => {
    return (
      <div className="signup quickConfirmationContainer">
        <div className="signupConfirmationForm">
          <div>
            <h3 className="miniTitle centered miniTitleLessPadded">
              Please check your inbox.
            </h3>
            <div className="explainer centered explainer-padded">
              We've sent you an email containing a confirmation code. Please enter it below.
            </div>
            <div className="row marginned">
              <label className="label" htmlFor="confirmationCode">Confirmation Code</label>
              <input className="field" type="hidden" id="confCodeAutocomplete" />
              <input type="tel"
                     id="confirmationCode"
                     className="field"
                     value={fields.confirmationCode}
                     onChange={handleFieldChange}
                     autoComplete="off" />
            </div>
            <div className="exceptions centered">
              {confirmationFormState.error}
            </div>
            <div className="centered">
              <Button handleClick={handleConfirmationSubmit} text="Submit" loading={confirmationFormState.isConfirmationCodeLoading} />
            </div>
            <div className="explainer centered explainer-padded">
              Can't validate your email address? Open the page in another browser tab or <a href="/contact">contact us</a>.
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderSignupComplete = () => {
    return (
      <div className="signup confirmationContainer">
        <div className="signupConfirmationForm">
          <h3 className="miniTitle miniTitleLessPadded">
            Sign up complete
          </h3>
          <div className="explainer explainer-padded">
            Congrats, your SilentWolf account is now active!
          </div>
          <div className="explainer explainer-padded">
            Feel free to <a href="/features">get started</a> to start enhancing your game or <a href="/">go back home</a>.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="Signup">
      {confirmationFormState.signupComplete
        ? renderSignupComplete()
        : (formState.newUser === null
          ? renderForm()
          : renderConfirmationForm())}
    </div>
  );
}

export default Signup
