import React from 'react';
import { Link } from 'react-router-dom';

const CallingSW = props => {

  return (
    <div className="CallingSW">
      <div className="basicContainer">
        <h1>Exporting to Android</h1>
        <div className="explainer basicExplainer">
          In order to connect to the backend the SilentWolf plugin needs to have internet access.
        </div>
        <div className="explainer basicExplainer">
          When exporting your game for mobile devices, make sure to request the appopriate permissions so that your game is allowed to access the internet. For Android all you need to do is select the "Internet" permission in your export options as shown below:
        </div>
        <div className="screenshotMedium">
          <img src={process.env.PUBLIC_URL + "/Android_export_permissions.png"} alt="Add Internet permission before Android export" height="531" width="722" />
        </div>
        <h2 className="titleShort">Troubleshooting</h2>
        <div className="explainer">
          If the instructions above don't solve your problem, check out our <a href="/troubleshooting">troubleshooting guide</a> to help you fix typical problems you might be facing.
        </div>
        <h2 className="titleShort">Get in touch</h2>
        <div className="explainer">
          You can always <Link to="/contact">reach out to us</Link> and we can help you debug your mobile game issues.
        </div>
      </div>
    </div>
  )
}

export default CallingSW
