import React from 'react';

const Checkbox = props => {

  return(
    <div className="checkBox">
      <label className="checkboxContainer">
        <input
          type="checkbox"
          name={props.label}
          checked={props.isSelected}
          onChange={props.onChange}
          className="swCheckbox"
        />
        <span className="checkmark"></span>
        {props.label}
      </label>
    </div>
  );
}

export default Checkbox;
