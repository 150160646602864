import React from "react";

import './PlayerDataModal.css'

// render json data in a div
const renderJson = (playerData) => {
    return (
        <div className="playerData">
            <pre className="modalPre">{JSON.stringify(playerData, null, 2)}</pre>
        </div>
    )
}

const renderPlayerData = (playerData) => {
    console.log("playerData: ")
    console.log(playerData)
    return (
        playerData ?
        renderJson(playerData) :
        <div>
            No player data for this player
        </div>
    )
}

const PlayerDataModal = props => {
    if(!props.show){
        return null;
    }
    return (
        <div className="modal playerDataModal">
            <div className="modalContent">
                <p className="modalMessage">Player data for this player</p>
                {renderPlayerData(props.playerWithData.pd)}
                <div className="modalButtons playerDataModalButton">
                    <button onClick={props.onClose}>
                    Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PlayerDataModal;
