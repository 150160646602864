import React from 'react';
import "./Button.css";

const Button = (props) => {

    const buttonStyle = () => {
        return `submitButton ${props.buttonStyle}`
    }

    return (
        <div className="buttonContainer">
            <button className={buttonStyle()} onClick={props.handleClick} type="submit" disabled={props.loading}>
                <span className={props.textStyle}>
                    {props.loading && (
                        <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                        />
                    )}
                    {props.text}
                </span>
            </button>
        </div>
    )
}

export default Button;
