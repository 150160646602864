import { useState } from "react";

export function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
      fields,
      function(event) {
        const targetValue = typeof event.target.value === "string"
                        ? event.target.value //.trim()
                        : event.target.value
        setValues({
          ...fields,
          [event.target.id]: targetValue
        });
      }
    ];
  }
