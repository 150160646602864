import React from 'react';
import { Link } from 'react-router-dom'
import { useAppContext } from '../../libs/contextLib'
import LeaderboardConfig from './LeaderboardConfig'
import ShowApiKey from '../Utils/ShowApiKey'
import CodeBlock from '../Utils/Controls/CodeBlock'
import "./Leaderboard.css";

// Snippets for the code blocks for Godot 3.x and Godot 4.x
const configSnippets = {
  g3: `
  SilentWolf.configure({
    "api_key": "YOUR_SILENTWOLF_API_KEY",
    "game_id": "YOUR_SILENTWOLF_GAME_ID",
    "game_version": "1.0.2",
    "log_level": 1
  })

  SilentWolf.configure_scores({
    "open_scene_on_close": "res://scenes/MainPage.tscn"
  })`, 
  g4: `
  SilentWolf.configure({
    "api_key": "YOUR_SILENTWOLF_API_KEY",
    "game_id": "YOUR_SILENTWOLF_GAME_ID",
    "log_level": 1
  })

  SilentWolf.configure_scores({
    "open_scene_on_close": "res://scenes/MainPage.tscn"
  })`
};

const saveScoreSnippets = {
  g3: `
    SilentWolf.Scores.persist_score(player_name, score)`,
  g4: `
    SilentWolf.Scores.save_score(player_name, score)`
};

const saveScoreAsyncSnippets = {
  g3: `
  var score_id = yield(SilentWolf.Scores.persist_score(player_name, score), "sw_score_posted")
  print("Score persisted successfully: " + str(score_id))`,
  g4: `
  var sw_result: Dictionary = await SilentWolf.Scores.save_score(player_name, score).sw_save_score_complete
  print("Score persisted successfully: " + str(sw_result.score_id))`
};

const getScoresSnippets = {
  g3: `
  yield(SilentWolf.Scores.get_high_scores(), "sw_scores_received")
  print("Scores: " + str(SilentWolf.Scores.scores))`,
  g4: `
  var sw_result: Dictionary = await SilentWolf.Scores.get_scores().sw_get_scores_complete
  print("Scores: " + str(sw_result.scores))`
};

const get200ScoresSnippets = {
  g3: `
    yield(SilentWolf.Scores.get_high_scores(200), "sw_scores_received")
    print("Scores: " + str(SilentWolf.Scores.scores)) `,
  g4: `
    var sw_result: Dictionary = await SilentWolf.Scores.get_scores(200).sw_get_scores_complete
    print("Scores: " + str(sw_result.scores))`
};

const getAllScoresSnippets = {
  g3: `
    yield(SilentWolf.Scores.get_high_scores(0), "sw_scores_received")
    print("All scores: " + str(SilentWolf.Scores.scores)) 
    `,
  g4: `
    var sw_result: Dictionary = await SilentWolf.Scores.get_scores(0).sw_get_scores_complete
    print("All scores: " + str(sw_result.scores))`
};

const loopScoresSnippets = {
  g3: `
  for score in scores:
      add_item(score.player_name, str(int(score.score)))`,
  g4: `
  for score in scores:
      add_item(score.player_name, str(int(score.score)))`
};

const getScoresByPlayerSnippets = {
    g3: `
    var player_scores = yield(SilentWolf.Scores.get_scores_by_player(player_name), "sw_player_scores_received")
    print("Got player scores: " + str(player_scores))
    print("Got: " + str(player_scores.size()) + " scores for player: " + str(player_name))
    print("Does player have scores? " + str(player_scores.size() > 0))`,
    g4: `
    var sw_result = await SilentWolf.Scores.get_scores_by_player(player_name).sw_get_player_scores_complete
    print("Got player scores: " + str(sw_result.scores))
    print("Got: " + str(sw_result.scores.size()) + " scores for player: " + str(player_name))
    print("Does player have scores? " + str(sw_result.scores.size() > 0))`
  };

const getScoresByPlayerCustomSnippets = {
    g3: `
    var player_scores = yield(SilentWolf.Scores.get_scores_by_player(player_name, 10, "CustLdBoardName"), "sw_player_scores_received")`,
    g4: `
    var sw_result = await SilentWolf.Scores.get_scores_by_player(player_name, 10, "CustLdBoardName").sw_get_player_scores_complete`
  };

const getTopScoreByPlayerSnippets = {
    g3: `
    var top_player_score = yield(SilentWolf.Scores.get_top_score_by_player(player_name), "sw_top_player_score_received")
    print("Got top player score: " + str(top_player_score))
    print("Does player have a top score? " + str(!top_player_score.empty()))`,
    g4: `
    var sw_result = await SilentWolf.Scores.get_top_score_by_player(player_name).sw_top_player_score_complete 
    print("Got top player score: " + str(sw_result.score))
    print("Does player have a top score? " + str(!sw_result.score.empty()))`
  };

const getTopScoreByPlayerCustomSnippets = {
    g3: `
    var top_player_score = yield(SilentWolf.Scores.get_top_score_by_player(player_name, 10, "CustLdBoardName"), "sw_top_player_score_received")`,
    g4: `
    var sw_result = await SilentWolf.Scores.get_top_score_by_player(player_name, 10, "CustLdBoardName").sw_get_top_player_score_complete`
  };

const getScorePositionSnippets = {
    g3: `
    SilentWolf.Scores.get_score_position(score)`,
    g4: `
    SilentWolf.Scores.get_score_position(score)`
  };

const getScorePositionAsyncSnippets = {
    g3: `
    yield(SilentWolf.Scores.get_score_position(score), "sw_position_received")
    var position = SilentWolf.Scores.position`,
    g4: `
    var sw_result = await SilentWolf.Scores.get_score_position(score).sw_get_position_complete
    var position = sw_result.position`
  };

const getScorePositionCustomSnippets = {
    g3: `
    yield(SilentWolf.Scores.get_score_position(score, ldboard_name), "sw_position_received")
    var position = SilentWolf.Scores.position
    `,
    g4: `
    var sw_result = await SilentWolf.Scores.get_score_position(score, ldboard_name).sw_get_position_complete
    var position = sw_result.position`
  };


const getScoresAroundSnippets = {
    g3: `
    yield(SilentWolf.Scores.get_scores_around(score, 2), "sw_scores_around_received")
    print("scores_above: " + str(SilentWolf.Scores.scores_above))
    print("scores_below: " + str(SilentWolf.Scores.scores_below))
    print("position: " + str(SilentWolf.Scores.position))
    `,
    g4: `   
    var sw_result = await SilentWolf.Scores.get_scores_around(score, 2).sw_get_scores_around_complete
    print("scores_above: " + str(sw_result.scores_above))
    print("scores_below: " + str(sw_result.scores_below))
    print("position: " + str(sw_result.position))
    `
  };

const getScoresAroundResultSnippets = {
    g3: `{
    "game_id_version": "Yourgameid;1.0.0",
    "score": 56,
    "player_name": "Some player name",
    "ld_name": "YourLeaderboard",
    "score_id": "0811ee6c-f2ab-4b86-903b-6db1ba054d71",
    "timestamp": 1586510245528,
    "position": 6
  }`,
    g4: `{
    "game_id": "Yourgameid",
    "score": 56,
    "player_name": "Some player name",
    "ld_name": "YourLeaderboard",
    "score_id": "0811ee6c-f2ab-4b86-903b-6db1ba054d71",
    "timestamp": 1586510245528,
    "position": 6
  }`
};

const saveScoreCustomSnippets = {
  g3: `
  SilentWolf.Scores.persist_score(player_name, score, ldboard_name)`,
  g4: `
  SilentWolf.Scores.save_score(player_name, score, ldboard_name)`
};

const getScoresCustomSnippets = {
  g3: `
  yield(SilentWolf.Scores.get_high_scores(0, "DailyBoard"), "sw_scores_received")
  print("Scores from the DailyBoard leaderboard: " + str(SilentWolf.Scores.leaderboards["DailyBoard"]))`,
  g4: `
  var sw_result = await SilentWolf.Scores.get_scores(0, "DailyBoard").sw_get_scores_complete
  var scores = sw_result.scores`
};

const getScoresPeriodOffsetSnippets = {
  g3: `
  yield(SilentWolf.Scores.get_high_scores(0, "DailyBoard", -4), "sw_scores_received")
  print("Scores from the DailyBoard leaderboard from 4 days ago: " + str(SilentWolf.Scores.leaderboards_past_periods["DailyBoard;-4"]))`,
  g4: `
  var sw_result = await SilentWolf.Scores.get_scores(0, "DailyBoard", -4).sw_get_scores_complete
  var scores = sw_result.scores`
};

const saveScoreMetadataSnippets = {
  g3: `
  var player_name = "Someplayer"
  var score = 451
  var ldboard_name = "main"
  var metadata = {
    "email": "support@silentwolf.com",
    "elapsed_time_ms": 231457,
    "won_boss_fight": true
  }
  SilentWolf.Scores.persist_score(player_name, score, ldboard_name, metadata)`,
  g4: `
  var player_name = "Someplayer"
  var score = 451
  var ldboard_name = "main"
  var metadata = {
    "email": "support@silentwolf.com",
    "elapsed_time_ms": 231457,
    "won_boss_fight": true
  }
  SilentWolf.Scores.save_score(player_name, score, ldboard_name, metadata)`
};

const getScoresMetadataSnippets = {
  g3: `
  yield(SilentWolf.Scores.get_high_scores(), "sw_scores_received")
  for score in SilentWolf.Scores.scores:
    print("Player name: " + str(player_name) + ", score: " + str(score.score) + ", metadata: " + str(score.metadata))`,
  g4: `
  var sw_result = await SilentWolf.Scores.get_scores().sw_get_scores_complete
  var scores = sw_result.scores
  for score in scores:
    print("Player name: " + str(player_name) + ", score: " + str(score.score) + ", metadata: " + str(score.metadata))`
};

const parseMetadataSnippets = {
  g3: `
  print("Player email: " + str(score.metadata.email))`,
  g4: `
  print("Player email: " + str(score.metadata.email))`
};

const wipeLeaderboardSnippets = {
  g3: `
  SilentWolf.Scores.wipe_leaderboard()`,
  g4: `
  SilentWolf.Scores.wipe_leaderboard()`
};

const wipeLeaderboardCustomSnippets = {
  g3: `
  SilentWolf.Scores.wipe_leaderboard(ldboard_name)`,
  g4: `
  SilentWolf.Scores.wipe_leaderboard(ldboard_name)`
};




const NextSteps = (props) => {

  const { userSession } = useAppContext()

  return (
    <div className="basicContainer nextStepsContainer">
      <h2 className="titleShort">Next steps</h2>
      <ol className="explainer">
        <li><a href="https://s3.amazonaws.com/silentwolf-downloads/silent_wolf.zip" download>Download the SilentWolf addon for Godot Engine</a>. </li>
        <li>Decompress the zip file you just downloaded (using 7zip or your system's native archive utility) and copy its contents to the <i>addons</i> folder of your Godot project.</li>
        <li>Start your Godot editor</li>
        <li>Add the SilentWolf script to your Autoload settings in Godot Editor. To do this go to 'Project Settings' under the 'Project' menu and open the 'Autoload' tab. Then add a new autoloaded script by entering the following:
          <ul>
            <li><strong>Path:</strong> res://addons/silent_wolf/SilentWolf.gd</li>
            <li><strong>Node name:</strong> SilentWolf</li>
          </ul>
          <div className="onlyLargeScreen">
            Then press 'Add'. Make sure to call the exact spelling above.
          </div>
          <div className="screenshotMedium">
            <img src={process.env.PUBLIC_URL + "/SilentWolf_screenshot_Godot_1.png"} alt="Add SilentWolf addon as an autoloaded singleton in Godot Editor" height="531" width="722" />
          </div>
          <div className="onlyLargeScreen">
            Once you are done your Project Settings tab should look like this:
          </div>
          <div className="screenshotMedium">
            <img src={process.env.PUBLIC_URL + "/SilentWolf_screenshot_Godot_2.png"} alt="Godot Project Settings - autoloaded scripts" height="179" width="721" />
          </div>
        </li>
        <li>Before you can use the SilentWolf APIs, you need to configure the addon with your SilentWolf API key and Game Id. You can also specify the amount of logging you want to get in the console from SilentWolf (0 for errors only, 1 for info-level logging and 2 for debug logging). Finally, if you're using Godot 3.x you can also optionally add the version of the game that you are working on (using <a target="_blank" href="https://semver.org/">semantic versioning</a>, e.g. 1.0.1).
          <CodeBlock codeSnippets={configSnippets} />
          <ShowApiKey />
          <div className="explainer">
             You can do so by calling the SilentWolf.configure function. Make sure that this code is executed at the very beginning of the game. A good place would be the _ready() function of an autoloaded singleton (like your global script) or the script associated with your splash page. Also, make sure to specify your game_id in your configuration (as generated by SilentWolf), not your game name.
          </div>
          <div className="explainer">
             In the example code above, we've included a call to the SilentWolf.configure_scores function. This is useful if you use the built-in Leaderboard scene (see below) and want to let SilentWolf know where to send the player after they close the leaderboard.
          </div>
        </li>
      </ol>
      <h2 className="titleShort">Quick note on mobile exports</h2>
      <div className="explainer">
        Before going into the details of saving and retrieving scores in your game, please note that SilentWolf will need internet access in your target platform. This is by default the case when exporting to desktop and HTML5, but if you're planning on exporting to mobile <a href="/mobileExport">you'll need to follow these instructions</a>.
      </div>
      <h2 className="titleShort">Saving scores</h2>
      <div className="explainer">
        Now that you have the SilentWolf addon set up you can start saving your scores to the SilentWolf backend. The following API call will save a score for a given player in your main leaderboard:
      </div>
      <CodeBlock codeSnippets={saveScoreSnippets} />
      <div className="explainer">
        Behind the scenes this function will securely send the score data to the SilentWolf backend where it can be queried in the future. A score_id will be generated to represent this new score that you can later use to refer to the score.
      </div>
      <div className="explainer">
        Note that both parameters are required to call this function. You have to submit a value for player name (null or empty values will not be accepted) and a numeric value for score.
      </div>
      <div className="explainer">
        If the score is saved successfully, a signal (called "sw_score_posted" in Godot 3, "sw_save_score_complete" in Godot 4) is emitted, and the new score_id is passed along:
      </div>
       <CodeBlock codeSnippets={saveScoreAsyncSnippets} />
      <h2 className="titleShort">Listing high scores</h2>
      <div className="explainer">
        You can then retrieve an ordered array of top scores by simply calling:
      </div>
       <CodeBlock codeSnippets={getScoresSnippets} /> 
      <div className="explainer">
        This function will perform another round trip to the backend to retrieve your top scores. You will know that the request has completed when the "sw_scores_received" ("sw_get_scores_complete" in Godot 4) signal has been emitted. After you call this function, <a href="/callingSilentWolf">the associated variable SilentWolf.Scores.scores will contain an array with your top scores</a>.
      </div>
      <div className="explainer">
        By default it will fetch the top 10 scores for your game (and game version), but you can request more by passing an optional parameter:
      </div>
       <CodeBlock codeSnippets={get200ScoresSnippets} />
      <div className="explainer">
        If you want to retrieve all the scores for your game, pass 0 as the function's parameter:
      </div>
       <CodeBlock codeSnippets={getAllScoresSnippets} />
      <div className="explainer">
        You don't need to call this function every time you want to access your scores. Only call it when you want to refresh your leaderboard with the most recent data.
      </div>
      <div className="explainer">
        Each item of the scores array contains a player_name and a score. You can loop through the array easily:
      </div>
       <CodeBlock codeSnippets={loopScoresSnippets} />
      <div className="explainer">
        In addition to the score and the player_name, the function also retrieves a unique score_id and a timestamp representing when the score was saved.
      </div>
      <div className="explainer">
        By default SilentWolf ranks scores first by the score value itself (higher is better), then by timestamp (earlier is better) and finally by player_name (in alphabetical order).
      </div>
      <div className="explainer">
        The instructions above work well when you have a single leaderboard. Your code needs to be slightly different when you have multiple leaderboards in your game, as explained in the <a href="/leaderboard#customLeaderboards">section on custom leaderboards</a>.
      </div>
      <h2 className="titleShort" id="scoresForPlayer">Getting scores for a specific player</h2>
      <div className="explainer">
        You can just as easily retrieve the scores of only a given player by calling:
      </div>
      <CodeBlock codeSnippets={getScoresByPlayerSnippets} />
      <div className="explainer">
        The specific player's scores can also be found in SilentWolf.Scores.player_scores. By default this will fetch the player's scores in the main leaderboard, but you can add the leaderboard name to the function attributes to get the player's scores in a specific <a href="/leaderboard#customLeaderboards">custom leaderboard</a>. You can also add a 'maximum' parameter to look for scores that are in the top of the leaderboard (this limits the search and improves the performance of the request). By default the maximum value is 10. For example:
      </div>
      <CodeBlock codeSnippets={getScoresByPlayerCustomSnippets} />
      <div className="explainer">
        The above function call will fetch the player's scores within the top 10 scores of a leaderboard called 'CustLdBoardName'.
      </div>
      <h2 className="titleShort" id="topScoreForPlayer">Getting the top score for a specific player</h2>
      <div className="explainer">
        Sometimes you just want to get a player's best score within a leaderboard. You can do that by calling:
      </div>
      <CodeBlock codeSnippets={getTopScoreByPlayerSnippets} />
      <div className="explainer">
        The specific player's top score can also be found in SilentWolf.Scores.top_player_score (also available in sw_result.score in Godot 4). By default this will fetch the player's top score in the main leaderboard, but here again you can add the leaderboard name to the function attributes to get the player's scores in a specific <a href="/leaderboard#customLeaderboards">custom leaderboard</a>. You can also add a 'maximum' parameter to look only among scores that are in the top of the leaderboard (thereby improving the function's response time). By default the maximum value is 10. For example:
      </div>
      <div className="explainer">
        If there are no scores yet for the player in that leaderboard (or none within the maximum range), the function will return an empty dictionary.
      </div>
      <CodeBlock codeSnippets={getTopScoreByPlayerCustomSnippets} />
      <div className="explainer">
        The above function call will fetch the player's best score within the top 10 scores of a leaderboard called 'CustLdBoardName'.
      </div>
      <h2 className="titleShort" id="scorePositions">Finding positions on the Leaderboard</h2>
      <div className="explainer">
        In some cases it can be useful to find (and display) the position of a player's score on the leaderboard, especially if that position isn't near the very top. If you have 1000 scores saved for your game and the latest player's efforts rank him at position #327, this is information that they won't see by looking at a leaderboard featuring only the top 20 scores.
      </div>
      <div className="explainer">
        SilentWolf's SilentWolf.Scores.get_score_position() function takes a 'score' attribute:
      </div>
      <CodeBlock codeSnippets={getScorePositionSnippets} />
      <div className="explainer">
        There are two ways that you can use this function, by passing a different value for the 'score' parameter:
        <ul>
          <li>A <b>score_value</b>, meaning just any numeric value, e.g.: SilentWolf.Scores.get_score_position(116) </li>
          <li>A <b>score_id</b> associated to an existing score, e.g. SilentWolf.Scores.get_score_position("01e0e975-cedc-4fc0-be09-43893d0defca") </li>
        </ul>
      </div>
      <div className="explainer">
        You might want to use a score_value or a score_id depending on your use case. When you call the get_score_position(score) function passing in a numeric value for score, SilentWolf will check what position that hypothetical score would have in a leaderboard if such a score was actually posted. Since scores are ordered by score value then by time, if you already have that same score value in your leaderboard a new score with the same score value would be listed one position below (e.g. number 2 instead of number 1 if it's the top numeric score value). One advantage of using score_value is that you're guaranteed to always get a position in return.
      </div>
      <div className="explainer">
        On the other hand, a score_id is a unique identifier for an existing score that you got by calling either SilentWolf.Scores.persist_score() or SilentWolf.Scores.get_high_scores(). If you call get_score_position(score) by passing a score_id you will get the position in the leaderboard of that exact score. However there are some cases when this will not work.Say for example that you're using a <a href="/leaderboard#customLeaderboards">custom leaderboard that only saves the highest score for any given player</a>. If you then submit a score for a player that is lower that his best personal score, it will not be saved to the leaderboard and hence get_score_position(score) will not return a valid position.
      </div>
      <div className="explainer">
        Therefore you should only use score_ids when getting score positions if you're savingall your scores to a leaderboard. Ifthat's not thecase but you'd like to get the same result, i.e. the exact position that that score would have had, you can call the get_score_position(score) function by passing the numeric value of the score <u>before</u> persisting the score.
      </div>
      <div className="explainer">
        To retrieve the score's position after calling SilentWolf.Scores.get_score_position(score) you can look into <a href="/callingSilentWolf">the SilentWolf.Scores.position associated variable for the value of the position once the corresponding signal has been emitted</a>:
      </div>
      <CodeBlock codeSnippets={getScorePositionAsyncSnippets} />
      <div className="explainer">
        Scores are ordered by score value then by time (earlier is better). In the rare event that two scores with the same score value are posted at exactly the same time, the scores are listed in alphabetical order of the players' names.
      </div>
      <div className="explainer">
        By default the get_score_position() function retrieves the position for a score within your main leaderboard. If you want to get a score position from another leaderboard (<a href="/leaderboard#customLeaderboards">see section on custom leaderboards</a>), just add the leaderboard name to the function call:
      </div>
      <CodeBlock codeSnippets={getScorePositionCustomSnippets} />
      <h2 className="titleShort" id="getScoresAround">Get scores above and below a given score</h2>
      <div className="explainer">
       When you display your leaderboard, most of the time you will only show a small number of top scores. Most players will not make the top scores but they might still want an indication of how well they did.
      </div>
      <div className="explainer">
       You can call the following SilentWolf.Scores.get_scores_around(score) function to get scores immediately above and below a given score:
      </div>
      <CodeBlock codeSnippets={getScoresAroundSnippets} />
      <div className="explainer">
       In a similar way to the <a href="/leaderboard#scorePositions">get_score_position function</a>, you can pass either a score_value (numeric value) or a score_id (36-character string) to the get_scores_around() function to get scores above and below a hypothetical score or an actual score in a leaderboard.
      </div>
      <div className="explainer">
       The function accepts an optional second argument that lets you select how many scores above and below the current score that you want to retrieve (by default this value is 3). You can also add a third argument to get a score from a custom leaderboard: SilentWolf.Scores.get_scores_around(score, 4, "MyCustomLeaderboard")
      </div>
      <div className="explainer">
       After the function completes you can get the results in SilentWolf.Scores.scores_above and SilentWolf.Scores.scores_below. Each of the scores in these two arrrays include their respective positions in the leaderboard, e.g.:
      </div>
      <CodeBlock codeSnippets={getScoresAroundResultSnippets} />
      <div className="explainer">
        The position of the score you just submitted will also be available (SilentWolf.Scores.position) so you don't need to call get_score_position separately if you're calling get_scores_around.
      </div>
      <h2 className="titleShort" id="customLeaderboards">Custom leaderboards</h2>
      <div className="explainer">
       By default there is only one leaderboard (your "main" leaderboard) associated with your game. But you can add other leaderboards and customize the way they work. All leaderboards can be customized, including the main leaderboard.
      </div>
      <div className="explainer">
       There are several ways in which a leaderboard can be customized. You can decide to keep all the scores that were submitted, or only the highest score for each player, or only the latest for each player. These are called <b>single-score</b> leaderboards.
       </div>
       <div className="explainer">
       You can also accumulate the value of the scores of a given player over time (e.g. if they score a 3 and then a 5, their total score becomes 8). This type of leaderboard is called an <b>accumulator</b>.
      </div>
      <div className="explainer">
       You can also make your leaderboard reset every so often on a schedule (every hour/day/week/month/year). Choose the reference timezone to decide when exactly each new period starts. These are called <b>time-based</b> leaderboards.
      </div>
      <div className="explainer">
        You can combine these customizations to your liking, for example by creating a leaderboard that keeps only the players' latest score from the past week (time-based single-score leaderboard), or that accumulates a player's score over an hour (time-based accumulator).
      </div>
      <div className="explainer">
       You can add leaderboards and customize all of your leaderboards below:
      </div>
      <LeaderboardConfig name="LeaderboardConfig" gameId={userSession.gameId} />
      <div className="explainer">
       To save a score to a certain leaderboard, all you need to do is add the leaderboard's name in your call to the persist_score() function:
      </div>
      <CodeBlock codeSnippets={saveScoreCustomSnippets} />
      <div className="explainer">
       If you don't specify a leaderboard name, by default the score will be saved to your main leaderboard.
      </div>
      <div className="explainer">
       Be careful if you update the configuration of a leaderboard that already has associated scores. Changing the configuration will alter the way that scores are treated and could even lead to scores being deleted. For example, if you change a leaderboard from keeping "All" scores to only the "Highest" for each player, the player's lower scores will be removed when he submits a new personal best score (but not before then). To make sure that your leaderboard behaves exactly the way you intend it to, it's preferable to either <a href="/leaderboard#wipeLeaderboard">wipe your leaderboard</a> before you change its configuration or create a new leaderboard.
      </div>
      <div className="explainer">
        To retrieve scores from a custom leaderboard, you just need to specify the leaderboard name when calling the get_high_scores() function:
      </div>
      <CodeBlock codeSnippets={getScoresCustomSnippets} />
      <div className="explainer">
        The scores from your leaderboard will then be available in the SilentWolf.Scores.leaderboards[ld_name]. If you don't specify a leaderboard name in the request, it will get the scores from your main leaderboard. In that case you can find them in SilentWolf.Scores.leaderboards["main"].
      </div>
      <div className="explainer">
       If you configured one of your leaderboards to be time-based (so that it resets at regular intervals), by default the get_high_scores() function (get_scores() in Godot 4) will retrieve the scores related to the current period (hour/day/week/month/year depending on what you configured). Scores from previous periods aren't deleted though. You can retrieve them by specifying the 'period offset' in the request. This is a integer value that is 0 for the current period, -1 for the preceding period (e.g. last week for a weekly leader board), -2, -3, and so forth. Here's an example for retrieving scores from 4 periods ago:
      </div>
      <CodeBlock codeSnippets={getScoresPeriodOffsetSnippets} />
      <div className="explainer">
       If you don't specify a period offset it will default to 0 (current period). Note that the scores from previous periods are saved in a different variable (SilentWolf.Scores.leaderboards_past_periods) which is ordered by a string composed of the leaderboard name, a semi-column (';') and the period offset, e.g. "DailyBoard;-4". In the example below, after calling the get_high_scores() function the scores can be found in SilentWolf.Scores.leaderboards_past_periods["DailyBoard;-4"].
      </div>
      <h2 className="titleShort" id="wipeLeaderboard">Score metadata</h2>
      <div className="explainer">
        Sometimes you want to submit additional data along with a score. This could be the additional player information like an email address or something related to the game session that the player completed, like elapsed time or whether or not certain success criteria were met.
      </div>
      <div className="explainer">
        You can store this data when you save the score. To do this you need to add your score matadata to a <a href="https://docs.godotengine.org/en/stable/classes/class_dictionary.html">dictionary</a> and add this dictionary to the arguments in your call to the SilentWolf.Scores.persist_score function.
      </div>
      <CodeBlock codeSnippets={saveScoreMetadataSnippets} />
      <div className="explainer">
        IMPORTANT NOTE: when calling SilentWolf.Scores.persist_score passing score metadata you MUST indicate which leaderboard you are submitting the score to. If you haven't created any custom leaderboard just pass the "main" leaderboard.
      </div>
      <div className="explainer">
        Inside the metadata dictionary you can use any of the basic GDScript type like Strings, Ints, Booleans. You can even submit a nested Dictionary.
      </div>
      <div className="explainer">
        When you call SilentWolf.Scores.get_high_scores you will get the metadata in return along with the scores:
      </div>
      <CodeBlock codeSnippets={getScoresMetadataSnippets} />
      <div className="explainer">
        When you get the metadata back from the backend, it will again be in the form of a Dictionary in GDScript. You can access the metadata values using the same keys you used when you submitted the score. For example, if you submitted the score metadata as shown above, you can access the email address like this:
      </div>
      <CodeBlock codeSnippets={parseMetadataSnippets} />
      <h2 className="titleShort" id="wipeLeaderboard">Wiping your Leaderboard</h2>
      <div className="explainer">
        You may want to delete all the scores in your leaderboard. You can do so by using the SilentWolf.Scores.wipe_leaderboard() function as described below, but be careful! This function deletes all of the scores for a given game and version permanently; there is no way of getting them back.
      </div>
      <CodeBlock codeSnippets={wipeLeaderboardSnippets} />
      <div className="explainer">
        By default this wipes your main leaderboard. To wipe a custom leaderboard, just specify the leaderboard name in the request:
      </div>
      <CodeBlock codeSnippets={wipeLeaderboardCustomSnippets} />
      <div className="explainer">
        Only use this function if you really want to delete all your game's scores and you're sure that you'll never need them again. If you just want to reset your leaderboard, it's probably better to use a <a href="/leaderboard#customLeaderboards">custom time-based leaderboard</a>.
      </div>
      <h2 className="titleShort">SilentWolf dashboard (new!)</h2>
      <div className="explainer">
        You can view your game's scores and leaderboards in <a href="https://silentwolf.com/dashboard/scores">your SilentWolf dashboard</a>. You can also delete scores from the dashboard manually.
      </div>
      <div className="explainer">
        NB: if your scores don't appear on the dashboard you may need to log out and log in again.
      </div>
      <div className="explainer">
        We'll be adding many new features to the dashboard in the near future. Stay tuned!
      </div>
      <h2 className="titleShort">Built-in Leaderboard scene</h2>
      <div className="explainer">
        You can create your own Leaderboard scene in Godot based on the scores array or you can use the one that comes built into SilentWolf (you can find it in Scores/Leaderboard.tscn with its associated script Scores/Leaderboard.gd).
      </div>
      <div className="explainer">
        You can also customize the SilentWolf leaderboard to match your game's visual style. It's best to create a new scene that extends from Scores/Leaderboard.tscn and modify that one so that addon upgrades don't override your customizations.
      </div>
      <div className="explainer">
        There is also an example of a more complicated leaderboard scene displaying three custom single-score, time-based leaderboards in a single scene in the addon's examples folder (you can find it in examples/CustomLeaderboards/TimeBasedLboards.tscn with its associated script).
      </div>
      <h2 className="titleShort">Troubleshooting</h2>
      <div className="explainer">
        Check out our <a href="/troubleshooting">troubleshooting guide</a> to help you fix typical problems you might be facing.
      </div>
      <h2 className="titleShort">Get in touch</h2>
      <div className="explainer">
        <Link to="/contact">Contact us</Link> and we can help you build a leaderboard (or another feature requiring backend servers) for your game.
      </div>
    </div>
  )
}

export default NextSteps
