import React from 'react';
import { Link } from 'react-router-dom';
import "./Download.css";

const Download = props => {

  return (
    <div className="swpage">
      <div className="basicContainer downloadContainer">
        <h2>Download the latest version of the SilentWolf plugin.</h2>
        <div className="downloadExplainer">
          <div className="downloadSections">
            <div classNane="downloadColumn">
              <h3>
                For Godot 3.x
              </h3>
              <div className="explainer itemList">
                The latest version  (0.6.20) of the SilentWolf addon for Godot 3.x includes:
                <ul>
                  <li>scores and leaderboards</li>
                  <li>player registration and player authentication</li>
                  <li>player and character data</li>
                </ul>
              </div>
              <div className="explainer downloadNoteExplainer">
                This minor version contains a the new function SilentWolf.Scores.get_top_score_by_player function, and a minor bug fix to the SilentWolf.Auth.get_player_details function.
              </div>
              <div className="buttonContainer downloadButtonExplainer">
                <a className="linkButton" href="https://s3.amazonaws.com/silentwolf-downloads/g3/silent_wolf.zip" download>
                  Download plugin
                </a>
              </div>
            </div>
            <div classNane="donwloadColumn">
              <h3>
                For Godot 4
              </h3>
              <div className="explainer itemList">
                This first version (0.9.7) of the SilentWolf addon for Godot 4 includes:
                <ul>
                  <li>scores and leaderboard features</li>
                  <li>player registration and player authentication features</li>
                  <li>player and character data</li>
                </ul>
              </div>
              <div className="explainer downloadNoteExplainer">
                This minor version of the plugin for Godot 4 fixes an issue with score field names in some functions. 
              </div>
              <div className="buttonContainer downloadButtonExplainer">
                <a className="linkButton" href="https://s3.amazonaws.com/silentwolf-downloads/g4/silent_wolf.zip" download>
                  Download plugin
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="downloadExplainer">
          <h2>Installation</h2>
          <div className="explainer itemList downloadExplainer">
            To install the SilentWolf plugin for Godot Engine, simply unzip the file you downloaded above into the addons folder in your game. In Godot Editor, make sure the res://addons/silent_wolf/SilentWolf.gd script is autoloaded in your game. You're ready to configure and use the plugin in your game.
          </div>
          <div className="buttonContainer downloadButtonExplainer">
            <a className="linkButton secondaryButton" href="/features">
              Start using the plugin
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Download
