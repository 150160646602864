import React from 'react';

const TextArea = ({ label, name, id, defaultValue, onChange, rows=5, cols=50 }) => (
  <div className="textArea">
    <label className="textAreaLabel" htmlFor={id}>
      {label}
    </label>
    <textarea
      name={name}
      id={id}
      onChange={onChange}
      className="textAreaTextInput"
      value={defaultValue}
      rows={rows}
      cols={cols}
    />
  </div>
);

export default TextArea;