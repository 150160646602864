import React, { useState, useEffect } from 'react';
import TextInputField from '../Utils/TextInputField'
import ReadOnlyTextField from '../Utils/ReadOnlyTextField'
import ControlledDropdown from '../Utils/ControlledDropdown'
import Button from '../Utils/Controls/Button'
import "./Leaderboard.css";

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = 'FmKF4gtm0Z2RbUAEU62kZ2OZoYLj4PNXa5YRIBb0'

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const LeaderboardConfigForm = (props) => {

  //const { userSession } = useAppContext()

  // don't use props to set initial values in state (so instead we just have an initValues
  // value derived from the props that we will replace with values in state when/if the user
  // changes them)
  const initValues = {
    name: props.name,
    displayName: props.displayName,
    insertOpt: props.insertOpt ? props.insertOpt : "keep",
    timeBased: props.timeBased ? props.timeBased : "-",
    timezone: props.timezone ? props.timezone : browserTimezone,
    maxEntries: props.maxEntries,
    isNew: props.isNew
  }

  const [formState, setFormState] = useState({
    name: undefined,
    displayName: undefined,
    insertOpt: undefined,
    timeBased: undefined,
    timezone: undefined,
    maxEntries: undefined,
    isNew: undefined,
    isLoading: false,
    leaderBoardConfigFormSubmittedError: false,
    leaderBoardConfigFormSubmittedErrorMessage: ""
  })

  // the values from the state, or the initial values if nothing in state
  const currentValues = {
    name: formState.name ? formState.name : initValues.name,
    displayName: formState.displayName ? formState.displayName : initValues.displayName,
    insertOpt: formState.insertOpt ? formState.insertOpt : initValues.insertOpt,
    timeBased: formState.timeBased ? formState.timeBased : initValues.timeBased,
    timezone: formState.timezone ? formState.timezone : initValues.timezone,
    maxEntries: formState.maxEntries ? formState.maxEntries : initValues.maxEntries,
    isNew:formState.isNew ? formState.isNew : initValues.isNew
  }

  /**useEffect(() => {
    resetForm();
  }, [userSession.gameId]);*/

  const resetForm = () => {
    console.log('LeaderboardConfigForm resetForm, displayName: ')
    console.log(props.displayName)
    console.log('LeaderboardConfigForm resetForm, gameId: ')
    console.log(props.gameId)
    setFormState({
      name: props.name,
      displayName: props.displayName,
      isLoading: false,
      insertOpt: props.insertOpt ? props.insertOpt : "keep",
      timeBased: props.timeBased ? props.timeBased : "-",
      timezone: props.timezone ? props.timezone : browserTimezone,
      maxEntries: props.maxEntries ? props.maxEntries : 10,
      isNew: props.isNew,
      leaderBoardConfigFormSubmittedError: false,
      leaderBoardConfigFormSubmittedErrorMessage: ""
    })
  }

  // in the following functions we can't use event.target.id
  // indeed, since we need uinque html ids for each field
  // across all leaderboards they can't match the state variable names
  // e.g. 'timeBased-1' !== 'timeBased'
  const handleLdNameChange = event => {
    setFormState({
      ...formState,
      name: event.target.value
    })
  }

  const handleLdDisplayNameChange = event => {
    setFormState({
      ...formState,
      displayName: event.target.value
    })
  }

  const handleInsertOptChange = event => {
    setFormState({
      ...formState,
      insertOpt: event.target.value
    })
  }

  const handleTimeBasedChange = event => {
    //console.log("TimeBased changed! Reredering should occur")
    setFormState({
      ...formState,
      timeBased: event.target.value
    })
  }

  const handleTimezoneChange = event => {
    setFormState({
      ...formState,
      timezone: event.target.value
    })
  }

  const handleMaxEntriesChange = event => {
    setFormState({
      ...formState, 
      maxEntries: event.target.value
    })
  }

  const sendPostRequest = async (urlPath, json) => {
    const apiUrl = `${baseUrl}${urlPath}`
    const results = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(json)
    })
    return results.json()
  }

  const submitLeaderboardConfigForm = async () =>  {
    setFormState({
      ...formState,
      isLoading: true
    })
    const path = "set_leaderboard_config"
    const json = {
      'game_id': props.gameId,
      'ldboard_name': currentValues.name,
      'ldboard_displayname': currentValues.displayName,
      'insert_option': currentValues.insertOpt,
      'time_based': currentValues.timeBased,
      'timezone': currentValues.timezone,
      'max_entries': currentValues.maxEntries
    }
    const jsonResults = sendPostRequest(path, json)
    //console.log(jsonResults)
    if(jsonResults.hasOwnProperty('error')) {
      //console.log("error in jsonResults: ")
      //console.log(jsonResults.error)
      setFormState({
        ...formState,
        leaderBoardConfigFormSubmittedError: true,
        leaderBoardConfigFormSubmittedErrorMessage: jsonResults.error,
        isLoading: false
      })
    } else {
      setFormState({
        ...formState,
        leaderBoardConfigFormSubmittedError: false,
        leaderBoardConfigFormSubmitted: true,
        isNew: false,
        isLoading: false
      })
    }
  }

  const timezoneToTimezoneOption = (timezone) => {
    return {
      "name": timezone,
      "value": timezone
    }
  }
  //console.log("LeaderboardConfigForm render, emailBody: " + this.state.emailBody)
  const insertOptOptions = [{ name:"All", value:"keep" }, { name:"Highest", value:"highest"}, { name:"Latest", value:"latest"}, { name:"Accumulator", value:"accumulator"}]
  const timeBasedOptions = [{ name:"-", value:"na" }, { name:"Hourly", value:"hourly" }, { name:"Daily", value:"daily" }, { name:"Weekly", value:"weekly" }, { name:"Monthly", value:"monthly" }, { name:"Yearly", value:"yearly" }]
  const timezones = [
      'Africa/Abidjan',
      'Africa/Accra',
      'Africa/Algiers',
      'Africa/Bissau',
      'Africa/Cairo',
      'Africa/Casablanca',
      'Africa/Ceuta',
      'Africa/El_Aaiun',
      'Africa/Johannesburg',
      'Africa/Juba',
      'Africa/Khartoum',
      'Africa/Lagos',
      'Africa/Maputo',
      'Africa/Monrovia',
      'Africa/Nairobi',
      'Africa/Ndjamena',
      'Africa/Tripoli',
      'Africa/Tunis',
      'Africa/Windhoek',
      'America/Adak',
      'America/Anchorage',
      'America/Araguaina',
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Catamarca',
      'America/Argentina/Cordoba',
      'America/Argentina/Jujuy',
      'America/Argentina/La_Rioja',
      'America/Argentina/Mendoza',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
      'America/Asuncion',
      'America/Atikokan',
      'America/Bahia',
      'America/Bahia_Banderas',
      'America/Barbados',
      'America/Belem',
      'America/Belize',
      'America/Blanc-Sablon',
      'America/Boa_Vista',
      'America/Bogota',
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Campo_Grande',
      'America/Cancun',
      'America/Caracas',
      'America/Cayenne',
      'America/Chicago',
      'America/Chihuahua',
      'America/Costa_Rica',
      'America/Creston',
      'America/Cuiaba',
      'America/Curacao',
      'America/Danmarkshavn',
      'America/Dawson',
      'America/Dawson_Creek',
      'America/Denver',
      'America/Detroit',
      'America/Edmonton',
      'America/Eirunepe',
      'America/El_Salvador',
      'America/Fort_Nelson',
      'America/Fortaleza',
      'America/Glace_Bay',
      'America/Godthab',
      'America/Goose_Bay',
      'America/Grand_Turk',
      'America/Guatemala',
      'America/Guayaquil',
      'America/Guyana',
      'America/Halifax',
      'America/Havana',
      'America/Hermosillo',
      'America/Indiana/Indianapolis',
      'America/Indiana/Knox',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Tell_City',
      'America/Indiana/Vevay',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Inuvik',
      'America/Iqaluit',
      'America/Jamaica',
      'America/Juneau',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/La_Paz',
      'America/Lima',
      'America/Los_Angeles',
      'America/Maceio',
      'America/Managua',
      'America/Manaus',
      'America/Martinique',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Menominee',
      'America/Merida',
      'America/Metlakatla',
      'America/Mexico_City',
      'America/Miquelon',
      'America/Moncton',
      'America/Monterrey',
      'America/Montevideo',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Nome',
      'America/Noronha',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Ojinaga',
      'America/Panama',
      'America/Pangnirtung',
      'America/Paramaribo',
      'America/Phoenix',
      'America/Port_of_Spain',
      'America/Port-au-Prince',
      'America/Porto_Velho',
      'America/Puerto_Rico',
      'America/Punta_Arenas',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Recife',
      'America/Regina',
      'America/Resolute',
      'America/Rio_Branco',
      'America/Santarem',
      'America/Santiago',
      'America/Santo_Domingo',
      'America/Sao_Paulo',
      'America/Scoresbysund',
      'America/Sitka',
      'America/St_Johns',
      'America/Swift_Current',
      'America/Tegucigalpa',
      'America/Thule',
      'America/Thunder_Bay',
      'America/Tijuana',
      'America/Toronto',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Winnipeg',
      'America/Yakutat',
      'America/Yellowknife',
      'Antarctica/Casey',
      'Antarctica/Davis',
      'Antarctica/DumontDUrville',
      'Antarctica/Macquarie',
      'Antarctica/Mawson',
      'Antarctica/Palmer',
      'Antarctica/Rothera',
      'Antarctica/Syowa',
      'Antarctica/Troll',
      'Antarctica/Vostok',
      'Asia/Almaty',
      'Asia/Amman',
      'Asia/Anadyr',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Ashgabat',
      'Asia/Atyrau',
      'Asia/Baghdad',
      'Asia/Baku',
      'Asia/Bangkok',
      'Asia/Barnaul',
      'Asia/Beirut',
      'Asia/Bishkek',
      'Asia/Brunei',
      'Asia/Chita',
      'Asia/Choibalsan',
      'Asia/Colombo',
      'Asia/Damascus',
      'Asia/Dhaka',
      'Asia/Dili',
      'Asia/Dubai',
      'Asia/Dushanbe',
      'Asia/Famagusta',
      'Asia/Gaza',
      'Asia/Hebron',
      'Asia/Ho_Chi_Minh',
      'Asia/Hong_Kong',
      'Asia/Hovd',
      'Asia/Irkutsk',
      'Asia/Jakarta',
      'Asia/Jayapura',
      'Asia/Jerusalem',
      'Asia/Kabul',
      'Asia/Kamchatka',
      'Asia/Karachi',
      'Asia/Kathmandu',
      'Asia/Khandyga',
      'Asia/Kolkata',
      'Asia/Krasnoyarsk',
      'Asia/Kuala_Lumpur',
      'Asia/Kuching',
      'Asia/Macau',
      'Asia/Magadan',
      'Asia/Makassar',
      'Asia/Manila',
      'Asia/Novokuznetsk',
      'Asia/Novosibirsk',
      'Asia/Omsk',
      'Asia/Oral',
      'Asia/Pontianak',
      'Asia/Pyongyang',
      'Asia/Qatar',
      'Asia/Qyzylorda',
      'Asia/Riyadh',
      'Asia/Sakhalin',
      'Asia/Samarkand',
      'Asia/Seoul',
      'Asia/Shanghai',
      'Asia/Singapore',
      'Asia/Srednekolymsk',
      'Asia/Taipei',
      'Asia/Tashkent',
      'Asia/Tbilisi',
      'Asia/Tehran',
      'Asia/Thimphu',
      'Asia/Tokyo',
      'Asia/Tomsk',
      'Asia/Ulaanbaatar',
      'Asia/Urumqi',
      'Asia/Ust-Nera',
      'Asia/Vladivostok',
      'Asia/Yakutsk',
      'Asia/Yangon',
      'Asia/Yekaterinburg',
      'Asia/Yerevan',
      'Atlantic/Azores',
      'Atlantic/Bermuda',
      'Atlantic/Canary',
      'Atlantic/Cape_Verde',
      'Atlantic/Faroe',
      'Atlantic/Madeira',
      'Atlantic/Reykjavik',
      'Atlantic/South_Georgia',
      'Atlantic/Stanley',
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Broken_Hill',
      'Australia/Currie',
      'Australia/Darwin',
      'Australia/Eucla',
      'Australia/Hobart',
      'Australia/Lindeman',
      'Australia/Lord_Howe',
      'Australia/Melbourne',
      'Australia/Perth',
      'Australia/Sydney',
      'Etc/GMT',
      'Etc/GMT+1',
      'Etc/GMT+10',
      'Etc/GMT+11',
      'Etc/GMT+12',
      'Etc/GMT+2',
      'Etc/GMT+3',
      'Etc/GMT+4',
      'Etc/GMT+5',
      'Etc/GMT+6',
      'Etc/GMT+7',
      'Etc/GMT+8',
      'Etc/GMT+9',
      'Etc/GMT-1',
      'Etc/GMT-10',
      'Etc/GMT-11',
      'Etc/GMT-12',
      'Etc/GMT-13',
      'Etc/GMT-14',
      'Etc/GMT-2',
      'Etc/GMT-3',
      'Etc/GMT-4',
      'Etc/GMT-5',
      'Etc/GMT-6',
      'Etc/GMT-7',
      'Etc/GMT-8',
      'Etc/GMT-9',
      'Etc/UTC',
      'Europe/Amsterdam',
      'Europe/Andorra',
      'Europe/Astrakhan',
      'Europe/Athens',
      'Europe/Belgrade',
      'Europe/Berlin',
      'Europe/Brussels',
      'Europe/Bucharest',
      'Europe/Budapest',
      'Europe/Chisinau',
      'Europe/Copenhagen',
      'Europe/Dublin',
      'Europe/Gibraltar',
      'Europe/Helsinki',
      'Europe/Istanbul',
      'Europe/Kaliningrad',
      'Europe/Kiev',
      'Europe/Kirov',
      'Europe/Lisbon',
      'Europe/London',
      'Europe/Luxembourg',
      'Europe/Madrid',
      'Europe/Malta',
      'Europe/Minsk',
      'Europe/Monaco',
      'Europe/Moscow',
      'Europe/Nicosia',
      'Europe/Oslo',
      'Europe/Paris',
      'Europe/Prague',
      'Europe/Riga',
      'Europe/Rome',
      'Europe/Samara',
      'Europe/Saratov',
      'Europe/Simferopol',
      'Europe/Sofia',
      'Europe/Stockholm',
      'Europe/Tallinn',
      'Europe/Tirane',
      'Europe/Ulyanovsk',
      'Europe/Uzhgorod',
      'Europe/Vienna',
      'Europe/Vilnius',
      'Europe/Volgograd',
      'Europe/Warsaw',
      'Europe/Zaporozhye',
      'Europe/Zurich',
      'Indian/Chagos',
      'Indian/Christmas',
      'Indian/Cocos',
      'Indian/Kerguelen',
      'Indian/Mahe',
      'Indian/Maldives',
      'Indian/Mauritius',
      'Indian/Reunion',
      'Pacific/Apia',
      'Pacific/Auckland',
      'Pacific/Bougainville',
      'Pacific/Chatham',
      'Pacific/Chuuk',
      'Pacific/Easter',
      'Pacific/Efate',
      'Pacific/Enderbury',
      'Pacific/Fakaofo',
      'Pacific/Fiji',
      'Pacific/Funafuti',
      'Pacific/Galapagos',
      'Pacific/Gambier',
      'Pacific/Guadalcanal',
      'Pacific/Guam',
      'Pacific/Honolulu',
      'Pacific/Kiritimati',
      'Pacific/Kosrae',
      'Pacific/Kwajalein',
      'Pacific/Majuro',
      'Pacific/Marquesas',
      'Pacific/Nauru',
      'Pacific/Niue',
      'Pacific/Norfolk',
      'Pacific/Noumea',
      'Pacific/Pago_Pago',
      'Pacific/Palau',
      'Pacific/Pitcairn',
      'Pacific/Pohnpei',
      'Pacific/Port_Moresby',
      'Pacific/Rarotonga',
      'Pacific/Tahiti',
      'Pacific/Tarawa',
      'Pacific/Tongatapu',
      'Pacific/Wake',
      'Pacific/Wallis',
  ]
  const maxEntriesOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "30", value: 30 }, { name: "40", value: 40 }, { name: "50", value: 50 }, { name: "100", value: 100 }, { name: "200", value: 200 }, { name: "500", value: 500 }, { name: "1000", value: 1000 }]
  const timeZoneOptions = timezones.map(timezoneToTimezoneOption)

  const getSubmitConfigFormText = () => {
    return currentValues.isNew ? 'Submit' : 'Update'
  }

  const getDisplayName = (currentDisplayName) => {
    return currentDisplayName ? currentDisplayName : ''
  }

  console.log('current values:')
  console.log(currentValues)

  const leaderboardConfigFormCss = (currentValues) => {
    return currentValues.isNew || currentValues.name === "" ? 'leaderBoardConfigForm newLdBoard' : 'leaderBoardConfigForm'
  }

  return (
    <div className={leaderboardConfigFormCss(currentValues)}>
      {/* Leaderboard name can only be set once. Afterwards it becomes read only */}
      { currentValues.isNew || currentValues.name === "" ?
        <TextInputField className="longTextInput" label={"Leaderboard name:"} name={"name"} id={`name-${props.index}`} defaultValue={currentValues.name} onChange={handleLdNameChange} />
      : <ReadOnlyTextField label={"Leaderboard name:"} name={"name"} id={`name-${props.index}`} defaultValue={currentValues.name} />
      }
      <TextInputField className="longTextInput" label={"Display name:"} name={"displayName"} id={`displayName-${props.index}`} defaultValue={getDisplayName(currentValues.displayName)} onChange={handleLdDisplayNameChange} />
      <ControlledDropdown className="dropdown" label={"Save scores:"} name={"insertOpt"} id={`insertOpt-${props.index}`} options={insertOptOptions} value={currentValues.insertOpt} onChange={handleInsertOptChange} />
      <ControlledDropdown className="dropdown" label={"Resets:"} name={"timeBased"} id={`timeBased-${props.index}`} options={timeBasedOptions} value={currentValues.timeBased} onChange={handleTimeBasedChange} />

      { currentValues.timeBased && currentValues.timeBased !== '' && currentValues.timeBased !== '-' ?
        <ControlledDropdown className="dropdown" label={"Timezone:"} name={"timezone"} id={`timezone-${props.index}`} options={timeZoneOptions} value={currentValues.timezone} onChange={handleTimezoneChange} />
      : null }

      <ControlledDropdown className="dropdown" label={"Max Entries:"} name={"maxEntries"} id={`maxEntries-${props.index}`} options={maxEntriesOptions} value={currentValues.maxEntries} onChange={handleMaxEntriesChange} />

      {/*<Button handleClick={submitLeaderboardConfigForm} text={getSubmitConfigFormText} loading={formState.isLoading} className="leaderBoardConfigFormSubmit" id={`button-${props.index}`} />*/}
      <div class="centered">
        <button className="secondaryButton leaderBoardConfigFormSubmit" id={`button-${props.index}`} onClick={submitLeaderboardConfigForm}>
          { currentValues.isNew ? "Submit" : "Update" }
        </button>
      </div>

      { formState.leaderBoardConfigFormSubmittedError ?
        <div className="explainer exceptions">
          There was an error saving your leaderboard config - {formState.leaderBoardConfigFormSubmittedErrorMessage}
        </div>
      : (formState.leaderBoardConfigFormSubmitted ?
        <div className="explainer successful">
          Configuration saved for leaderboard: {currentValues.name}
        </div>
      : null) }
    </div>
  )
}

export default LeaderboardConfigForm;
