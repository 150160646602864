import React from 'react';
import { useAppContext } from '../../libs/contextLib'
import "./GameSwitcher.css";

const gameToGameOption = (game) => {
  return {
    "name": game.name.length > 12 ? game.name.substring(0,12) + "..." : game.name,
    "value": game.id
  }
}

const getGameSwitcherOptions = (games) => games.map(gameToGameOption)

const GameSwitcher = props => {

  const { userSession, switchGame } = useAppContext()

  const renderOptions = (gameOptions) => {
    if(gameOptions.length === 0) {
      return <option value="nogamesyet" key="0">-- No games yet --</option>
    } else {
      return gameOptions.map(renderOption)
    }
  }

  const renderOption = (option, index) => {
    return <option value={option.value} key={index}>{option.name}</option>
  }

  const handleChange = event => {
    const newGameId = event.target.value
    switchGame(newGameId)
  }

  // GameSwitcher is drop-down that enables to select from a list of games
  // associated to a given user
  const gameOptions = getGameSwitcherOptions(userSession.games)

  return ( //this.props.games.length > 0 ?
    <div className="dropdown gameSwitcher">
      <form onSubmit={switchGame}>
       {/*<div className="dropdown">*/}
          <select
            name="gameSwitcher"
            id="gameSwitcher"
            value={userSession.gameId ? userSession.gameId : "" }
            onChange={handleChange}
            className="dropdownSelect gameSwitcherSelect">
              {renderOptions(gameOptions)}
          </select>
        {/*</div>*/}
    </form>
  </div> //:
  //null
  )
}

export default GameSwitcher;
