import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../../../libs/contextLib'
import { useNavigate } from "react-router-dom";
import ShowApiKey from '../../../Utils/ShowApiKey'
import ConfirmDeleteGame from './Modal/ConfirmDeleteGame'
import Button from '../../../Utils/Controls/Button'
import './../../Dashboard.css'
import './../Main.css'

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = '2WQMRe392f2T9bOxVogxh8z9ROJ0qWmt2O1aKiNF'

const Game = props => {

  const navigate = useNavigate()
  const { isAuthenticated, userSession, reloadGames } = useAppContext()
  const [game, setGame] = useState(userSession.games[0])
  const [showConfirmDeleteGameModal, setShowConfirmDeleteGameModal] = useState(false)

  useEffect(() => {
    setGame(userSession.games[0])
  }, [userSession.gameId]);

  const sendGetRequest = async (urlPath) => {
    const apiUrl = `${baseUrl}${urlPath}`
    console.log('apiUrl: ')
    console.log(apiUrl)
    const results = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'x-api-key': apiKey
      }
    })
    const jsonResponse = results.json()
    console.log('results: ')
    console.log(jsonResponse)
    return jsonResponse
  }

  const sendPostRequest = async (urlPath, json) => {
    const apiUrl = `${baseUrl}${urlPath}`
    const results = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
        'x-sw-plugin-version': '0.6.8'
      },
      body: JSON.stringify(json)
    })
    return results.json()
  }

  const deleteGame = async (gameId) => {
    const username = userSession.username
    if(username) {
      const urlPath = `delete_game/${username};${gameId}`
      const jsonResponse = await sendGetRequest(urlPath)
      if(jsonResponse.success) {
        reloadGames(null)
        navigate('/')
      }
      return jsonResponse
    } else {
      return {success: false, error: "You must be logged in to delete a game."}
    }
  }

  const hideConfirmDeleteGameModal = () => {
    setShowConfirmDeleteGameModal(false)
  }

  const renderGame = () => {
    return <div className="game">
      <h2 className="gameTitle">Your game details:</h2>
      <div className="gameDetails">
        <div className="gameDetailsRow">
          <span className="subtitleLabel">Game ID:</span>{game.id}
        </div>
        <div className="gameDetailsRow">
          <span className="subtitleLabel">Game name:</span>{game.name}
        </div>
        <div className="gameDetailsRow">
          <ShowApiKey />
        </div> 
        <div className="deleteGameRow">
          <Button buttonStyle="secondaryButton" handleClick={() => setShowConfirmDeleteGameModal(true)} text="Delete this game" />
        </div>
        { showConfirmDeleteGameModal ?
            <ConfirmDeleteGame gameId={game.id} show={showConfirmDeleteGameModal} onClose={hideConfirmDeleteGameModal} onDelete={deleteGame}>
              <p className="modalMessage">Are you sure you want to delete this game? </p>
              <p>Any data associated with this game will be lost, and there's no turning back! If you're not sure, click Cancel below. </p>
              <p className="modalItem">
                <span className="modalLabel">Game ID:</span>
                {game.id}
              </p>
            </ConfirmDeleteGame> :
            null
        }
      </div>
    </div>
  }


  return (
    <div className="main">
      { 
        game ? renderGame() : <div className="comingSoon"></div>
      }
    </div>
  )
}

export default Game
