import React from 'react';
import "./Newsletter.css";

const NewsletterSubConfirmed = props => {

  return (
    <div className="NewsletterSubConfirmed">
      <div className="pageContainer newsletterContainer">
        <h2>Thanks for subscribing to the SilentWolf newsletter.</h2>
        <div className="explainer newsletterExplainer">
          We'll send you updates on SilentWolf features and other news.
        </div>
        <div className="explainer newsletterExplainer">
          They will come in on irregular basis and at most once a month. And no spam, promised.
        </div>
        <div className="explainer newsletterExplainer">
          You can unsubscribe at any time.
        </div>
        <a href="/features">
          <button className="newsletterButton">
            Home
          </button>
        </a>
      </div>
    </div>
  )
}

export default NewsletterSubConfirmed
