import React, { useState, Fragment} from 'react';
import { useAppContext } from '../../libs/contextLib'
import Button from './Controls/Button'
import "./ShowApiKey.css";

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = 'FmKF4gtm0Z2RbUAEU62kZ2OZoYLj4PNXa5YRIBb0'

const sendGetRequest = async (urlPath) => {
  const apiUrl = `${baseUrl}${urlPath}`
  const results = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey
    }
  })
  return results.json()
}

const ShowApiKey = props => {

  const { userSession } = useAppContext()

  const [formState, setFormState] = useState({
    apiKey: null,
    apiKeyRetrieved: false,
    isLoading: false
  })
  //const [apiKey, setApiKey] = useState([]);
  //const [apiKeyRetrieved, setApiKeyRetrieved] = useState(false);

  const getApiKey = async () => {

    setFormState({
      ...formState,
      isLoading: true
    })

    const path = "get_user_api_key/" + userSession.username
    const jsonResults = await sendGetRequest(path)

    setFormState({
      apiKey: jsonResults.apiKey,
      apiKeyRetrieved: true,
      isLoading: false
    })
    //setApiKey(jsonResults.apiKey)
    //setApiKeyRetrieved(true)
  }

  const showApiKeyButton = () => {
    return(
      <>
        <div className="explainer">
          <i>Lost your API key? </i>
          <Button buttonStyle="secondaryButton" handleClick={getApiKey} text="View your API key" loading={formState.isLoading} />
        </div>
      </>
    )
  }

  const showApiKey = () => {
    return(
      <>
        <div className="explainer">
          <i>Your API key: </i>
          <div className="apiKeyValue">
            {formState.apiKey}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="ShowApiKey">
      {!formState.apiKeyRetrieved
        ? showApiKeyButton()
        : showApiKey()}
    </div>
  );
}

export default ShowApiKey
