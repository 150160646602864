import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './SidebarElement.css'


// TODO: use React Router <Link /> instead of <a> tags below?
const SidebarElement = props => {

  const navigate = useNavigate();

  function handleClick() {
    navigate(props.url);
  }

  const sidebarLinkClass = () => {
    let returnClass = ""
    if(props.active) {
      returnClass = " active"
    }
    return returnClass
  }

  const getIconSrc = () => {
    let iconSrc = props.iconSrc
    if(props.active) {
      iconSrc = props.iconSelectedSrc
    }
    return iconSrc
  }

  return (
      <Link to={props.url} className={`sidebarElement ${sidebarLinkClass()}`}>
        <div className={`sidebarIcon ${sidebarLinkClass()}`}>
            <img src={getIconSrc()} alt={props.alt} />
        </div>
        <div className={`sidebarText ${sidebarLinkClass()}`}>
            {props.text}
        </div>
      </Link>
  )
}

export default SidebarElement
