import React from 'react';

const renderOption = (option, index) => {
  return <option id={option.value} value={option.value} name={option.name} key={index}>{option.name}</option>
}

// use ControlledDropdown instead of Dropdown when you're feeding a dynamic value
// that can changed based on the state of the parent component rather than a static default value
// (so we use 'value' instead of 'defaultValue')
const ControlledDropdown = ({ label, name, id, options, value, onChange }) => (
  <div className="dropdownField">
    <label className="dropdownFieldLabel" htmlFor={id}>
      {label}
    </label>
    <select
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      className="dropdownSelect">
      {
        options.map(renderOption)
      }
    </select>
  </div>
);

export default ControlledDropdown;
