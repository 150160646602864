import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../libs/contextLib'
import Checkbox from '../Utils/Checkbox'
import CustomEmailSettings from './CustomEmailSettings'
import ControlledDropdown from '../Utils/ControlledDropdown'

const regTypeOptions = [{ name:"Email / username / password", value:"email_un_pwd" }, { name:"Username / password", value:"un_pwd" }]

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = 'FmKF4gtm0Z2RbUAEU62kZ2OZoYLj4PNXa5YRIBb0'

const isTrue = (testBoolean) => {
  return (testBoolean !== undefined && testBoolean)
}

const sendPostRequest = async (urlPath, json) => {
  const apiUrl = `${baseUrl}${urlPath}`
  const results = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(json)
  })
  return results.json()
}

const AuthSettings = props => {

  const { userSession } = useAppContext()

  /**const [gameName, setGameName] = useState( (userSession.games && userSession.games.length > 0) ? userSession.games[0].name : props.gameName)
  const [authEnabled, setAuthEnabled] = useState( (props.games && props.games.length > 0)  ? isTrue(props.games[0].player_auth_enabled) : false)
  const [checkboxLabel, setCheckboxLabel] = useState( (props.games && props.games.length > 0 && isTrue(props.games[0].player_auth_enabled)) ? "Player authentication is enabled" : "Player authentication is disabled")
  const [regType, setRegType] = useState( (userSession.games && userSession.games.length > 0 && userSession.games[0].reg_type) ? userSession.games[0].reg_type : "email_un_pwd" )
  const [requestEmailConfirmation, setRequestEmailConfirmation] = useState(props.games ? isTrue(props.games[0].email_conf_enabled) : false)
  const [requestEmailConfirmationLabel, setRequestEmailConfirmationLabel] = useState(props.games && isTrue(props.games[0].email_conf_enabled) ? "Email confirmation is enabled" : "Email confirmation is disabled")
*/

  const initValues = {
    gameName: (userSession.games && userSession.games.length > 0) ? userSession.games[0].name : props.gameName,
    authEnabled: (props.games && props.games.length > 0)  ? isTrue(props.games[0].player_auth_enabled) : false,
    checkboxLabel: (props.games && props.games.length > 0 && isTrue(props.games[0].player_auth_enabled)) ? "Player authentication is enabled" : "Player authentication is disabled",
    regType: (userSession.games && userSession.games.length > 0 && userSession.games[0].reg_type) ? userSession.games[0].reg_type : "email_un_pwd",
    requestEmailConfirmation: props.games ? isTrue(props.games[0].email_conf_enabled) : false,
    requestEmailConfirmationLabel: props.games && isTrue(props.games[0].email_conf_enabled) ? "Email confirmation is enabled" : "Email confirmation is disabled",
  }

  const [formState, setFormState] = useState({
    gameName: undefined,
    authEnabled: undefined,
    checkboxLabel: undefined,
    regType: undefined,
    requestEmailConfirmation: undefined,
    requestEmailConfirmationLabel: undefined
  })

  const currentValues = {
    gameName: formState.gameName ? formState.gameName : initValues.gameName,
    authEnabled: formState.authEnabled ? formState.authEnabled : initValues.authEnabled,
    checkboxLabel: formState.checkboxLabel ? formState.checkboxLabel : initValues.checkboxLabel,
    regType: formState.regType ? formState.regType : initValues.regType,
    requestEmailConfirmation: formState.requestEmailConfirmation ? formState.requestEmailConfirmation : initValues.requestEmailConfirmation,
    requestEmailConfirmationLabel: formState.requestEmailConfirmationLabel ? formState.requestEmailConfirmationLabel : initValues.requestEmailConfirmationLabel
  }

  useEffect(() => {
    console.log('userSession: ')
    console.log(userSession)
    console.log('regType: ')
    console.log(formState.regType)
    console.log('regType from userSession: ')
    console.log(userSession.games[0].reg_type)
    console.log('regType from props: ')
    console.log(props.games[0].reg_type)
    if(props.games.length > 0) {
      setFormState({
        gameName: props.games[0].name,
        authEnabled: isTrue(props.games[0].player_auth_enabled),
        checkboxLabel: isTrue(props.games[0].player_auth_enabled) ? "Player authentication is enabled" : "Player authentication is disabled",
        regType: props.games[0].reg_type,
        requestEmailConfirmation: isTrue(props.games[0].email_conf_enabled),
        requestEmailConfirmationLabel: isTrue(props.games[0].email_conf_enabled) ? "Email confirmation is enabled" : "Email confirmation is disabled"
      })
      /**setGameName(props.games[0].name)
      setAuthEnabled(isTrue(props.games[0].player_auth_enabled))
      setCheckboxLabel(isTrue(props.games[0].player_auth_enabled) ? "Player authentication is enabled" : "Player authentication is disabled")
      setRegType(props.games[0].reg_type)
      setRequestEmailConfirmation(isTrue(props.games[0].email_conf_enabled))
      setRequestEmailConfirmationLabel(isTrue(props.games[0].email_conf_enabled) ? "Email confirmation is enabled" : "Email confirmation is disabled")
      */
    } else {
      setFormState({
        gameName: props.gameName,
        authEnabled: false,
        checkboxLabel: 'Player authentication is disabled',
        regType: 'email_un_pwd',
        requestEmailConfirmation: false,
        requestEmailConfirmationLabel: 'Email confirmation is disabled'
      })
      /**setGameName(props.gameName)
      setAuthEnabled(false)
      setCheckboxLabel('Player authentication is disabled')
      setRegType('email_un_pwd')
      setRequestEmailConfirmation(false)
      setRequestEmailConfirmationLabel('Email confirmation is disabled')*/
    }
    console.log('props.games: ')
    console.log(props.games)
    console.log('regType after useEffect: ')
    console.log(formState.regType)
  }, [props.gameId]);

  const handleCheckboxChange = async event => {
    let newCheckboxLabel = "Player authentication is disabled"
    if(!formState.authEnabled) {
      newCheckboxLabel = "Player authentication is enabled"
    }
    await setAuthenticationEnabled(formState.authEnabled)
    setFormState({
      ...formState,
      authEnabled: !formState.authEnabled,
      checkboxLabel: newCheckboxLabel
    })
    /*setAuthEnabled(!authEnabled)
    setCheckboxLabel(newCheckboxLabel)*/
  }

  const handleRegTypeChange = async (newRegType) => {
    await setRegistrationType(newRegType)
    setFormState({
      ...formState,
      regType: newRegType
    })
    //setRegType(newRegType)
  }

  const handleRequestEmailConfirmationChange = async event => {
    let newRequestEmailConfirmationLabel = "Email confirmation is disabled"
    if(!formState.requestEmailConfirmation) {
      newRequestEmailConfirmationLabel = "Email confirmation is enabled"
    }
    await setEmailConfirmationEnabled(formState.requestEmailConfirmation)
    const newRequestEmailConfirmation = !formState.requestEmailConfirmation
    setFormState({
      ...formState,
      requestEmailConfirmation: newRequestEmailConfirmation,
      requestEmailConfirmationLabel: newRequestEmailConfirmationLabel
    })
    /*setRequestEmailConfirmation(!requestEmailConfirmation)
    setRequestEmailConfirmationLabel(newRequestEmailConfirmationLabel)*/
  }

  // enables or disables authentication based on the value of the parameter
  const setAuthenticationEnabled = async (authCurrentlyEnabled) => {
    console.log('Calling setAuthenticationEnabled')
    const path = 'update_player_auth_settings'
    const json = {
      'game_id': props.gameId,
      'auth_enabled': !authCurrentlyEnabled
    }
    sendPostRequest(path, json)
  }

  const setEmailConfirmationEnabled = async (emailConfCurrentlyEnabled) => {
    const path = "update_player_auth_settings"
    const json = {
      'game_id': props.gameId,
      'request_email_confirmation': !emailConfCurrentlyEnabled
    }
    sendPostRequest(path, json)
  }

  const setRegistrationType = async (regType) => {
    console.log('Calling setRegistrationType')
    const path = 'update_player_auth_settings'
    const json = {
      'game_id': props.gameId,
      'reg_type': regType
    }
    sendPostRequest(path, json)
  }

  return (
    <div className="authSettings">
      {/**<div className="explainer">
        Now that you've enabled player authentication for your game, SilentWolf supports different authentication mechanisms for your players:
        <ul>
          <li>Username (email) and Password</li>
          <li>Facebook login</li>
          <li>Google login</li>
        </ul>
      </div>*/}
      <div className="explainer">
        <Checkbox name="authEnabled" label={currentValues.checkboxLabel} isSelected={formState.authEnabled} onChange={handleCheckboxChange} />
      </div>
      <div className="explainer">
        If you don't enable player authentication, or if you disable it at any point, you'll get an error when you try to call the SilentWolf Auth APIs.
      </div>
      { currentValues.authEnabled ?
        <div className="authSettings">
          <h2 className="titleShort">Player registration options</h2>
          <div className="explainer">
            There are currently two ways to enable player registration in your game:
          </div>
          <ol className="explainer">
            <li><span className="spanTitle">Registration with player name, email and password</span>. In this case you will be asking your players for three pieces of information dduring registration: a player name (username), an email address and a password. After successful registration, they will be able to log in simply using their player name and password. There are several advantages to requesting an email address as well. First of all it means that you'll be able to communicate with your players in the future (obviously, you should not abuse this by sending spam or other unsolicited emails). In case your players forget their password after registration, having their email address will allow you to let them reset their password by themselves as explained below in the section entitled: 'Reset password'. If you choose this option we strongly recommend that you also require email validation as described in the section below entitled: 'Email confirmation'.</li>
            <li><span className="spanTitle">Registration with username and password</span>. If you don't want to ask for your players' mail address you can also require only a player name (username) and password on sign up. In this case the accounts will be automatically confirmed after successful registration. Obviously, players will not be able to reset their own passwords.</li>
          </ol>
          <div className="explainer">
            <ControlledDropdown options={regTypeOptions} name="regType" value={currentValues.regType} id="regType" label="Player sign up:" onChange={(e) => handleRegTypeChange(e.target.value)} />
            {/*<Dropdown options={regTypeOptions} name="regType" defaultValue={currentValues.regType} id="regType" label="Player sign up:" onChange={(e) => handleRegTypeChange(e.target.value)} />*/}
          </div>
          { currentValues.regType === 'email_un_pwd' ?
            <div className="emailSettings">
              <div className="explainer">
                In email / username / password registration, you'll prompt the player for their email address and ask them to choose a password. You can also optionally request that they confirm their email address by sending a code to them by email that you then prompt them for to complete the registration process. If you've enabled email confirmation, you can also use the player's email address to let them reset their password. The plugin's <a href="/playerauth#builtinScenes">built-in scenes</a> do all of this already. You can customize the email subjects and contents in both cases, and the email address that will send the emails:
              </div>
              <div className="explainer">
                <Checkbox name="requestEmailConfirmation" label={currentValues.requestEmailConfirmationLabel} isSelected={formState.requestEmailConfirmation} onChange={handleRequestEmailConfirmationChange} />
              </div>
              <div className="explainer">
                { currentValues.requestEmailConfirmation ? <CustomEmailSettings gameId={props.gameId} gameName={currentValues.gameName} games={props.games} username={props.username} /> : null }
              </div>
              <div className="explainer">
                Keep reading to see how you can implement the email confirmation flow in your game.
              </div>
            </div>
            : null }
        </div>
      : null }
    </div>
  )
}

export default AuthSettings;
