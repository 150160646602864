import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../libs/contextLib';
import { useFormFields } from '../../libs/hooksLib';
import { useNavigate } from 'react-router-dom';
import Button from '../Utils/Controls/Button'

const Login = props => {

  const { isAuthenticated, userHasAuthenticated, userSession } = useAppContext()
  console.log("userSession from context: ")
  console.log(userSession)
  console.log("isAuthenticated from context: ")
  console.log(isAuthenticated)

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [forgotPosition, setForgotPosition] = useState(35);
  //const [newUser, setNewUser] = useState(null);
  //const [userId, setUserId] = useState(null)

  const handleSigninSubmit = async event => {
    event.preventDefault();

    setIsLoading(true)

    const lowerCaseEmail = fields.email.toLowerCase()

    Auth.signIn(lowerCaseEmail, fields.password)
      .then(user => {
        userHasAuthenticated(true);
        // in final version, use should be redirected to dashboard after login
        //navigate('/dashboard')
        navigate('/features')
      })
      .catch(e => {
        setIsLoading(false)
        if (e.name === 'UserNotFoundException' || e.name === 'NotAuthorizedException') {
          setError('Incorrect username or password')
        } else {
          console.log(e);
          alert(e.message);
        }
      });
  };

  const changeForgotPosition = e => {
    const fgtPosition = forgotPosition === 35 ? 20 : 35
    setForgotPosition(fgtPosition);
  };

  return (
      <div className="login">
        <div className="loginForm ">
          <form onSubmit={handleSigninSubmit}>
            <div className="emailPassword">
              <h3 className="miniTitle centered">
                Sign in to your existing account
              </h3>
              <div className="row">
                <label htmlFor="email" className="label">
                  Email address:
                </label>
                <input type="text"
                        id="email"
                        className="field"
                        placeholder="email@example.com"
                        value={fields.email}
                        onChange={handleFieldChange}
                        required />
              </div>
              <div className="row">
                <label htmlFor="password" className="label">
                  Password:
                </label>
                <input type="password"
                       id="password"
                       className="field"
                       onChange={handleFieldChange}
                       onFocus={changeForgotPosition}
                       onBlur={changeForgotPosition}
                       required />
              </div>
            </div>
            <div className="exceptions">
              {error}
            </div>
            <div className="centered">
              <Button handleClick={handleSigninSubmit} text="Submit" loading={isLoading} />
            </div>
          </form>
          <div className="forgotPwd centered">
            <a href="/forgotPassword">Forgot password?</a>
          </div>
        </div>
      </div>
  )
}

export default Login
