import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Button from '../Utils/Controls/Button'
import { useFormFields } from '../../libs/hooksLib';
import { Link } from 'react-router-dom';
import "./Auth.css";

const ChangePassword = props => {

  const [fields, handleFieldChange] = useFormFields({
                                        oldPassword: "",
                                        newPassword: "",
                                        confirmPassword: ""
                                      });
  const [formState, setFormState] =  useState({
                                      isLoading: false,
                                      passwordChanged: false,
                                      error: undefined
                                    });

  const handleSubmit = async event => {

    event.preventDefault();

    if ( fields.newPassword !== fields.confirmPassword ) {
      setFormState({ 
        ...formState,
        error: "The two new passwords you entered don't match." 
      })
    } else {

      setFormState({
        ...formState,
        isLoading: true
      });

      Auth.currentAuthenticatedUser()
          .then(user => {
              console.log("user: " + user)
              return Auth.changePassword(user, fields.oldPassword, fields.newPassword);
          })
          .then(data => {
            console.log(data)
            setFormState({
              ...formState,
              passwordChanged: true,
              isLoading: false
            });
          }).catch(err => {
            console.log(err)
            if(err.name === "LimitExceededException") {
              setFormState({
                ...formState,
                error: "You've requested a password change too often. Please try again later.",
                isLoading: false
              });
            } else if(err.name === "NotAuthorizedException") {
              setFormState({
                ...formState,
                error: "Your old password is incorrect.",
                isLoading: false
              });
            } else if(err.name === "InvalidPasswordException") {
              setFormState({
                ...formState,
                error: "Your new password is invalid. Passwords must contain at least 8 characters and feature at least one letter, one number and one symbol.",
                isLoading: false
              });
            } else {
              setFormState({
                ...formState,
                error: "Something wrong happened. If this problem persists please contact us and we'll help you get this sorted.",
                isLoading: false
              });
            }
          });
    }
  };

  const renderChangePwdForm = () => {
    return (
      <div className="basicContainer changePasswordContainer">
        <form className="changePwdForm">
          {/** adding a hidden input field with autocomplete set to off.
           this si the only way to cancel the incorrect autofill on all browsers
           (otherwise they try to put the email address in the code field) */}
          <input autoComplete="false" name="hidden" type="text" className="hidden" />
          <h3 className="miniTitle">Please enter your current password and then your new password twice.</h3>
          <div className="row">
            <label className="label" htmlFor="oldPassword">Enter old password:</label>
            <input className="field" type="password" id="oldPassword" value={fields.oldPassword}
                    onChange={handleFieldChange} autoComplete="off" required />
          </div>
          <div className="row">
            <label className="label" htmlFor="newPassword">Enter new password:</label>
            <input className="field" type="password" id="newPassword" value={fields.newPassword}
                     onChange={handleFieldChange} autoComplete="off" required />
          </div>
          <div className="row">
            <label className="label" htmlFor="confirmNewPassword">Confirm new password:</label>
            <input className="field" type="password" id="confirmPassword" value={fields.confirmPassword}
                     onChange={handleFieldChange} autoComplete="off" required />
          </div>
          <div className="exceptions">
            {formState.error}
          </div>
          <div className="centered">
            <Button handleClick={handleSubmit} text="Submit" isLoading={formState.isLoading} />
          </div>
        </form>
      </div>
    )
  }

  const renderChangePwdChanged = () => {
    return (
      <div className="basicContainer quickConfirmationContainer ">
        <div className="row">
          Your password has been changed.
        </div>
        <div className="row">
          <Link to="/features">
            <button className="homeButton">Home</button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="ChangePassword">
      {!formState.passwordChanged
        ? renderChangePwdForm()
        : renderChangePwdChanged()}
    </div>
  )
}

export default ChangePassword
