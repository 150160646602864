import React from 'react';
import { Link } from 'react-router-dom'
import CodeBlock from '../Utils/Controls/CodeBlock'
import './TroubleShooting.css';

// Snippets for the code blocks for Godot 3.x and Godot 4.x
const timerSnippets = {
  g3: `
  yield(get_tree().create_timer(0.1), "timeout")
  SilentWolf.Scores.get_high_scores()`, 
  g4: `
  async get_tree().create_timer(0.1).timeout
  SilentWolf.Scores.get_high_scores()`
};

const ldNameSnippets = {
  g3: `
  #var ld_name = "main"
  var ld_name = "MyCustomLeaderboardName"`, 
  g4: `
  #var ld_name = "main"
  var ld_name = "MyCustomLeaderboardName"`
};



const Troubleshooting = props => {

  return (
    <div className="Troubleshooting">
      <div className="troubleshootingContainer">
        <h1>Troubleshooting SilentWolf</h1>
        <h2 className="titleShort">Getting this warning in Godot Editor: "Parse Error: Couldn't fully preload the script, possible cyclic reference or compilation error." or "Failed loading resource: res://addons/silent_wolf/SilentWolf.gd. Make sure resources have been imported by opening the project in the editor at least once."</h2>
        <div className="explainer troubleshootingExplainer">
          Closing/reopening Godot Editor should fix this problem. This warning should go away as of version 0.6.14 of the plugin.
        </div>
        <h2 className="titleShort">Can't call SilentWolf functions</h2>
        <div className="explainer troubleshootingExplainer">
          If you're getting an error such as "Invalid call. Nonexistent function 'persist_score' in base 'Node'", it probably means that the SilentWolf plugin is not properly installed. Make sure to follow the <a href="https://silentwolf.com/leaderboard">installation instructions</a> precisely. In particular, make sure that the plugin is unzipped directly in the 'addons' folder in your Godot project. Also make sure that the 'addons' folder is spelt in lowercase and that no extra folders are in the path.
        </div>
        <h2 className="titleShort">Debug logging</h2>
        <div className="explainer troubleshootingExplainer">
          If your plugin is installed properly and if you're having trouble with any part of SilentWolf, the first thing you can do is enable debug-level logging. To do so just set the value for "log_level" to 2 in your call to SilentWolf.configure at the start of your game. You can also call SilentWolf.configure_log_level(2).
        </div>
        <div className="explainer troubleshootingExplainer">
          This will produce much more verbose logs, including valuable information to help you pinpoint the problem.
        </div>
        <div className="explainer troubleshootingExplainer">
          With debug level logging you will probably overflow Godot editor's default output console buffer limit so you will probably also want to increase the limit at: Project > Project Settings... > Network > Limits > Max Chars Per Seconds
        </div>
        <h2 className="titleShort">Expecting a signal that never gets emitted?</h2>
        <div className="explainer troubleshootingExplainer">
          SilentWolf relies heavily on <a href="https://docs.godotengine.org/en/3.1/getting_started/step_by_step/signals.html">Godot signals</a>, so make sure you understand how they work before you use the plugin.
        </div>
        <div className="explainer troubleshootingExplainer">
          Depending on how your game works, you may want to <a href="/callingSilentWolf">call SilentWolf functions in a certain way</a> to get the result you expect.
        </div>
        <div className="explainer troubleshootingExplainer">
          Also please note that since version 0.5.0 of the plugin all SilentWolf signal names start with "sw_", so you might just be connecting using the wrong signal names (the old signal names will still work for scores and leaderboards for the moment, but it's better to migrate to the new names as soon as possible; you just need to prepend "sw_" to all of our signals).
        </div>
        <h2 className="titleShort">Getting the error: "Invalid Call. Nonexistent function in base Nil." when calling a SilentWolf function</h2>
        <div className="explainer troubleshootingExplainer">
          This error is usually caused by the fact that you are calling the function before the SilentWolf plugin has been fully set up (i.e. the _ready() function in SilentWolf.gd has not completed).
        </div>
        <div className="explainer troubleshootingExplainer">
          To fix this, make sure that the SilentWolf.gd script is at the very top of your list of Autoloaded singletons in Project Settings > Autoload. If this is not possible, you can still call the SilentWolf functions from another script that is further down in the list, from a script associated with a Scene in your game, or even from an autoloaded singleton that loads before SilentWolf.gd, but then not from either its _init() or _ready() function.
        </div>
        <h2 className="titleShort">Player names appear in my leaderboard, but not score values</h2>
        <div className="explainer troubleshootingExplainer">
          Check the custom font on the 'Score' node within res://addons/silent_wolf/Scores/ScoreItem.tscn. In the Inspector pane in the 'Control' section under 'Custom Fonts', have a look at the 'Font' set of parameters and in particular the 'Font Path'.  It should point to a font that is in the plugin (res://addons/silent_wolf/assets/fonts/Comfortaa-Bold.ttf). If not, make it point to existing font data.
        </div>
        <h2 className="titleShort">No response on request (freeze)</h2>
        <div className="explainer troubleshootingExplainer">
          If you called on of SilentWolf's functions and you can see in the logs that the response doesn't come back, there are a few things that could be going on. First of all, make sure you are not setting get_tree().paused = true when waiting for a backend request to resolve. Pausing the game also pauses all HTTP requests so you will never get a response until the game restarts. If this is not the case, one thing you can try is to wait for a short-lived timer to complete immediately before the function call:
        </div>
        <CodeBlock codeSnippets={timerSnippets} />
        <div className="explainer troubleshootingExplainer">
            This often solves problems where Godot seems to be stuck on a request.
        </div>
        <h2 className="titleShort">Can't see scores for custom leaderboard</h2>
        <div className="explainer troubleshootingExplainer">
          Make sure there are no typos in the leaderboard name! When retrieving the scores you should use the correct ld_name (leaderboard names are case-sensitive), the same as the ones you configured in your custom leaderboard settings (https://silentwolf.com/leaderboard).
          If you’re using the built-in leaderboard scene (res://addons/silent_wolf/Scores/Leaderboard.tscn), make sure to change the value of the “ld_name” variable to use your custom leaderboard name.
        </div>
        <CodeBlock codeSnippets={ldNameSnippets} />
        <h2  className="titleShort">Built-in scenes appear too large or too small for the viewport</h2>
        <div className="explainer troubleshootingExplainer">
          The built-in scenes are built with a target resolution of 1920 (width) by 1080 (height). You can check out your game's resolution in Project Settings > Display > Window > width and height
        </div>
        <h2  className="titleShort">SilentWolf works well in Godot Editor on Windows but it stops working when I export to a different platform (e.g. HTML5)</h2>
        <div className="explainer troubleshootingExplainer">
          One reason could be that paths are case-insensitive on Windows but not on other platforms. Make sure your paths are correct, including the case. In particular, the 'addons' folder must be lowercase.
        </div>
        <div className="explainer troubleshootingExplainer">
          Also, there is a known issue in Godot 3.4.3 that will make it impossible to connect to SilentWolf when your game is deployed as an HTML5 application. You should consider either reverting to Godot 3.4.2 or upgrading to 3.4.4 or later.
        </div>
        <h2  className="titleShort">I'm using C# to build my game and I'm having trouble getting SilentWolf to work</h2>
        <div className="explainer troubleshootingExplainer">
          SilentWolf was built using GDScript, but you can still use it in your C# game. Here's a short tutorial: https://silentwolf.com/csharp.
        </div>
        <h2  className="titleShort">Legacy issues</h2>
        <div className="explainer troubleshootingExplainer">
          The issues below should no longer take place but might still be lingering if you're using an old version of Godot (pre-3.1) or an old version o the SilentWolf plugin (pre-0.6.12).
        </div>
        <h3 className="troubleshootingTitle">You get the following error: "Godot couldn't connect to the SilentWolf backend. This is probably due to custom SSL configuration in Project Settings > Network > SSL"</h3>
        <div className="explainer troubleshootingExplainer">
          If you are using a version of Godot prior to 3.1 and are having problems connecting to the SilentWolf backend using the Godot plugin, it's probable that you have an SSL certificate configured in your project seetings that overrides the default that SilentWolf expects.
        </div>
        <div className="explainer troubleshootingExplainer">
          Since Godot 3.1, the engine is shipped with a Mozilla certificate bundle is built into the Godot Editor (and all export templates) so you don't need to configure your own certificates in your project settings. SilentWolf doesn't need any special SSL configuration to work. In fact, if you change the SSL configuration you will override the default client-side certificates which can cause problems when attempting to contact the SilentWolf backend.
        </div>
        <div className="explainer troubleshootingExplainer">
          To fix this, in Godot editor go to Project Settings (General tab) > Network > SSL and remove any certificated configured there.
        </div>
        <div className="explainer troubleshootingExplainer">
          If you are seeing this error even though you're using a more recent version of Godot, it is probably a red herring. The actual problem might be one from the list above. If you're don't hesitate to contact us at the email address below.
        </div>
        <h2 className="titleShort">Contact SilentWolf support</h2>
        <div className="explainer troubleshootingExplainer">
          If you're stuck and need assistance don't heistate to <Link to="/contact">contact us</Link>.
        </div>
      </div>
    </div>
  )
}

export default Troubleshooting
