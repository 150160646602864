import React, { useState, useEffect } from 'react'
import ConfirmDeleteScore from "./Modal/ConfirmDeleteScore";
import ScoreMetadata from "./Modal/ScoreMetadata";
import Button from '../../../Utils/Controls/Button'
import './LeaderboardElement.css'

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = '2WQMRe392f2T9bOxVogxh8z9ROJ0qWmt2O1aKiNF'

const MAX_SCORES = 50

const sendGetRequestLegacy = async (urlPath) => {
  const apiUrl = `${baseUrl}${urlPath}`
  console.log("In sendGetRequestLegacy, apiUrl: ", apiUrl)
  const results = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey
    }
  })
  const jsonResponse = results.json()
  return jsonResponse
}

const sendGetRequest = async (urlPath) => {
  const apiUrl = `${baseUrl}${urlPath}`
  const results = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey,
      'x-sw-plugin-version': '0.9.2'
    }
  })
  const jsonResponse = results.json()
  return jsonResponse
}

const LeaderboardElement = props => {

  const [scoresLoading, setScoresLoading] = useState(true)
  const [paginationKey, setPaginationKey] = useState(null)
  const [showDeleteScoreModal, setShowDeleteScoreModal] = useState(false)
  const [scoreToBeDeleted, setScoreToBeDeleted] = useState(null)
  const [showScoreMetadataModal, setShowScoreMetadataModal] = useState(false)
  const [metadataForScore, setMetadataForScore] = useState(null)

  const [ldState, setLdState] = useState({ 
                                          selected: false,
                                          scores: null,
                                          legacyAddon: false
                                        })

  useEffect(() => {
    console.log("In useEffect...")
  }, []);

  const getScoresInLeaderboard = async (maxScores, ldName) => {
    // Get scores for Godot 4.x games
    console.log("In getScoresInLeaderboard, ldName: ", ldName)
    const firstResults = (paginationKey === null)
    let ldScores = await getScores(maxScores, ldName)
    console.log("ldScores from scores_prod:")
    console.log(ldScores)
    console.log("typeof ldScores: ", typeof ldScores)
    console.log("ldScores[0]: ", ldScores[0])
    if (ldScores && ldScores.length > 0) {
      console.log("Resetting ldState:")
      const newScores = firstResults ? ldScores : ldState.scores.concat(ldScores)
      setLdState({
        selected: true,
        scores: newScores,
        legacyAddon: false
      })
    } else {
      // Get scores for Godot 3.x games
      ldScores = await getScoresLegacy(maxScores, ldName)
      console.log("ldScores from scores (legacy):")
      console.log(ldScores)
      setLdState({
        selected: true,
        scores: ldScores,
        legacyAddon: true
      })
    }
    setScoresLoading(false)
  }

  // Latest game versions are stored (in the game object in DDB) at each new persist score.
  const getScoresLegacy = async (maxScores, ldName) => {
    let newScores = []
    const username = props.username
    if(username) {
      const latestVersion = props.latestVersion ? props.latestVersion : '0.1.0'
      const urlPath = `get_top_scores/${props.gameId}?version=${latestVersion}&max=${maxScores}&ldboard_name=${ldName}`
      const jsonResponse = await sendGetRequestLegacy(urlPath)
      newScores = jsonResponse.top_scores
      console.log("legacy newScores: ", newScores)
    }
    return newScores
  }

  const getGodotVersion = async (ldState) => {
    const godotVersion = ldState.scores.length > 0 && ldState.legacyAddon ? "3" : ldState.scores.length > 0 ? "4" : "-"
    return godotVersion
  }

  // Latest game versions are stored (in the game object in DDB) at each new persist score.
  const getScores = async (maxScores, ldName) => {
    let paginationKeySuffix = ''
    let newScores = []
    const username = props.username
    if(paginationKey !== null) {
      // PaginationKey is made up of sd, s, sid and gid_ln (where sd is a string, s is an int, sid is a string and gid_ln is a string).
      // We're already sending the gid and ln (ld_name) as part in the query params, and we're not ddealing with offsets in the dashboard yet,
      // so we can just send the sid (score_id) and s (score value) of the pagination key as extra query params.
      /**
       * input_pagination_key = {
            'sd': f"{game_id};{ld_name}",
            's': int(query_params['s']),
            'sid': query_params['sid'],
            'gid_ln': f"{game_id};{ld_name}"
        }
       */
      const sid = paginationKey.sid
      const s = paginationKey.s
      paginationKeySuffix = `sid=${sid}&s=${s}`
    }
    if(username) {
      const urlPath = `get_scores/${props.gameId}?max=${maxScores}&ldboard_name=${ldName}&pagination=true&${paginationKeySuffix}`
      const jsonResponse = await sendGetRequest(urlPath)
      newScores = jsonResponse.top_scores
      const paginationKey = jsonResponse.pagination_key
      if(jsonResponse.hasOwnProperty('pagination_key')) {
        setPaginationKey(jsonResponse.pagination_key)
      } else {
        setPaginationKey(null)
      }
    }
    return newScores
  }

  const confirmDeleteScore = (scoreId, playerName, scoreValue) => {
    // open modal window to confirm deletion
    setScoreToBeDeleted({
      scoreId: scoreId,
      playerName: playerName,
      scoreValue: scoreValue
    })
    setShowDeleteScoreModal(true)
  }

  const closeLeaderboard = () => {
    setLdState({
      ...ldState,
      selected: false
    })
  }

  const openLeaderboard = async () => {
    await getScoresInLeaderboard(MAX_SCORES, props.ldName)
    // const ldScores =  await getScoresInLeaderboard(MAX_SCORES, props.ldName)
    /**setLdState({
      ...ldState,
      selected: true
    })*/
    //console.log('openLeaderboard, scores: ', ldState.scores)
    //console.log('openLeaderboard, Selected: ', ldState.selected)
    //console.log('openLeaderboard, Selected && scores: ', ldState.selected && ldState.scores)
  }

  const hideDeleteScoreModal = () => {
    setShowDeleteScoreModal(false)
  }

  const deleteScore = async (scoreId) => {
    // TODO: since we're getting scores in LeaderboardElement.js, why not get delete the scores here as well (rather than calling parent component)?
    // TODO check if legacy addon or not, and call different deleteScore function (with different headers) accordingly
    console.log("Deleting score, scoreId, props.ldName, ldState.legacyAddon: ", scoreId, props.ldName, ldState.legacyAddon)
    const deleteResponse = await props.deleteScore(scoreId, props.ldName, ldState.legacyAddon)
    let returnValue = 'success'
    if(deleteResponse.success) {
      // retrieve the scores for the leaderboard, the deleted score shoudl be removed from the array
      const updatedScores = await getScoresInLeaderboard(MAX_SCORES, props.ldName)
    } else {
      returnValue = 'error'
    }
    setShowDeleteScoreModal(false)
    return returnValue
  }

  const hideMetadataModal = () => {
    setShowScoreMetadataModal(false)
  }

  const openScoreMetadata = (scoreId) => {
    console.log("Opening score metadata modal for score: ", scoreId)
    const scoreWithMD = ldState.legacyAddon ? 
      ldState.scores.find(score => score.score_id === scoreId) :
      ldState.scores.find(score => score.sid === scoreId)
    console.log("Found score: ")
    console.log(scoreWithMD)
    setMetadataForScore(scoreWithMD)
    setShowScoreMetadataModal(true)
  }

  // translate milliseconds to UTC date string
  const millisToDateString = (millis) => {
    const date = new Date(millis)
    const dateString = date.toUTCString()
    // get substring of dateString starting from 4th position
    const dateStringSubstring = dateString.substring(4)
    return dateStringSubstring
  }

  const getSubstringWithEllipsis = (string, maxLength) => {
    let substring = string
    if(string.length > maxLength) {
      substring = string.substring(0, maxLength) + '...'
    }
    return substring
  }

  // get string representation of object
  const objToString = (obj) => {
    let str = ''
    for(let key in obj) {
      str += `${key}: ${obj[key]}, `
    }
    return str.substring()
  }

  const renderNoScores = () => {
    console.log("renderNoScores, ldState: ")
    console.log(ldState)
    return <div className="ldboardScores">
    <div className="ldboardScoresRow">
      <div className="ldboardScoresElem noScores">
        <span className="subtitleLabel">No scores for this leaderboard</span>
      </div>
    </div>
  </div>
  }

  const renderLegacyScores = () => {
    return <div className="ldboardScores">
    <div className="ldboardScoresHeader">
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Player name</span>
      </div>
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Score</span>
      </div>
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Saved on</span>
      </div>
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Metadata</span>
      </div>
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Actions</span>
      </div>
    </div>
    <>{ldState.scores.map(score => 
      <div className="ldboardScoresRow" key={score.timestamp}>
        <div className="ldboardScoresElem">
          <span className="subtitleLabel">{score.player_name}</span>
        </div>
        <div className="ldboardScoresElem scoreElem">
          <span className="subtitleLabel">{score.score}</span>
        </div>
        <div className="ldboardScoresElem">
          <span className="subtitleLabel">{millisToDateString(score.timestamp)}</span>
        </div>
        <div className="ldboardScoresElem">
          <a href="#" onClick={() => openScoreMetadata(score.score_id)}>
            <span className="subtitleLabel">
              {score.metadata ? getSubstringWithEllipsis(objToString(score.metadata), 20) : ''}
            </span>
          </a>
        </div>
        <div className="ldboardScoresElem">
          <span className="actionItem" onClick={() => confirmDeleteScore(score.score_id, score.player_name, score.score)}>
            <img src="/img/dashboard/delete.png" alt="Delete player" title="Delete player" className="dashboardMainIcon" />
          </span>
        </div>
      </div>
    )}
    </>
    </div>
  }

  const renderScores = () => {
    return <div className="ldboardScores">
    <div className="ldboardScoresHeader">
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Player name</span>
      </div>
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Score</span>
      </div>
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Saved on</span>
      </div>
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Metadata</span>
      </div>
      <div className="ldboardScoresHeaderElem">
        <span className="subtitleLabel">Actions</span>
      </div>
    </div>
    <>{ldState.scores.map(score => 
      <div className="ldboardScoresRow" key={score.sid}>
          <div className="ldboardScoresElem">
            <span className="subtitleLabel">{score.pn}</span>
          </div>
          <div className="ldboardScoresElem scoreElem">
            <span className="subtitleLabel">{score.s}</span>
          </div>
          <div className="ldboardScoresElem">
            <span className="subtitleLabel">{millisToDateString(score.t)}</span>
          </div>
          <div className="ldboardScoresElem">
            <a href="#" onClick={() => openScoreMetadata(score.sid)}>
              <span className="subtitleLabel">
                {score.md ? getSubstringWithEllipsis(objToString(score.md), 20) : ''}
              </span>
            </a>
          </div>
          <div className="ldboardScoresElem">
            <span className="actionItem" onClick={() => confirmDeleteScore(score.sid, score.pn, score.s)}>
              <img src="/img/dashboard/delete.png" alt="Delete player" title="Delete player" className="dashboardMainIcon" />
            </span>
          </div>
      </div>)}
    </>
    { !ldState.legacyAddon && paginationKey ?
        <div className="moreScores">
          <p className="loadMore">
            <Button handleClick={() => getScoresInLeaderboard(MAX_SCORES, props.ldName)} text="Load more scores" loading={scoresLoading} />
          </p>
        </div> :
        null
    }
    </div>
  }

  return (
      <div className="ldboardElem" key={props.ldName}>
        <div className="elemName">
          {ldState.selected && (
              <i
              className="fa fa-solid fa-chevron-down"
              style={{ marginRight: "5px" }}
              onClick={() => closeLeaderboard()}
              />
          )}
          {!ldState.selected && (
              <i
              className="fa fa-solid fa-chevron-right"
              style={{ marginRight: "5px" }}
              onClick={() => openLeaderboard()}
              />
          )}
          {props.ldName}
        </div>
        {ldState.selected && (
        <div className="ldboardConfig">
          <div className="ldboardConfigCol">
            <div className="ldboardConfigElem">
              <span className="subtitleLabel">Display name:</span> {props.displayName ? props.displayName : props.ldName }
            </div>
            <div className="ldboardConfigElem">
              <span className="subtitleLabel">Save scores:</span> {props.insertOpt ? props.insertOpt : "n/a"}
            </div>
            <div className="ldboardConfigElem">
              <span className="subtitleLabel">Godot version:</span> {ldState.scores.length > 0 && ldState.legacyAddon ? "3" : ldState.scores.length > 0 ? "4" : "-"}
            </div>
          </div> 
          <div className="ldboardConfigCol">
            <div className="ldboardConfigElem">
              <span className="subtitleLabel">Resets:</span> {props.timeBased ? props.timeBased : "n/a"}
            </div>
            <div className="ldboardConfigElem">
              <span className="subtitleLabel">Timezone:</span> {props.timeZone ? props.timeZone : "n/a"}
            </div>
          </div>
        </div>)
        }
        {ldState.selected && (!ldState.scores || ldState.scores.length === 0) ?
          renderNoScores()
          : ldState.selected && ldState.scores ?
            ldState.legacyAddon ?
              renderLegacyScores() 
              : renderScores()
          : null
        }
        { scoreToBeDeleted !== null ?
          <ConfirmDeleteScore show={showDeleteScoreModal} onClose={hideDeleteScoreModal} 
                              onDelete={deleteScore} scoreId={scoreToBeDeleted.scoreId}>
            <p className="modalMessage">Are you sure you want to delete this score?</p>
            <p className="modalItem">
              <span className="modalLabel">Score id: </span>
              {scoreToBeDeleted.scoreId}
            </p>
            <p className="modalItem">
              <span className="modalLabel">Player name:</span> 
              {scoreToBeDeleted.playerName}
            </p>
            <p className="modalItem">
              <span className="modalLabel">Score value:</span> 
              {scoreToBeDeleted.scoreValue}
            </p>
          </ConfirmDeleteScore> :
          null }
          { metadataForScore === null ?
            null :
            ldState.legacyAddon ?
              <ScoreMetadata show={showScoreMetadataModal} onClose={hideMetadataModal} 
                            scoreId={metadataForScore.scoreId} metadata={metadataForScore.metadata} /> :
              <ScoreMetadata show={showScoreMetadataModal} onClose={hideMetadataModal} 
                            scoreId={metadataForScore.sid} metadata={metadataForScore.md} /> 
          }
      </div>
  )
}

export default LeaderboardElement


/**
 * 
 * 
 * 
 * 
 */
