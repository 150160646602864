import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useFormFields } from '../../libs/hooksLib';
import { useNavigate } from 'react-router-dom';
import Button from '../Utils/Controls/Button'
import './Auth.css';

const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ForgotPassword = props => {

  const [fields, handleFieldChange] = useFormFields({
    username: '',
    code: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [formState, setFormState] =  useState({
    isLoading: false,
    isConfirmationCodeLoading: false,
    emailSent: false,
    error: undefined
  });

  const navigate = useNavigate();

  const handleSubmit = async event => {
    event.preventDefault();

    if(!fields.username) {
      setFormState({
        ...formState,
        error: "Please enter your email address.",
        isLoading: false
      });
    } if(!validateEmail(fields.username)) {
      setFormState({
        ...formState,
        error: "Please enter a valid email address.",
        isLoading: false
      });
    } else {

      setFormState({
        ...formState,
        isLoading: true
      });

      Auth.forgotPassword(fields.username)
        .then(data => {
          console.log(data)
          setFormState({
            ...formState,
            emailSent: true,  
            isLoading: false
          });
        }).catch(err => {
          console.log(err)
          if(err.name === "UserNotFoundException") {
            //this.setState({ error: "We couldn't find a user with this email address.", isLoading: false })
            // the user is trying to reset password for a user that doesn't exist. This could be part of an enumeration attack.
            // We should not let the user know that the user doesn't exist.
            setFormState({
              ...formState,
              error: "We can't request a new password for this user. If this problem persists please contact us and we'll help you get this sorted.",
              isLoading: false
            });
          } else if(err.name === "LimitExceededException") {
            setFormState({
              ...formState,
              error: "You've requested a password change too often. Please try again later.",
              isLoading: false
            });
          } else {
            setFormState({
              ...formState,
              error: "Something wrong happened. If this problem persists please contact us and we'll help you get this sorted.",
              isLoading: false
            });
          }
        });
      }
  };

  const handleConfirmationSubmit = async event => {
    event.preventDefault();

    if(!fields.code) {
      setFormState({
        ...formState,
        error: "Please enter your confirmation code.",
        isConfirmationCodeLoading: false
      });
    } else if(fields.newPassword && fields.newPassword !== fields.confirmPassword) {
      setFormState({
        ...formState,
        error: "The two new passwords you entered don't match.",
        isConfirmationCodeLoading: false
      });
    } else {

      setFormState({
        ...formState,
        isConfirmationCodeLoading: true
      })

      Auth.forgotPasswordSubmit(fields.username, fields.code, fields.newPassword)
        .then(data => {
          console.log(data)
          setFormState({
            ...formState,
            emailSent: false,
            isConfirmationCodeLoading: false
          });
          navigate("/auth");
        }).catch(err => {
          console.log(err)
          if(err.name === "CodeMismatchException") {
            setFormState({
              ...formState,
              error: "The confirmation code you entered is incorrect. Please try again.",
              isConfirmationCodeLoading: false
            });
          } else if(err.name === "LimitExceededException") {
            setFormState({
              ...formState,
              error: "You've requested a password change too often. Please try again later.",
              isConfirmationCodeLoading: false
            });
          } else if(err.name === "InvalidPasswordException") {
            setFormState({
              ...formState,
              error: "The password you entered is invalid. Please try again.",
              isConfirmationCodeLoading: false
            });
          } else {
            setFormState({
              ...formState,
              error: "Something wrong happened. If this problem persists please contact us and we'll help you get this sorted.",
              isConfirmationCodeLoading: false
            });
          }
        });
    }
  };

  const renderForgotPwdForm = () => {
    return (
      <div className="basicContainer forgotPasswordContainer">
          <h3 className="miniTitle forgotPwdTitle">
            Forgot password? Enter your email address below:
          </h3>
          <div className="forgotPwdForm">
            <div className="row right-margined">
              <label className="label" htmlFor="username">Email address:</label>
              <input className="field" id="username" type="text" placeholder="email@example.com" value={fields.username} onChange={handleFieldChange} required />
            </div>
            <div className="exceptions">
              {formState.error}
            </div>
            <div class="centered">
              <Button handleClick={handleSubmit} text="Submit" loading={formState.isLoading} />
            </div>
          </div>
      </div>
    )
  }

  const renderForgotPwdConfirmation = () => {
    return (
      <div className="basicContainer forgotPasswordContainer">
          <h3 className="centered">
            If the email address exists, we've sent a password reset email containing a code. Please enter it below.
          </h3>
          <div className="forgotPwdConfForm">
            {
              /** adding a hidden input field with autocomplete set to off.
              this is the only way to cancel the incorrect autofill on all browsers
              (otherwise they try to put the email address in the code field)*/
            }
            <input autoComplete="false" name="hidden" type="text" className="hidden" />
            <div className="row">
              <label className="label" htmlFor="code">Confirmation code:</label>
              <input className="field" id="code" name="code" type="tel" value={fields.code} onChange={handleFieldChange} autoComplete="off" required />
            </div>
            <div className="row">
              <label className="label" htmlFor="newPassword">Enter new password:</label>
              <input className="field" type="password" id="newPassword" value={fields.newPassword} onChange={handleFieldChange} autoComplete="off" required />
            </div>
            <div className="row">
              <label className="label" htmlFor="confirmPassword">Confirm new password:</label>
              <input className="field" type="password" id="confirmPassword" value={fields.confirmPassword} onChange={handleFieldChange} autoComplete="off" required />
            </div>
            <div className="exceptions">
              {formState.error}
            </div>
            <div class="centered">
              <Button handleClick={handleConfirmationSubmit} text="Submit" loading={formState.isConfirmationCodeLoading} />
            </div>
          </div>
      </div>
    )
  }

  return (
    <div className="ForgotPassword">
      {!formState.emailSent
        ? renderForgotPwdForm()
        : renderForgotPwdConfirmation()}
    </div>
  )
}

export default ForgotPassword