import React, { useState } from "react";
import { Link } from 'react-router-dom'
import HeaderSignedIn from './HeaderSignedIn';
import { useAppContext } from "../../libs/contextLib";
import "./Header.css";

const Header = props => {

  const { isAuthenticated, userSession } = useAppContext()

  const [mobileMenuToggled, setMobileMenuToggled] = useState(false)

  const homeUrl = isAuthenticated ? "/features" : "/homepage.html"

  return(
    <div className="navGlobal">
        { !mobileMenuToggled ?
        <nav id="navbar">
            <ul className="navList">
                <li className="navItem itemLogo">
                    <a href={homeUrl}>
                        <img src={process.env.PUBLIC_URL + "/logo/logo_with_name.png"} width="320" height="61" alt="SilentWolf Logo" />
                    </a>
                </li>
                <li className="navItem itemLogoMobile">
                    <a href={homeUrl}>
                        <img src={process.env.PUBLIC_URL + "/logo/sw_logo.svg"} width="61" height="61" alt="SilentWolf Logo" />
                    </a>
                </li>
                { isAuthenticated ?
                <HeaderSignedIn {...props} userSession={userSession}  /> :
                <>
                    <li className="navItem featureItem">
                        <Link to="/leaderboard">
                            Leaderboards
                        </Link>
                    </li>
                    <li className="navItem featureItem">
                        <Link to="/playerauth">
                            Player accounts
                        </Link>
                    </li>
                    <li className="navItem featureItem">
                        <Link to="/playerdata">
                            Player Data
                        </Link>
                    </li>
                    <li className="navItem contactItem">
                        <Link to="/contact">
                            Contact
                        </Link>
                    </li>
                    <li className="navItem downloadItem">
                        <Link to="/download">
                            Download
                        </Link>
                    </li>
                    <li className="navItem signInItem">
                        <Link to="/auth">
                            <button className="btnPrimary" id="navGetStartedMobile">
                                Sign in
                            </button>
                        </Link>
                    </li> 
                </>
                }
                <li className="navItem hamburgerItem" id="mobileHamburger" onClick={e => setMobileMenuToggled(true)}>
                    <img src="./img/nav/hamburger.svg" alt="Open SilentWolf mobile menu" />
                </li> 
            </ul>
        </nav> :
        <div className="mobileNav" id="mobileNav">
            <div className="mobileNavHeader">
                <ul className="mobileNavList">
                    <li className="mobileNavItem itemLogoMobile">
                        <a href={homeUrl}>
                            <img src="./logo/sw_logo.svg" width="61" height="61" alt="SilentWolf Logo" />
                        </a>
                    </li>
                    <li className="mobileNavItem crossItem" id="mobileCross" onClick={e => setMobileMenuToggled(false)}>
                        <img src="./img/nav/cross.svg" alt="Close SilentWolf mobile menu" />
                    </li>
                </ul>
            </div>
            <div className="mobileNavContent">
                <ul className="mobileContentNavList">
                    <li className="mobileContentNavItem itemLogoMobile">
                        <a href={homeUrl}>
                            <img src="./logo/sw_logo_white_with_text.png" width="200" alt="SilentWolf Logo" />
                        </a>
                    </li>
                    <li className="mobileContentNavItem featureItem">
                        <Link to="/leaderboard">
                            Leaderboards
                        </Link>
                    </li>
                    <li className="mobileContentNavItem featureItem">
                        <Link to="/playerauth">
                            Player accounts
                        </Link>
                    </li>
                    <li className="mobileContentNavItem featureItem">
                        <Link to="/playerdata">
                            Player Data
                        </Link>
                    </li>
                    <li className="mobileContentNavItem contactItem">
                        <Link to="/contact">
                            Contact
                        </Link>
                    </li>
                    
                    { isAuthenticated ?
                    <HeaderSignedIn {...props} userSession={userSession} mobile="true" /> :
                    <>
                        <li className="mobileContentNavItem downloadItem">
                            <Link to="/download">
                                Download
                            </Link>
                        </li>
                        <li className="mobileContentNavItem signInItem">
                            <button className="btnPrimary" id="navGetStarted">
                                Sign in
                            </button>
                        </li>
                    </>
                    }
                </ul>
            </div>
        </div> }
    </div>)
}

export default Header