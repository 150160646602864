import React from 'react';

const TextInputField = ({ label, name, id, placeholder, defaultValue, onChange }) => (
  <div className="textInputField">
    <label className="inputFieldLabel" htmlFor={id}>
      {label}
    </label>
    <input
      type="text"
      name={name}
      id={id}
      placeholder={placeholder}
      value={defaultValue}
      onChange={onChange}
      className="inputFieldTextInput"
    />
  </div>
);

export default TextInputField;
