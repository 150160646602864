import React from 'react';
import Login from "./Login"
import Signup from "./Signup"
import "./Auth.css";

const LoginSignup = props =>  {

  return(
    <div className="signinContainer">
      <Login {...props} />
      <Signup {...props} />
    </div>
  )
}

export default LoginSignup
