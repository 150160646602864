import React from 'react';
import { Link } from 'react-router-dom';

const CSharp = props => {

  return (
    <div className="CallingSW">
      <div className="basicContainer">
        <h1>Calling SilentWolf in C#</h1>
        <div className="explainer basicExplainer">
          SilentWolf is a plugin for Godot engine that is built using GDScript. However, C# developers can also use the plugin by using Godot's native <a href="https://docs.godotengine.org/en/stable/tutorials/scripting/cross_language_scripting.html" target="_blank">cross-language scripting</a> features.
        </div>
        <h2 className="titleShort">Configuring SilentWolf using C#</h2>
        <div className="explainer basicExplainer">
          When using SilentWolf in a C# Godot project, the initial steps are the same as for a game using GDScript. <a href="/download">Download</a> the plugin and unzip it into your 'addons' folder at the root of your project.
        </div>
        <div className="explainer basicExplainer">
          The next step is to configure your game. You can do so by adapting the following code in an autoloaded singleton (e.g. your Global.cs file):
        </div>
        <div>
          Towards the top of your script, declare the SilentWolf singleton:
        </div>
        <div>
          Then in your _Ready() function add:
        </div>
        <pre className="text-left">{`
    var config = new Godot.Collections.Dictionary();
    config.Add("api_key", "YOUR_API_KEY");
    config.Add("game_id", "YOUR_GAME_ID");
    config.Add("game_version", "1.0.0");
    config.Add("log_level", 1);

    SilentWolf = GetNode<Node>("/root/SilentWolf");
    SilentWolf.Call("configure", config);
        `}</pre>
        <h2 className="titleShort">Calling SilentWolf functions</h2>
        <div className="explainer basicExplainer">
          Once your game is configured you can start calling SilentWOlf backend functions. In the example below we're going to use the scores/leaderboard functions but you can call other SilentWolf functions in the same way.
        </div>
        <div className="explainer basicExplainer">
          In a script within your game, start by declaring the Scores node and associated script that you'll be calling functions on:
        </div>
        <pre className="text-left">{`
    public static GDScript SWScoresScript;
    public static Node SilentWolfScores;
        `}</pre>
        <div className="explainer basicExplainer">
          Then (typically in your script's _ready() function), copy the following:
        </div>
        <pre className="text-left">{`
    SWScoresScript = (GDScript) GD.Load("res://silent_wolf/Scores/Scores.gd");
    SilentWolfScores =  (Godot.Node) SWScoresScript.New();
    AddChild( (Godot.Node) SilentWolfScores);
        `}</pre>
        <div className="explainer basicExplainer">
          You can now easily call functions on the new Node, using the proper syntax to pass arguments where relevant:
        </div>
        <pre className="text-left">{`
    SilentWolfScores.Call("get_high_scores");
    SilentWolfScores.Call("persist_score", player_name, score_value);
        `}</pre>
        <h2 className="titleShort">Connecting a signal</h2>
        <div className="explainer basicExplainer">
          All SilentWolf functions emit signals. You can connect to them in C# just like you would in GDScript. In your script's _Ready() function:
        </div>
        <pre className="text-left">{`
    SilentWolfScores.Connect("sw_scores_received", this, "_ScoresReceivedCallback");
          `}
        </pre>
        <div className="explainer basicExplainer">
          And then write your callback to detail what to do when the signal is emitted:
        </div>
        <pre className="text-left">{`
    public _ScoresReceivedCallback()
    {
      GD.Print("Received scores from SilentWolf: " + SilentWolfScores.Get("scores"));
    }
          `}</pre>
        <h2 className="titleShort">Contact us</h2>
        <div className="explainer basicExplainer">
          If you have trouble getting SilentWolf to work in your C# game, <Link to="/contact">let us know</Link>.
        </div>
      </div>
    </div>
  )
}

export default CSharp
