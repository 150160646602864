import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../../../libs/contextLib'
import './../Main.css'
import './Multiplayer.css'

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = '2WQMRe392f2T9bOxVogxh8z9ROJ0qWmt2O1aKiNF'

const Multiplayer = props => {

  const { isAuthenticated, userSession } = useAppContext()
  //const [game, setGame] = useState(userSession.games[0])

  useEffect(() => {
    //setGame(userSession.games[0])
  }, [userSession.gameId]);

  const sendGetRequest = async (urlPath) => {
    const apiUrl = `${baseUrl}${urlPath}`
    console.log('apiUrl: ')
    console.log(apiUrl)
    const results = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'x-api-key': apiKey
      }
    })
    const jsonResponse = results.json()
    console.log('results: ')
    console.log(jsonResponse)
    return jsonResponse
  }

  const sendPostRequest = async (urlPath, json) => {
    const apiUrl = `${baseUrl}${urlPath}`
    const results = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
        'x-sw-plugin-version': '0.6.8'
      },
      body: JSON.stringify(json)
    })
    return results.json()
  }

  return (
    <div className="main">
      <div className="comingSoon">
        Coming soon!
      </div>
    </div>
  )
}

export default Multiplayer
