import React, { useState, useEffect } from 'react';
import TextInputField from '../Utils/TextInputField'
import TextArea from '../Utils/TextArea'

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = 'FmKF4gtm0Z2RbUAEU62kZ2OZoYLj4PNXa5YRIBb0'

const sanitizeCustomEmailBody = (body) => {
  return body.replace(/{####}/g, '@@@@').replace(/<br>/g, '\n')
}

const isEmpty = (testString) => {
  return (testString === undefined || testString === "")
}

const sendPostRequest = async (urlPath, json) => {
  const apiUrl = `${baseUrl}${urlPath}`
  const results = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(json)
  })
  return results.json()
}

const CustomEmailSettings = props => {

  const [gameName, setGameName] = useState(props.games ? props.games[0].name : props.gameName)
  const [emailSubject, setEmailSubject] = useState(props.games && !isEmpty(props.games[0].cust_email_subj) ? props.games[0].cust_email_subj : `Welcome to ${gameName}! Please confirm your email address.`)
  const [emailBody, setEmailBody] = useState(props.games && !isEmpty(props.games[0].cust_email_body) ? sanitizeCustomEmailBody(props.games[0].cust_email_body) : `Welcome to ${gameName}. \n\n Your account is almost ready. \n\n Please return to the game and type in the following code: \n\n @@@@ \n\nThanks in advance, \nthe ${gameName} Team`)
  const [resetPwdEmailSubject, setResetPwdEmailSubject] = useState(props.games && !isEmpty(props.games[0].cust_resetpwd_email_subj) ? props.games[0].cust_resetpwd_email_subj : `Reset your password for ${gameName}`)
  const [resetPwdEmailBody, setResetPwdEmailBody] = useState(props.games && !isEmpty(props.games[0].cust_resetpwd_email_body) ? sanitizeCustomEmailBody(props.games[0].cust_resetpwd_email_body) : `To reset your password in ${gameName}, please enter the following code in the game: \n\n @@@@ \n\nThanks,\nThe ${gameName} Team`)
  const [replyToEmail, setReplyToEmail] = useState(props.games && !isEmpty(props.games[0].cust_reply_email) ? props.games[0].cust_reply_email : props.username)
  const [customEmailFormSubmitted, setCustomEmailFormSubmitted] = useState(false)
  const [customEmailFormSubmittedError, setCustomEmailFormSubmittedError] = useState(false)
  const [customEmailFormSubmittedErrorMessage, setCustomEmailFormSubmittedErrorMessage] = useState("")

  useEffect(() => {
    if(props.games) {
      console.log("Game name:")
      console.log(props.games[0].name)
      const gameName = props.games[0].name
      setGameName(gameName)
      setEmailSubject(!isEmpty(props.games[0].cust_email_subj) ? props.games[0].cust_email_subj : `Welcome to ${gameName}! Please confirm your email address.`)
      setEmailBody(!isEmpty(props.games[0].cust_email_body) ? sanitizeCustomEmailBody(props.games[0].cust_email_body) : `Welcome to ${gameName}. \n\n Your account is almost ready. \n\n Please return to the game and type in the following code: \n\n @@@@ \n\nThanks in advance, \nthe ${gameName} Team`)
      setResetPwdEmailSubject(!isEmpty(props.games[0].cust_resetpwd_email_subj) ? props.games[0].cust_resetpwd_email_subj : `Reset your password for ${gameName}`)
      setResetPwdEmailBody(!isEmpty(props.games[0].cust_resetpwd_email_body) ? sanitizeCustomEmailBody(props.games[0].cust_resetpwd_email_body) : `To reset your password in ${gameName}, please enter the following code in the game: \n\n @@@@ \n\nThanks,\nThe ${gameName} Team`)
      console.log("Reset password email subject:")
      console.log(!isEmpty(props.games[0].cust_resetpwd_email_subj) ? props.games[0].cust_resetpwd_email_subj : `Reset your password for ${gameName}`)
      setReplyToEmail(!isEmpty(props.games[0].cust_reply_email) ? props.games[0].cust_reply_email : props.username)
    } else {
      setGameName(props.gameName)
      setEmailSubject(`Welcome to ${props.gameName}! Please confirm your email address.`)
      setEmailBody(`Welcome to ${props.gameName}. \n\n Your account is almost ready. \n\n Please return to the game and type in the following code: \n\n @@@@ \n\nThanks in advance, \nthe ${props.gameName} Team`)
      setResetPwdEmailSubject(`Reset your password for ${props.gameName}`)
      setResetPwdEmailBody(`To reset your password in ${props.gameName}, please enter the following code in the game: \n\n @@@@ \n\nThanks,\nThe ${props.gameName} Team`)
      setReplyToEmail(props.username)
    }
  }, [props.gameId]);

  const submitCustomEmailSettings = async () => {
    const path = "update_player_auth_settings"
    console.log('replyToEmail:')
    console.log(replyToEmail)
    const json = {
      'game_id': props.gameId,
      'custom_email_subject': emailSubject,
      'custom_email_body': emailBody,
      'custom_resetpwd_email_subject': resetPwdEmailSubject,
      'custom_resetpwd_email_body': resetPwdEmailBody,
      'custom_reply_email': replyToEmail
    }
    const jsonResults = await sendPostRequest(path, json)
    if(jsonResults.hasOwnProperty('error')) {
      setCustomEmailFormSubmittedError(true)
      setCustomEmailFormSubmittedErrorMessage(jsonResults.error)
    } else {
      setCustomEmailFormSubmittedError(false)
      setCustomEmailFormSubmitted(true)
    }
  }

  return (
    <div className="customEmailSettings">
      <h3 className="titleShort">Customize sign up emails</h3>
      <TextInputField className="longTextInput" label={"Player sign up subject:"} name={"emailSubject"} id={"emailSubject"} defaultValue={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} />
      <TextArea className="emailContentInput" rows="12" label={"Player sign up contents:"} name={"emailBody"} id={"emailBody"} defaultValue={emailBody} onChange={(e) => setEmailBody(e.target.value)} />
      <hr />
      <h3 className="titleShort">Customize reset password emails</h3>
      <TextInputField className="longTextInput" label={"Reset password subject:"} name={"resetPwdEmailSubject"} id={"resetPwdEmailSubject"} defaultValue={resetPwdEmailSubject} onChange={(e) => setResetPwdEmailSubject(e.target.value)} />
      <TextArea className="emailContentInput" rows="10" label={"Reset password contents:"} name={"resetPwdEmailBody"} id={"resetPwdEmailBody"} defaultValue={resetPwdEmailBody} onChange={(e) => setResetPwdEmailBody(e.target.value)} />
      <div className="explainer">
        If you intend to customize the above email contents (for player sign up and/or reset password), make sure to keep the '@@@@' placeholder for the one-time verification code that will be inserted into the email for you.
      </div>
      <div className="explainer">
        The emails will be sent by "{gameName} {"<emailverif@silentwolf.com>"}" but you can customize the reply-to email:
      </div>

      <TextInputField className="longTextInput" label={"Select reply-to email:"} name={"replyToEmail"} id={"replyToEmail"} defaultValue={replyToEmail} onChange={(e) => setReplyToEmail(e.target.value)} />
      <div class="centered">
        <button className="customEmailSettingsSubmit" onClick={submitCustomEmailSettings}>Submit custom email settings</button>
      </div>
      { customEmailFormSubmittedError ?
        <div className="explainer exceptions">
          There was an error saving your custom email data - {customEmailFormSubmittedErrorMessage}
        </div>
      : (customEmailFormSubmitted ?
        <div className="explainer successful">
          Your email confirmation settings were saved
        </div>
      : null) }
    </div>
  )
}

export default CustomEmailSettings;
