import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../../../libs/contextLib'
import PlayerElement  from './PlayerElement'
import PlayerDataModal from './Modal/PlayerDataModal'
import ConfirmDeletePlayer from './Modal/ConfirmDeletePlayer'
import ConfirmDisablePlayer from './Modal/ConfirmDisablePlayer'
import ConfirmEnablePlayer from './Modal/ConfirmEnablePlayer'
import ChangePlayerPassword from './Modal/ChangePlayerPassword'
import Button from '../../../Utils/Controls/Button'
import './../../Dashboard.css'
import './../Main.css'
import './Players.css'

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = '2WQMRe392f2T9bOxVogxh8z9ROJ0qWmt2O1aKiNF'

const Players = props => {

  const { isAuthenticated, userSession } = useAppContext()

  const [playersLoading, setPlayersLoading] = useState(true)
  const [players, setPlayers] = useState({})
  const [paginationKey, setPaginationKey] = useState(null)
  const [showPlayerDataModal, setShowPlayerDataModal] = useState({ show: false, playerWithData: null })
  const [showDeletePlayerModal, setShowDeletePlayerModal] = useState({ show: false, playerName: null })
  const [showDisablePlayerModal, setShowDisablePlayerModal] = useState({ show: false, playerName: null })
  const [showEnablePlayerModal, setShowEnablePlayerModal] = useState({ show: false, playerName: null })
  const [showChangePlayerPasswordModal, setShowChangePlayerPasswordModal] = useState({ show: false, playerName: null })
  const [allPlayerData, setAllPlayerData] = useState({})
  const [allPlayerDataLoading, setAllPlayerDataLoading] = useState(false)

  const statusHeaderClass = userSession && userSession.gameId && userSession.games[0].email_conf_enabled ? "dashboardListHeaderElem longElem" : "dashboardListHeaderElem shortElem"

  useEffect(() => {
    console.log('useEffect function, userSession.gameId = ')
    console.log(userSession.gameId)
    getPlayers()
  }, [userSession.gameId]);

  const sendGetRequest = async (urlPath) => {
    const apiUrl = `${baseUrl}${urlPath}`
    console.log('apiUrl: ')
    console.log(apiUrl)
    const results = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'x-api-key': apiKey
      }
    })
    const jsonResponse = results.json()
    console.log('results: ')
    console.log(jsonResponse)
    return jsonResponse
  }

  const sendPostRequest = async (urlPath, json) => {
    const apiUrl = `${baseUrl}${urlPath}`
    const results = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
        'x-sw-plugin-version': '0.6.8'
      },
      body: JSON.stringify(json)
    })
    return results.json()
  }

  function downloadJSON(jsonData) {
    const dataStr = JSON.stringify(jsonData, null, 2); // Format JSON with 2 space indentation
    const blob = new Blob([dataStr], { type: "application/json;charset=utf-8" });
    const url = URL.createObjectURL(blob);
  
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "playerdata.json";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  /**
  * We have several backend fucntions to choose from:
  * - get_players (much faster; gets only the data related to players with accounts)
  * - get_players_with_data (slower; gets data for players with accounts and their associated player data)
  * - get_all_players (slowest; this one gets a merged dataset of players with accounts and player data)
  * maybe we could check ahead of time if the game uses player data and/or player accounts
  * and based on this decide which function to call.
  * By default we should use get_players
  *
  * Ideally we should list both types of players without making a difference,
  * and easily enable to create accounts for those who don't have one
  * Or create player data for those who only have accounts
  *
  * TODO: we also need the Cognito data for:
  * - player creation timestamp (we should really have this in ddb)
  * - enabled/disabled status
  */
  const getPlayers = async () => {
    console.log('userSession:')
    console.log(userSession)
    const username = userSession.username
    let paginationKeySuffix = ''
    // If we know it we might as well add the user pool id to the request
    // (otherwise the backend will retrieve it from ddb)
    // We can also remove the AWS region (us-east_1_) from the poolId as it's always the same
    /**if(userSession && userSession.games.length > 0 && 'pool_id' in userSession.games[0]) {
      suffix = `?pId=${userSession.games[0].pool_id.substring(10)}`
    }*/
    if(paginationKey !== null) {
      // PaginationKey is made up of pid, co and gid (where co is an int, pi is a string and gid is already sent down in the path params)
      // we could send this info as 3 separate params
      const pid = paginationKey.pid
      const co = paginationKey.co
      paginationKeySuffix = `?pid=${pid}&co=${co}`
    }
    if(username && userSession.gameId) {
      const urlPath = `get_all_players/${userSession.gameId}${paginationKeySuffix}`
      const jsonResponse = await sendGetRequest(urlPath)
      if(jsonResponse.success) {
        // Create an object where the keys are the player names and the values are the players
        let newPlayers = players
        jsonResponse.players.forEach(player => {
          newPlayers[player.pn] = player
        })
        const paginationKey = jsonResponse.pagination_key
        setPlayers(newPlayers)
        if(jsonResponse.hasOwnProperty('pagination_key')) {
          setPaginationKey(jsonResponse.pagination_key)
        } else {
          setPaginationKey(null)
        }
      }
      setPlayersLoading(false)
    }
  }

  // remove player from the players object
  const removePlayer = (playerName) => {
    const newPlayers = {...players}
    delete newPlayers[playerName]
    setPlayers(newPlayers)
  }

  const updatePlayer = (playerName, enabled) => {
    // from the playerd object, get the value corresponding to the player name
    let newPlayer = players[playerName]
    //let newPlayer = players.find(player => player.player_name === playerName)
    newPlayer.en = enabled
    // update the player in the players object

    setPlayers({
      ...players,
      [playerName]: newPlayer
    })
  }

  const getAllPlayerData = async () => {
    let returnValue = false
    if(userSession.username) {
      setAllPlayerDataLoading(true)
      const urlPath = `get_all_player_data/${userSession.gameId}`
      const jsonResponse = await sendGetRequest(urlPath)
      returnValue = jsonResponse.success
      setAllPlayerData(jsonResponse.player_data)
      setAllPlayerDataLoading(false)
    }
    return returnValue
  }

  const disablePlayerAccount = async (playerName) => {
    let returnValue = false
    if(userSession.username) {
      const path = "disable_player"
      const jsonPlayer = {
          "game_id": userSession.gameId,
          "username": playerName
      }
      const jsonResults = await sendPostRequest(path, jsonPlayer)
      returnValue = jsonResults.success
      hideDisablePlayerModal()
      updatePlayer(playerName, false)
    }
    return returnValue
  }

  const enablePlayerAccount = async (playerName) => {
    let returnValue = false
    if(userSession.username) {
      const path = "enable_player"
      const jsonPlayer = {
          "game_id": userSession.gameId,
          "username": playerName
      }
      const jsonResults = await sendPostRequest(path, jsonPlayer)
      returnValue = jsonResults.success
      hideEnablePlayerModal()
      updatePlayer(playerName, true)
    }
    return returnValue
  }

  const deletePlayerAccount = async (playerName) => {
    let returnValue = false
    if(userSession.username) {
      const path = "delete_player"
      const jsonPlayer = {
          "game_id": userSession.gameId,
          "player_name": playerName
      }
      const jsonResults = await sendPostRequest(path, jsonPlayer)
      returnValue = jsonResults.success
      hideDeletePlayerModal()
      removePlayer(playerName)
    }
    return returnValue
  }

  const createPlayer = async (player_name, email, password) => {
    let returnValue = false
    if(userSession.username) {
      const path = "create_new_player"
      const jsonPlayer = {
          "game_id": userSession.gameId,
          "player_name": player_name,
          "email": email,
          "password": password,
          "confirm_password": password
      }
      const jsonResults = await sendPostRequest(path, jsonPlayer)
      returnValue = jsonResults.success
    }
    return returnValue
  }

  const resetPlayerPassword = async (playerName, password) => {
    let returnValue = {
      "success": false,
      "error": "Please sign in before changing a user's password."
    }
    if(userSession.username) {
      const path = "admin_set_player_password"
      const jsonPlayer = {
          "game_id": userSession.gameId,
          "player_name": playerName,
          "password": password
      }
      const jsonResults = await sendPostRequest(path, jsonPlayer)
      returnValue = jsonResults
    }
    return returnValue
  }

  const getPlayerData = async (playerName) => {
    const username = userSession.username
    if(username) {
      const urlPath = `get_player_data/${userSession.gameId}/${playerName}`
      const jsonResponse = await sendGetRequest(urlPath)
    }
  }

  const setPlayerData = async (gameVersion, playerName, newPlayerData) => {
    let returnValue = false
    const username = userSession.username
    if(username) {
      const urlPath = `push_player_data`
      const jsonPlayer = {
          "game_id": userSession.gameId,
          "game_version": gameVersion,
          "player_name": playerName,
          "player_data": newPlayerData,
          "overwrite": true
      }
      const jsonResults = await sendPostRequest(urlPath, jsonPlayer)
      returnValue = jsonResults.update_done
    }
    return returnValue
  }

  const deletePlayerData = async (gameVersion, playerName, characterName) => {
    let returnValue = false
    console.log(`Pushing player data for player: ${playerName}`)
    const username = userSession.username
    if(username) {
      const urlPath = `remove_player_data`
      const jsonPlayerData = {
          "game_id": userSession.gameId,
          "player_name": playerName,
          "character_name": characterName,
          "player_data": ""
      }
      const jsonResults = await sendPostRequest(urlPath, jsonPlayerData)
      returnValue = jsonResults.update_done
    }
    return returnValue
  }

  const renderLoaderSpinner = () => {
    return (<div className="loaderSpinner">
        <i
        className="fa fa-refresh fa-spin fa-3x"
        style={{ marginRight: "0px" }}
        />
    </div>)
  }

  /**
  *
  * PROBLEM: how do we display data for players who have multiple instances of
  * player data (one for each character they have in a game), either linked to
  * a single account or no account at all?
  *
  **/
   const renderPlayer = (player) => {
    return  <PlayerElement key={player.pid}
                          player={player}
                          setShowPlayerDataModal={setShowPlayerDataModal}
                          setShowDeletePlayerModal={setShowDeletePlayerModal}
                          setShowDisablePlayerModal={setShowDisablePlayerModal}
                          setShowEnablePlayerModal={setShowEnablePlayerModal}
                          setShowChangePlayerPasswordModal={setShowChangePlayerPasswordModal}
                          emailConfEnabled ={userSession && userSession.gameId ? userSession.games[0].email_conf_enabled : false}
                          />
  }

  const hidePlayerDataModal = () => {
    setShowPlayerDataModal({show: false, playerWithData: null })
  }

  const hideDeletePlayerModal = () => {
    setShowDeletePlayerModal({show: false, playerName: null })
  }

  const hideDisablePlayerModal = () => {
    setShowDisablePlayerModal({show: false, playerName: null })
  }

  const hideEnablePlayerModal = () => {
    setShowEnablePlayerModal({show: false, playerName: null })
  }

  const hideChangePlayerPasswordModal = () => {
    setShowChangePlayerPasswordModal({show: false, playerName: null })
  }

  const renderPlayers = (players) => {
    return Object.keys(players).length === 0 ?
      <div className="noContent">
        No players yet!
      </div> :
      <div className="dashboardList playerList">
        <div className="dashboardListHeader">
          <div className="dashboardListHeaderElem mediumElem">
            <span className="subtitleLabel">Player name</span>
          </div>
          <div className="dashboardListHeaderElem mediumElem">
            <span className="subtitleLabel">Email address</span>
          </div>
          <div className={statusHeaderClass}>
            <span className="subtitleLabel">Status</span>
          </div>
          <div className="dashboardListHeaderElem mediumElem">
            <span className="subtitleLabel">Created on</span>
          </div>
          <div className="dashboardListHeaderElem shortElem">
            <span className="subtitleLabel">Player data</span>
          </div>
          <div className="dashboardListHeaderElem veryShortElem">
            <span className="subtitleLabel">Actions</span>
          </div>
        </div>
        { Object.values(players).map(renderPlayer) }
        { showPlayerDataModal.show ?
            <PlayerDataModal show={showPlayerDataModal.show} onClose={hidePlayerDataModal}
                           playerWithData={showPlayerDataModal.playerWithData} /> :
            null
        }
        { showDeletePlayerModal.show ?
            <ConfirmDeletePlayer show={showDeletePlayerModal.show} onClose={hideDeletePlayerModal}
                           playerName={showDeletePlayerModal.playerName} onDelete={deletePlayerAccount}>
              <p className="modalMessage">Are you sure you want to delete this player? No turning back!</p>
              <p className="modalItem">
                They will no longer be able to log into your game. 
              </p>
              <p className="modalItem">
                This will also delete any saved sessions associated with this player.
              </p>
              <p className="modalItem">
                <span className="modalLabel">Player name:</span>
                {showDeletePlayerModal.playerName}
              </p>
            </ConfirmDeletePlayer> :
            null
        }
        { showDisablePlayerModal.show ?
            <ConfirmDisablePlayer show={showDisablePlayerModal.show} onClose={hideDisablePlayerModal}
                           playerName={showDisablePlayerModal.playerName} onDisable={disablePlayerAccount}>
              <p className="modalMessage">Are you sure you want to disable this player? </p>
              <p className="modalItem">
                They will no longer be able to log into your game (unless you re-enable them). 
              </p>
              <p className="modalItem">
                This will also delete any saved sessions associated with this player.
              </p>
              <p className="modalItem">
                <span className="modalLabel">Player name:</span>
                {showDisablePlayerModal.playerName}
              </p>
            </ConfirmDisablePlayer> :
            null
        }
        { showEnablePlayerModal.show ?
            <ConfirmEnablePlayer show={showEnablePlayerModal.show} onClose={hideEnablePlayerModal}
                           playerName={showEnablePlayerModal.playerName} onEnable={enablePlayerAccount}>
              <p className="modalMessage">Are you sure you want to enable this player? </p>
              <p className="modalItem">
                Once enabled, they will be allowed to log into your game.
              </p>
              <p className="modalItem">
                <span className="modalLabel">Player name:</span>
                {showEnablePlayerModal.playerName}
              </p>
            </ConfirmEnablePlayer> :
            null
        }
        { showChangePlayerPasswordModal.show ?
            <ChangePlayerPassword show={showChangePlayerPasswordModal.show}
                          onClose={hideChangePlayerPasswordModal}
                          playerName={showChangePlayerPasswordModal.playerName}
                          onChangePassword={resetPlayerPassword} /> :
            null
        }
        { paginationKey ?
        <div className="morePlayers">
          <p className="loadMore">
            <Button handleClick={getPlayers} text="Load more players" loading={playersLoading} />
          </p>
        </div> :
        null
        }
      </div>
  }

  return (
    <div className="main">
      <div className="players">
        <h2 className="playersTitle">Players:</h2>
          {
            playersLoading ?
            renderLoaderSpinner() :
            renderPlayers(players)
          }
      </div>
      <div className="playerDataDownload">
      {
        Object.keys(allPlayerData).length > 0 ? 
        <Button handleClick={() => downloadJSON(allPlayerData)} text="Download player data as JSON" /> :
        <Button handleClick={getAllPlayerData} text="Fetch all player data for game (experimental)" loading={allPlayerDataLoading} />
      }
      </div>
    </div>
  )
}

export default Players
