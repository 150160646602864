import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useAppContext } from '../../libs/contextLib'
import { useFormFields } from "../../libs/hooksLib";
import IntroText from './IntroText'
import NextSteps from './NextSteps'
import ShowApiKey from '../Utils/ShowApiKey'
import Button from '../Utils/Controls/Button'
import "./Leaderboard.css";

const Leaderboard = props => {

  const { isAuthenticated, userSession } = useAppContext()

  const [formState, setFormState] = useState({
                                              apiKeyCreated: undefined,
                                              gameId: undefined,
                                              isLoading: false
                                            })

  const [fields, handleFieldChange] = useFormFields({
    gameName: ''
  });

  const createAPIkey = async event => {
    event.preventDefault();

    setFormState({
      ...formState,
      isLoading: true
    })

    const createAPIKeyUrl = "https://api.silentwolf.com/create_api_key"
    const usernameGameName = userSession.username + ";" + fields.gameName
    //console.log("Creating API key for: " + usernameGameName)
    await fetch(createAPIKeyUrl + "/" + usernameGameName).then( results => {
        console.log("Created API key for: " + usernameGameName)
        return results.json()
      }).then( json_obj => {
        console.log("api key created: " + json_obj["api_key"])
        console.log("game created: " + json_obj["game_id"])
        setFormState({
          apiKeyCreated: json_obj["api_key"],
          gameId: json_obj["game_id"],
          isLoading: false
        })
      })
  }

  const unauthRender = () => {
    return(
      <div className="basicContainer featureContainer">
        <IntroText />
        <h2 className="titleShort">Get started in under a minute</h2>
        <div className="explainer">
          Getting started is quick and easy with SilentWolf. Sign up and create an API key and Game id. Then download, install and configure the plugin. Your leaderboard can be ready in minutes.
        </div>
        <div className="explainer">
          If you have any issues during installation, don't hesitate to <Link to="/contact">contact us</Link>.
        </div>
        <div className="explainer">
          <Link to="/auth">
            <button>Login or create an account</button>
          </Link>
        </div>
      </div>
    )
  }

  const renderApiKeyFormSubmitted = () => {
    return(
      <div className="basicContainer leaderboardContainer">
        <h1>Your API key has been created:</h1>
        <pre>{formState.apiKeyCreated}</pre>
        <div>And we've also created an identifier for your game {fields.gameName}:</div>
        <pre>{formState.gameId}</pre>
        <div>Please take note of your API key and your game id, you will need to use them to access SilentWolf backend services.</div>
        <NextSteps username={userSession.username} gameId={formState.gameId} />
      </div>
    )
  }

  const renderApiKeyFormInit = () => {
    return(
      <div className="basicContainer leaderboardContainer">
        <IntroText />
        <h2 className="titleShort">Getting started with SilentWolf</h2>
        <div className="explainer">Use the form below to generate your API key:</div>
        <div>
          <div className="row">
            Name of your game: <input type="text"
                                      id="gameName"
                                      className="field"
                                      placeholder=""
                                      value={fields.gameName}
                                      onChange={handleFieldChange}
                                      required />
          </div>
          <Button handleClick={createAPIkey} text="Submit" loading={formState.isLoading} />
        </div>
      </div>
    )
  }

  const renderApiKeyForm = () => {
    return(
      <div>
        {!formState.apiKeyCreated
          ? renderApiKeyFormInit()
          : renderApiKeyFormSubmitted()}
      </div>
    )
  }

  const renderNoForm = () => {
    return(
      <div className="leaderboardContainer formContainer">
        <h2 className="titleShort">Use your API key to set up your Leaderboard</h2>
        <div className="explainer">You've previously set up an API key for your game. Follow the instructions below to set up your leaderboard for free.</div>
        <ShowApiKey {...props} />
        {/*<div><i>Lost your API key? <a href="mailto:support@silentwolf.com">Contact us</a> and we'll help you retrieve it.</i></div>*/}
        {/* TODO: list the game names and game ids linked to this API key */}
        <NextSteps username={userSession.username} games={userSession.games} gameId={userSession.gameId} />
      </div>
    )
  }

  const authRender = () => {
    return(
      <div>
        {!userSession.gameId
          ? renderApiKeyForm()
          : renderNoForm()}
      </div>
    )
  }

  return (
    <div className="Leaderboard">
      {!isAuthenticated
        ? unauthRender()
        : authRender()}
    </div>
  )
}

export default Leaderboard
