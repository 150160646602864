import React, { useState, useEffect} from 'react';
import LeaderboardConfigForm from './LeaderboardConfigForm'
import { useAppContext } from '../../libs/contextLib'
import "./Leaderboard.css";

const baseUrl = 'https://api.silentwolf.com/'
const apiKey = 'FmKF4gtm0Z2RbUAEU62kZ2OZoYLj4PNXa5YRIBb0'

const sendGetRequest = async (urlPath) => {
  const apiUrl = `${baseUrl}${urlPath}`
  const results = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey
    }
  })
  return results.json()
}

const LeaderboardConfig = props => {

  const { userSession } = useAppContext()

  const [leaderboards, setLeaderboards] = useState([]);

  useEffect(() => {
    const getLeaderboards = async (gameId) => {
      const path = "get_leaderboard_config/" + gameId
      const jsonResults = await sendGetRequest(path)
      console.log('LeaderboardConfig leaderboard config after useEffect:')
      console.log(jsonResults.leaderboards)
      setLeaderboards(jsonResults.leaderboards)
    }
    console.log('LeaderboardConfig leaderboard config before useEffect:')
    console.log(leaderboards)
    console.log(`new gameId: ${userSession.gameId}`)
    getLeaderboards(userSession.gameId)
  }, [userSession.gameId]);

  const renderLeaderboard = (item, index) => {
    console.log(`rerendering leaderboard config form for leaderboard: ${item.name}, display name: ${item.display_name}`)
    return <LeaderboardConfigForm name={item.ld_name}
      displayName={item.display_name} timeBased={item.time_based}
      timezone={item.timezone} insertOpt={item.insert_opt}
      maxEntries={item.max_entries} isNew={item.isNew} 
      key={`${userSession.gameId}-${index}`} index={index} 
      gameId={userSession.gameId}  />
  }

//index={`${props.gameId}-${index}`}   index={index}


  const handleAddLeaderboard = async event => {
    const newLeaderboardConfig = {
      "game_id": userSession.gameId,
      "ld_name": "",
      "display_name": "",
      "insert_opt": "keep",
      "max_entries": 10,
      "sharded": false,
      "isNew": true
    }
    setLeaderboards([...leaderboards, newLeaderboardConfig])
  }

  //console.log("leaderboards : " + leaderboards)
  return (
    <div className="leaderboardConfig">
        {
          leaderboards.map(renderLeaderboard)
        }
      <div className="explainer centered">
        <button className="addLeaderboardButton" id="addLeaderboardButton" onClick={handleAddLeaderboard}>+ Add a new leaderboard</button>
      </div>
    </div>
  );
}

export default LeaderboardConfig
